import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { SubmitButton } from "src/components/common/common-button";
import { Box, Dialog } from "@mui/material";

import classNames from "classnames";
import { CommonInputField } from "src/components/common/common-input-field";
import { usePostCloseLeadMutation } from "src/hooks/useLeadsData";
import { useParams } from "react-router-dom";
import { showNotification } from "src/components/notification";
import { useQueryClient } from "@tanstack/react-query";

const CloseLostModal = ({
  styles,
  open,
  handleClose,
  formData,
  handleChange,
  closeLeadData,
}) => {
  const { id } = useParams();
  const [showTips, setShowTips] = useState(false);

  const queryClient = useQueryClient();
  const closeLostLeadMutation = usePostCloseLeadMutation();

  const handleWinCloseLead = async () => {
    try {
      await closeLostLeadMutation.mutateAsync(
        {
          body: { ...formData, status: "lost", close_date: new Date() },
          id: id,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["close-lead", id] });

            showNotification(
              "success",
              "Lead Closing Updated Successfully.!",
              2000
            );
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };
  return (
    <Box className={styles["main-dialog"]}>
      <Dialog
        fullWidth
        open={open === undefined ? false : open}
        PaperProps={{
          sx: {
            width: "45%",
            maxWidth: "none",
            height: "fit-content",
            maxHeight: "none",
          },
        }}
      >
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>Close Lost</Box>
          <Box>
            <Close className={styles["close"]} onClick={handleClose} />
          </Box>
        </Box>

        {closeLeadData || showTips ? (
          <>
            <Box className={styles["progress_card_name"]}>
              Top Tips: Converting Leads the Right Way
            </Box>

            <Box className={styles["close_tip_card"]}>
              {/* first Tip */}
              <Box
                className={classNames("common-box-shadow", styles["close_tip"])}
              >
                <Box className={styles["proposal_title"]}>
                  <Box
                    className={classNames(
                      "common-box-shadow",
                      styles["tip_img"]
                    )}
                  >
                    <img
                      src="/assets/tip2.png"
                      alt=""
                      className={styles["tip_to_img"]}
                    />
                  </Box>
                  <Box className={styles["requirement_Box"]}>
                    <Box sx={{ display: "flex" }}>
                      <Box className={styles["tip_number"]}>Tip 1:</Box>
                      <Box className={styles["proposal_title"]}>
                        <Box className={styles["progress_tip_text"]}>
                          A precise and unique proposal attracts the customer.{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* second tip */}
              <Box
                className={classNames("common-box-shadow", styles["close_tip"])}
              >
                <Box className={styles["proposal_title"]}>
                  <Box
                    className={classNames(
                      "common-box-shadow",
                      styles["tip_img"]
                    )}
                  >
                    <img
                      src="/assets/tip2.png"
                      alt=""
                      className={styles["tip_to_img"]}
                    />
                  </Box>
                  <Box className={styles["requirement_Box"]}>
                    <Box sx={{ display: "flex" }}>
                      <Box className={styles["tip_number"]}>Tip 2:</Box>
                      <Box className={styles["proposal_title"]}>
                        <Box className={styles["progress_tip_text"]}>
                          Timely follow up helps in building a good rapport with
                          the customer.{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* third Tip */}
              <Box
                className={classNames("common-box-shadow", styles["close_tip"])}
              >
                <Box className={styles["proposal_title"]}>
                  <Box
                    className={classNames(
                      "common-box-shadow",
                      styles["tip_img"]
                    )}
                  >
                    <img
                      src="/assets/tip2.png"
                      alt=""
                      className={styles["tip_to_img"]}
                    />
                  </Box>
                  <Box className={styles["requirement_Box"]}>
                    <Box sx={{ display: "flex" }}>
                      <Box className={styles["tip_number"]}>Tip 3:</Box>
                      <Box className={styles["proposal_title"]}>
                        <Box className={styles["progress_tip_text"]}>
                          Tailoring solutions to fit the customer's exact needs
                          boosts engagement and loyalty.{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
             
                <SubmitButton
                  fullWidth={true}
                  text="Close"
                  handleClick={() => {
                    handleClose();
                    setShowTips(false);
                  }}
                />
            </Box>
          </>
        ) : (
          <Box>
            <form
              style={{ padding: "20px" }}
              onSubmit={(e) => {
                e.preventDefault();
                handleWinCloseLead();
                setShowTips(true);
              }}
            >
              <Box className={styles["progress_card_name"]}>
                Write The Reason Why Lead is close As Lost!!
              </Box>
              <CommonInputField
                placeholder="Comment"
                name="comment"
                type="text"
                defaultValue={formData?.comment}
                handleChange={handleChange}
                minLines={4}
                customClass={classNames("full-width", styles["text-area"])}
              />
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <SubmitButton fullWidth={true} text="Save" />
              </Box>
            </form>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default CloseLostModal;
