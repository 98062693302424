import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
const styles = {
  card: {
    height: "90%",
    width: "100%",
    padding: "16px",
    borderRadius: "12px",
    fontFamily: "Roboto, sans-serif",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.3s ease",
  },
  iconBox: {
    padding: "6px",
    width: 32,
    height: 32,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    marginRight: "8px",
  },
  title: {
    width: "20%",
    fontSize: "13px",
    fontWeight: "500",
  },
  total: {
    fontSize: "32px",
    fontWeight: "600",
    marginTop: "16px",
  },
  arrowIcon: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
    width: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "transform 0.3s ease",
    borderRadius: "50%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

export default function StatsCard({ title, total, icon, bgColor, textColor }) {
  return (
    <Card style={{ backgroundColor: bgColor, color: textColor, ...styles.card }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={styles.iconBox}>{icon}</Box>
        <Typography variant="subtitle2" sx={styles.title}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={styles.total}>{total}</Typography>
        <Box sx={styles.arrowIcon}>
          {<ArrowOutwardIcon  fontSize="small" />}
        </Box>
      </Box>
    </Card>
  );
}

StatsCard.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string,
  total: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
};
