import React, { useEffect, useState } from "react";
import TitleHeader from "src/components/common/title-header";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Tooltip,
  TablePagination,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import TableHeader from "./components/table-header";
import styles from "./style.module.css";
import Loader from "src/components/common/loader";
import { formatDate } from "src/utils/formatTime";
import dayjs from "dayjs";
import classNames from "classnames";
import { showNotification } from "src/components/notification";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import DeleteDealsModal from "./components/delete-modal";
import DealsModal from "../accounts/components/accounts-modal";
import {
  useDealsData,
  useDealsMutation,
  useDeleteDealsMutation,
  usePatchDealsMutation,
} from "src/hooks/useDealsData";

const DealsPage = () => {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [filterQueryString, setFilterQueryString] = useState("");
  const [searchField, setSearchField] = useState("");
  const [formData, setFormData] = useState({
    source: "default",
  });
  const [filterData, setFilterData] = useState({
    source: "all",
    status: "all",
    lead_owner: "all",
    is_active: "all",
  });
  const [value, setValue] = React.useState(dayjs());
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10); //eslint-disable-line
  const navigate = useNavigate();

  // eslint-disable-next-line
  const { data, isLoading, isError } = useDealsData({
    page,
    query: filterQueryString,
  });

  const dealsMutation = useDealsMutation();
  const deleteDealsMutation = useDeleteDealsMutation();
  const patchOpportunitiesMutation = usePatchDealsMutation();

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries("opportunitiesData");
  }, [page, queryClient, filterQueryString]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    if (searchField !== "") {
      setFilterQueryString((prev) => (prev += `&search=${searchField}`));
    }
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
    setOpen(false);
  };

  const handleReset = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      opportunity_amount: "",
      probability: "",
      organization: "",
      source: "default",
      website: "",
      description: "",
      tags: [],
    });
  };

  const handleFilterReset = (e) => {
    e.preventDefault();
    setFilterData({
      source: "all",
      status: "all",
      lead_owner: "all",
      is_active: "all",
    });
    setFilterQueryString("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFilterDataChange = (event) => {
    const { name, value } = event.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (edit) {
      try {
        await patchOpportunitiesMutation.mutateAsync(formData, {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["opportunitiesData"] });
            showNotification("success", "Lead Updated Successfully.!", 2000);
            handleReset();
            setEdit(false);
            setOpen(false);
          },
        });
      } catch (error) {
        showNotification("error", error, 8000);
      }
    } else {
      try {
        await dealsMutation.mutateAsync(formData, {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["opportunitiesData"] });
            showNotification("success", "Leads Added Successfully.!", 2000);
            handleReset();
            setOpen(false);
          },
        });
      } catch (error) {
        showNotification("error", error, 8000);
      }
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await deleteDealsMutation.mutateAsync(deleteUserData?.id, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["opportunitiesData"] });
          showNotification("success", "Leads Deleted Successfully.!", 2000);
          handleReset();
          setOpen(false);
        },
      });
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleFilterSubmit = async () => {
    // Construct the filter query string
    let filterQuery = "";

    // Check if source is not "all"
    if (filterData.source !== "all") {
      filterQuery += `&source=${filterData.source}`;
    }

    // Check if status is not "all"
    if (filterData.status !== "all") {
      filterQuery += `&status=${filterData.status}`;
    }

    // Check if lead_owner is not "all"
    if (filterData.lead_owner !== "all") {
      filterQuery += `&lead_owner=${filterData.lead_owner}`;
    }

    // Check if is_active is not "all"
    if (filterData.is_active !== "all") {
      filterQuery += `&is_active=${filterData.is_active === "active"}`;
    }

    setFilterQueryString(filterQuery);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      <ToastContainer />
      <TitleHeader
        sx={{
          color: "#ff0000",
          fontWeight: "bold",
        }}
      />
      <Box className={styles["table_box"]}>
        <TableHeader
          styles={styles}
          handleOpen={() => setOpen(true)}
          openFilter={openFilter}
          handleOpenFilter={handleOpenFilter}
          handleCloseFilter={() => setOpenFilter(false)}
          value={value}
          setValue={setValue}
          handleSearch={handleSearch}
          searchField={searchField}
          setSearchField={setSearchField}
          filterData={filterData}
          handleFilterDataChange={handleFilterDataChange}
          handleFilterReset={handleFilterReset}
          handleFilterSubmit={handleFilterSubmit}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={styles["tHead"]}>
              <TableRow>
                <TableCell className={styles["table_cell"]}>
                  <Box className={styles["checkBoxDiv"]}>
                    <Checkbox className={styles["checkBox"]} />
                  </Box>
                </TableCell>
                <TableCell className={styles["table_cell"]}>Deal Id</TableCell>
                <TableCell className={styles["table_cell"]}>Name</TableCell>
                <TableCell className={styles["table_cell"]}>
                  Company Name
                </TableCell>
                <TableCell className={styles["table_cell"]}>
                  Owner Name
                </TableCell>
                <TableCell className={styles["table_cell"]}>Amount</TableCell>

                <TableCell className={styles["table_cell"]}>
                  Closing Date
                </TableCell>

                <TableCell
                  className={classNames(
                    styles["table_cell"],
                    styles["table_action_header"]
                  )}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.results?.map((row) => (
                <TableRow className={styles["table-body-row"]} key={row?.id}>
                  <TableCell className={styles["table_cell"]}>
                    <Box className="form-check">
                      <Checkbox
                        className="form-check-input"
                        name="chk_child"
                        value="option1"
                      />
                    </Box>
                  </TableCell>
                  <TableCell className="phone table_cell">
                    {row?.id ? row.id.toString().slice(-5) : "----"}
                  </TableCell>
                  <TableCell className={styles["table_cell"]}>
                    {/* <Box className={styles["avatar_box"]}> */}
                    {/* <Box className={styles["avatar_inner_box"]}>
                        <NameProfilePic
                          letter={
                            row?.lead?.first_name
                              ? row?.lead?.first_name[0].toUpperCase()
                              : ""
                          }
                          customStyle={{
                            fontSize: "11px !important",
                          }}
                        />
                      </Box> */}
                    <Box className="flex-grow-1 ms-2 name">
                      {`${row?.first_name} ${row?.last_name}`}
                    </Box>
                    {/* </Box> */}
                  </TableCell>
                  <TableCell className="phone table_cell">
                    {row?.company || "----"}
                  </TableCell>
                  <TableCell className="location">
                    {row?.assignee?.user?.fullname || "----"}
                  </TableCell>
                  <TableCell className={styles["table_cell"]}>
                    {row?.closing_value || "----"}
                  </TableCell>

                  <TableCell className="location">
                    {row?.close_date ? formatDate(row?.close_date) : "----"}
                  </TableCell>
                  <TableCell className={styles["row_action"]}>
                    <ul
                      style={{
                        width: "fit-content",
                        listStyle: "none",
                        padding: 0,
                        display: "flex",
                      }}
                    >
                      <li
                        className="list-inline-item"
                        onClick={() =>
                          navigate(
                            `/dashboard/deals/${row?.id}?path=deals-summary`
                          )
                        }
                      >
                        <Tooltip title="View" placement="top">
                          <Visibility
                            sx={{
                              color: "#878a99",
                              marginRight: "0.6rem",
                              fontSize: "17px",
                            }}
                          />
                        </Tooltip>
                      </li>
                      {/* <li
                        className="list-inline-item"
                        onClick={() => {
                          setFormData({
                            id: row?.id,
                            amount: row?.amount,
                            probability: row?.probability,
                            description: row?.description,
                            stage: row?.stage,
                            tags: [],
                          });
                          setEdit(true);
                          setOpen(true);
                        }}
                      >
                        <Tooltip title="Edit" placement="top">
                          <Edit
                            sx={{
                              color: "#878a99",
                              marginRight: "0.6rem",
                              fontSize: "17px",
                            }}
                          />
                        </Tooltip>
                      </li> */}
                      {/* <li
                        className="list-inline-item"
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteUserData({
                            name: row?.first_name + row?.last_name,
                            id: row?.id,
                          });
                        }}
                      >
                        <Tooltip title="Delete" placement="top">
                          <DeleteForever
                            sx={{
                              color: "#878a99",
                              marginRight: "0.6rem",
                              fontSize: "17px",
                            }}
                          />
                        </Tooltip>
                      </li> */}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={data?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
      <DeleteDealsModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        deleteUserData={deleteUserData}
        setDeleteUserData={setDeleteUserData}
        handleDelete={handleDelete}
        styles={styles}
      />
      <DealsModal
        open={open}
        styles={styles}
        handleReset={handleReset}
        handleOpen={() => setOpen(true)}
        handleClose={() => setOpen(false)}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChange={handleChange}
        edit={edit}
        setEdit={setEdit}
      />
    </Box>
  );
};

export default DealsPage;
