import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, Button, Grid, Box } from "@mui/material";

function TemplateList({ templates , styles}) {
  const navigate = useNavigate();

  const handlePreview = (templateId) => {
    navigate(`/dashboard/preview-template/${templateId}`);
  };

  const templateCount = templates?.results?.length || 0; // Count the templates, default to 0 if undefined

  return (
    <div style={{ width: "100%", height: "55vh" }}>
      <Typography
        variant="subtitle2"
        sx={{ marginBottom: "20px", color: "#6c757d" }}
      >
        Total Templates: {templateCount}
      </Typography>
      <div
        style={{
          height: "85%",
          overflowY: "auto",
          width: "100%",
        }}
      >
        <Grid container spacing={3}>
          {templateCount > 0 ? (
            templates.results.map((template) => (
              <Grid item xs={12} key={template.id}>
                {" "}
                {/* Each template takes the full width of the row */}
                <Card
                  variant="outlined"
                  sx={{
                    width: "100%", // Ensure cards take the full width of the Grid item
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 20px",
                      paddingBottom: "10px !important",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: 600, color: "#212b36" }}
                    >
                      {template.name}
                    </Typography>
                    {/* <Button
                      variant="contained"
                      onClick={() => handlePreview(template.id)}
                      sx={{
                        backgroundColor: "#212b36",
                        color: "#ffffff",
                        textTransform: "none",
                        fontSize: "0.875rem",
                        padding: "6px 12px",
                        "&:hover": { backgroundColor: "#374151" },
                      }}
                    >
                      Preview
                    </Button> */}
                    <Button
                variant="contained"
                      className={styles["button"]}
                      onClick={() => handlePreview(template.id)}
                    >
                      <Box className={styles["export_btn"]}>Preview</Box>
              </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  padding: "6px",
                  textAlign: "center",
                  width: "100%",
                  color: "black",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No templates available. Please Create New Template.
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default TemplateList;
