import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextareaAutosize,
} from "@mui/material";
import styles from "../style.module.css";
import {
  SubmitButton,
} from "src/components/common/common-button";
import { CommonInputField } from "src/components/common/common-input-field";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import { STAGE_OPTIONS } from "src/utils/constants";
import { Close } from "@mui/icons-material";

const ConvertDialog = ({
  isOpen,
  closeDialog,
  data,
  handleSubmit,
  conversionFormData,
  handleChange,
}) => {
  return (
    <>
      <Box className={styles["main"]}>
        <Dialog open={isOpen} fullWidth>
          <Box className={styles["main_header"]}>
            <Box className={styles["add_lead"]}>{"CONVERT LEAD"}</Box>
            <Box>
              <Close className={styles["close"]} onClick={closeDialog} />
            </Box>
          </Box>
          <Box className={styles["dialog_summary_name"]}>
            Name: {data?.first_name} {data?.last_name}
          </Box>
          <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
            {/* probablitty &ooportunity */}
            <Box className={styles["text_field"]}>
              <CommonInputField
                placeholder="Probability"
                name="probability"
                type="number"
                value={conversionFormData?.probability}
                handleChange={handleChange}
                customClass={"half-width"}
              />
              <CommonInputField
                placeholder="Opportunity Amount"
                name="amount"
                type="number"
                value={conversionFormData?.amount}
                handleChange={handleChange}
                customClass={styles["common-search-input-right"]}
              />
            </Box>
            {/* Close Date & Stage */}
            <Box className={styles["text_field"]}>
              <CommonInputField
                placeholder="Close Date [Optional]"
                name="close_date"
                type="close_date"
                customClass={"half-width"}
                value={conversionFormData?.close_date}
                handleChange={handleChange}
              />
              {/* NEW DESIGN  */}
              <CommonDropdownField
                handleChange={handleChange}
                customClass={"half-width"}
                name="stage"
                placeholder="Stage"
                variant="outlined"
                value={conversionFormData?.stage}
                defaultValue={"default"}
                options={STAGE_OPTIONS}
              />
            </Box>

            <TextareaAutosize
              placeholder="Description"
              name="description"
              onChange={handleChange}
              value={conversionFormData?.description}
              style={{
                width: "100%",
                minHeight: "100px",
                padding: "8px",
                resize: "vertical",
              }}
            />
            <Box className={styles["first_dialog_content"]}>
              <Box className={styles["sec_dialog_content"]}>
                <img
                  src="/assets/convert.png"
                  alt="Convert"
                  className={styles["dialog_img"]}
                />
              </Box>
              <Box>
                <DialogTitle>
                  {"Confirmation to convert the Lead!!"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to confirm converting this lead into
                    an opportunity?
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Box>
            <DialogActions>
              <SubmitButton
                fullWidth={true}
                text={"Convert Lead"}
                // handleClick={handleSubmit}
              />
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </>
  );
};

export default ConvertDialog;
