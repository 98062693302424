import React, { useState } from "react";
import styles from "../style.module.css";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CommonButton } from "src/components/common/common-button";
import { showNotification } from "src/components/notification";

const FileUpload = ({ handleFileUpload }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [file, setFile] = useState(null);

  const allowedTypes = ["image/png", "image/jpeg", "application/pdf", "image/jpg"];
  const maxSize = 1 * 1024 * 1024; 

  const validateFile = (file) => {
    if (!allowedTypes.includes(file.type)) {
      showNotification("error", "Invalid file type. Only PNG, JPEG, JPG, PDF allowed.",8000);
      return false;
    }
    if (file.size > maxSize) {
      showNotification("error","File size exceeds the limit of 1MB.", 8000);
      return false;
    }
    return true;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && validateFile(selectedFile)) {
      setFile(selectedFile);
      
    }
    event.target.value = null; 
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && validateFile(droppedFile)) {
      setFile(droppedFile);
    
    }
    setIsDragOver(false);
    event.target.value = null; 
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <Box className={styles["main_drag_card"]}>
      <Box className={styles["main_drag"]}>
        <Box
          className={`${styles.dragArea} ${isDragOver ? styles.active : ""}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleButtonClick}
          sx={{ cursor: "pointer" }}
        >
          {!file && <button>
            Drag & Drop to Upload File
          </button>}
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            hidden
          />
          {file && (
            <Box className={styles["file-preview"]}>
              {file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="file"
                  className={styles["img_preview"]}
                />
              ) : (
                <span>{file.name}</span>
              )}
              <Box>
                <Close onClick={() => setFile(null)} />
              </Box>
            </Box>
          )}
        </Box>
        {file && (
          <Box className={styles["btns"]}>
            <CommonButton
              text={"Cancel"}
              customClass={styles["button"]}
              handleClick={(e) => {
                e.preventDefault();
                setFile(null);
              }}
            />

            <CommonButton
              text={"Upload"}
              customClass={styles["button"]}
              handleClick={(e) => {
                e.preventDefault();
                handleFileUpload(file);
                setFile(null);
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileUpload;
