import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import styles from "./style.module.css";
import { showNotification } from "src/components/notification";
import {
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  TableHead,
  Paper,
  Tooltip,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserListToolbar } from "../../sections/@dashboard/user";
import {
  usePatchUserMutation,
  usePostUserMutation,
  useUserList,
} from "src/hooks/useMembersData";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import Loader from "src/components/common/loader";
import { Edit, DeleteForever } from "@mui/icons-material";
import UserModal from "./component/user-modal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import EmptyTableMessage from "src/components/common/EmptyTableMessage";

const rowsPerPage = 10;
const initialPage = 0;

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false, width: "40%" },
  { id: "email", label: "email", alignRight: false, width: "25%" },
  { id: "role", label: "Role", alignRight: false, width: "20%" },
  { id: "status", label: "Status", alignRight: false, width: "20%" },
  { id: "", label: "Actions", width: "10%" },
];

export default function UserPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [page, setPage] = useState(initialPage);
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState({ limit: rowsPerPage, page: 1 });
  const { data, isLoading } = useUserList(query);
  const PostUserMutation = usePostUserMutation();
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState("");
  const [formData, setFormData] = useState({});
  const PatchUserMutation = usePatchUserMutation();
  const deactivateUserMutation = usePatchUserMutation();
  const [selectedRow, setSelectedRow] = useState({ role: "default" });
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const searchQuery = searchParams.get("search");
    setQuery({
      ...query,
      search: searchQuery,
    });
  }, [searchParams]); // eslint-disable-line

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams();

      Object.entries(query).forEach(([key, value]) => {
        if (value) params.append(key, value);
      });
      navigate(`?${params}`, { replace: true });
      queryClient.invalidateQueries({ queryKey: ["userList"] });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [query, page]); //eslint-disable-line

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpenDialog = (type, row) => {
    setActionType(type);
    setOpenDialog(true);
    setSelectedRow({ ...selectedRow, ...row });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleAddUser = async () => {
    try {
      await PostUserMutation.mutateAsync(
        { body: formData },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["userList"] });
            showNotification("success", "Added User Successfully.!", 2000);

            setOpenDialog(false);
          },
        }
      );
    } catch (error) {
    
      showNotification("error", error, 8000);
    }
  };

  const handleEditUser = async () => {
    try {
      await PatchUserMutation.mutateAsync(
        { body: formData, id: selectedRow.user.id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["userList"] });
            showNotification("success", "Edited User Successfully.!", 2000);
            setOpenDialog(false);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };
  const deactivateUser = async () => {
    try {
      await deactivateUserMutation.mutateAsync(
        {
          body: { is_active: false },
          id: selectedRow.user.id,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["userList"] });
            showNotification(
              "success",
              " User Deactivated Successfully.!",
              2000
            );
            setOpenDialog(false);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((itemId) => itemId !== id);
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    const checked = event.target.checked;
    const newSelected = checked ? data?.results.map((user) => user.id) : [];
    setSelected(newSelected);
  };

  const totalPages = Math.ceil((data?.count || 0) / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    const offset = newPage * rowsPerPage;
    setPage(newPage);
    setQuery({ ...query, page: newPage + 1, offset });
  };

  const handleSeachChange = (event) => {
    setQuery({
      ...query,
      [event.target.name]: event.target.value,
    });
  };

  const filteredUsers = data?.results || [];
  const total_users = data?.count || 0;
  return (
    <>
      <Helmet>
        <title>User Management | Lead Management</title>
      </Helmet>

      <Box>
        <ToastContainer />
        {/* <TitleHeader
          title={"User Management"}
          sx={{
            color: "#ff0000",
            fontWeight: "bold",
          }}
        /> */}
        <Box className={styles["table_box"]}>
          <UserListToolbar
            numSelected={selected.length}
            handleChange={handleSeachChange}
            handleOpenDialog={() => handleOpenDialog("add")}
            query={query}
            total_users={total_users}
          />
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Table>
                  <TableHead className={styles["tHead"]}>
                    <TableRow>
                      <TableCell
                        className={styles["table_cell"]}
                        style={{ width: "5%" }}
                        padding="checkbox"
                      >
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < filteredUsers.length
                          }
                          checked={selected.length === filteredUsers.length}
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? "center" : "left"}
                          width={headCell.width}
                          className={styles["table_cell"]}
                          style={{ paddingRight: "1.5rem" }}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.length === 0 ? (
                      <EmptyTableMessage
                        colSpan={TABLE_HEAD.length}
                        message="No results found."
                      />
                    ) : (
                      filteredUsers.map((row) => (
                        <TableRow
                          className={styles["table-body-row"]}
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell
                            className={styles["table_cell"]}
                            padding="checkbox"
                          >
                            <Checkbox
                              checked={selected.includes(row.id)}
                              onChange={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell
                            className={styles["table_cell"]}
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Box
                                sx={{
                                  borderRadius: "100%",
                                  height: "24px",
                                  width: "24px",
                                  border: "1px solid black",
                                  backgroundColor: "#1C2536",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                {row?.user?.profile_pic ? (
                                  <img
                                    src={row?.user?.profile_pic}
                                    alt={JSON.stringify(row?.user?.profile_pic)}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      fontWeight: "normal",
                                      color: "white",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {row?.user?.fullname
                                      ? row.user?.fullname[0]
                                      : ""}
                                  </Typography>
                                )}
                              </Box>

                              <Typography variant="subtitle2" noWrap>
                                {row?.user?.fullname || "--"}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell className={styles["table_cell"]}>
                            {row?.user?.email || "--"}
                          </TableCell>

                          <TableCell className={styles["table_cell"]}>
                            {row?.role || "--"}
                          </TableCell>

                          <TableCell>
                            <Box
                              className={classNames(
                                styles["info-chip"],
                                row?.user?.is_active
                                  ? ""
                                  : styles["danger-chip"]
                              )}
                            >
                              {row?.user?.is_active ? "Active" : "Inactive"}
                            </Box>
                          </TableCell>

                          <TableCell
                            className={styles["table_cell"]}
                            align="left"
                          >
                            <ul
                              style={{
                                width: "fit-content",
                                listStyle: "none",
                                padding: 5,
                                display: "flex",
                              }}
                            >
                              <li
                                className="list-inline-item"
                                onClick={() => handleOpenDialog("edit", row)}
                              >
                                <Tooltip title="Edit" placement="top">
                                  <Edit
                                    sx={{
                                      color: "#878a99",
                                      marginRight: "0.6rem",
                                      fontSize: "17px",
                                    }}
                                  />
                                </Tooltip>
                              </li>
                              <li
                                className="list-inline-item"
                                onClick={() => handleOpenDialog("delete", row)}
                              >
                                <Tooltip title="Deactivate" placement="top">
                                  <DeleteForever
                                    sx={{
                                      color: "#878a99",
                                      marginRight: "0.6rem",
                                      fontSize: "17px",
                                    }}
                                  />
                                </Tooltip>
                              </li>
                            </ul>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={data?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            totalPages={totalPages}
          />
        </Box>
        <UserModal
          actionType={actionType}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          handleChange={handleChange}
          formData={formData}
          handleAddUser={handleAddUser}
          handleEditUser={handleEditUser}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          deactivateUser={deactivateUser}
        />
      </Box>
    </>
  );
}
