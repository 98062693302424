import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography, Stack, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../../components/logo";
import { useConfirmPassMutation } from "../../../hooks/useSessiondata";
import { showNotification } from "../../../components/notification";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import CustomInput from "src/components/input/CustomInput";

const theme = createTheme({
  palette: {
    blue: {
      main: "#174276",
      hover: "#2874D0",
      contrastText: "#fff",
    },
  },
});

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const ConfirmPassword = () => {
  const [new_password, setNew_password] = useState("");
  const [re_new_password, setRe_new_password] = useState("");
  const { token, uuid } = useParams();
  const uid = uuid;
  const navigate = useNavigate();
  const confirmPassMutation = useConfirmPassMutation();

  const handleSetPass = async () => {
    if (new_password !== re_new_password) {
      showNotification("error", "Password Mismatch", "Password Mismatch", 5000);
      return;
    }
    try {
      await confirmPassMutation.mutateAsync(
        { new_password, re_new_password, token, uid },
        {
          onSuccess: () => {
            setTimeout(() => {
              showNotification("success", "Updated Password", 2000);

            }, 100);
            navigate("/login");
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  return (
    <div>
      <Helmet>
        <title> Confirm Password | Lead Management System </title>
      </Helmet>
      <StyledRoot>
        <ToastContainer />
        <Box maxWidth="sm" sx={{ width: "100%" }} >
          <StyledContent>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",

              }}
            >
              <Logo />
            </Grid>

            <Grid sx={{ textAlign: "center", marginBottom: "50px" }}>
              <Typography variant="h4" gutterBottom>
                Reset your password
              </Typography>
            </Grid>
            <Stack
              direction="column"
              spacing={2}
              sx={{ my: 2 }}
            >
              <CustomInput
                name="new_password"
                placeholder="New Password"
                type="password"
                value={new_password}
                onChange={(e) => setNew_password(e.target.value)}
              />
              <CustomInput
                name="re_new_password"
                placeholder="Confirm Password"
                type="password"
                value={re_new_password}
                onChange={(e) => setRe_new_password(e.target.value)}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ my: 1 }}
            >
              <ThemeProvider theme={theme}>
                <LoadingButton
                  fullWidth
                  color="blue"
                  size="large"
                  variant="contained"
                  onClick={handleSetPass}
                  loading={confirmPassMutation.isLoading}
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.palette.blue.hover,
                    },
                  }}
                >
                  Set Password
                </LoadingButton>
              </ThemeProvider>
            </Stack>
          </StyledContent>
        </Box>
      </StyledRoot>
    </div>
  );
};

export default ConfirmPassword;
