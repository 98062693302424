import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Card, CardHeader, Typography, Box } from "@mui/material"

import { useChart } from "../../../components/chart";

const TaskBySite = ({ title, subheader, chartColors, chartData }) => {
  const theme = useTheme();
  const adjustedChartColors = ["#EF3817", "#379ACE"];
  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);
  const totalValue = chartSeries.reduce((acc, value) => acc + value, 0);

  const chartOptions = useChart({
    colors: adjustedChartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper], width: 0.5 },
    legend: {
      show: false,
    },
    
    plotOptions: {
      pie: { 
        donut: { 
          labels: { 
            show: false 
          } 
        } 
      },
    },
  });

  const styles = {
    card: {
      height: "100%",
      padding: '8px 12px',
      display: 'flex',
      justifyContent: 'center',
    },
    chartContainer: {

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
     padding: '24px',
      width: '100%',
      
    },
    legendContainer: {
      marginBottom: '6px',
      display: 'flex',
      alignItems: 'center',

    },
    cardHeader: {

      padding: '0',
      marginBottom: '16px',
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
      fontSize: '14px',
      fontWeight: 'bold',

    },
    pie: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    legendMarker: {
      width: '12px',
      height: '12px',
      borderRadius: '6px',
      marginRight: '8px',
    },
    chart: { padding: '0' },
    title: {
      fontSize: "13px",
      border:" 1px solid #959495 ",borderLeft: "none",borderRight: "none",borderTop: "none" ,
      fontWeight: "bold",
      color:"#959495"
    },
  };

  return (
    <Card sx={styles.card}>
      <Box sx={styles.chartContainer}>
      <CardHeader
        title={
          <Typography sx={styles.title}>
            {title}
          </Typography>
        }
        sx={styles.cardHeader}
        subheader={subheader}
      />

        <Box sx={{ display: "flex", flexDirection: "column"}}>
          <Box sx={styles.legendContainer}>
            <Box sx={styles.legendItem}>
              <Box
                sx={{
                  ...styles.legendMarker,
                  backgroundColor: adjustedChartColors[0],
                }}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ width: "50%" , fontSize:"12px" , fontWeight:500}}>
                {(chartSeries[0] / totalValue * 100).toFixed(1)}%
              </Typography>
              <Typography  sx={{ fontSize:"12px" , fontWeight:500 }} >
                Lost Lead
              </Typography>
            </Box>

          </Box>
          <Box sx={styles.legendContainer}>
            <Box sx={styles.legendItem}>
              <Box
                sx={{
                  ...styles.legendMarker,
                  backgroundColor: adjustedChartColors[1],
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ width: "50%", fontSize:"12px" , fontWeight:500 }}>
                {(chartSeries[1] / totalValue * 100).toFixed(1)}%
              </Typography>
              <Typography sx={{ fontSize:"12px" , fontWeight:500 }} >
                Closed Lead
              </Typography>
            </Box>
          </Box>
        </Box>

      </Box>
      <Box sx={styles.pie}>
        <ReactApexChart
          type="pie"
          series={chartSeries}
          options={chartOptions}
          height={170}
          width={170}
          sx={styles.chart}
        />
        </Box>
    </Card>
  );
};

export default TaskBySite;
