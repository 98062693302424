import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";

export const SubmitButton = ({
  text,
  variant = "contained",
  color,
  customClass,
  handleClick,
  fullWidth = false,
}) => {
  return (
    <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Button
      type="submit"
      variant={variant}
      color={color}
      margin="normal"
      fullWidth={fullWidth}
      onClick={handleClick}
      className={classNames("submit-button", customClass)}
    >
      {text}
    </Button>
    </Box>
  );
};

export const CommonButton = ({
  text,
  color,
  fullWidth = false,
  handleClick,
  customClass,
  ...rest
}) => {
  return (
    <Button
      onClick={handleClick}
      color={color}
      margin="normal"
      fullWidth={fullWidth}
      className={classNames("common-full-width-btn", customClass)}
      {...rest}
    >
      {text}
    </Button>
  );
};

export const CommonPlainButton = ({
  handleClick,
  customBoxClass,
  customBoxStyle,
  customTextClass,
  customTextStyle,
  buttonText,
  iconComponent,
  ...rest
}) => {
  return (
    <Box
      sx={customBoxStyle}
      className={classNames("plain-btn-box", customBoxClass)}
      onClick={handleClick}
      {...rest}
    >
      <Box className={"plain-btn-icon"}>{iconComponent}</Box>
      <Typography
        sx={customTextStyle}
        className={classNames("plain-btn-txt", customTextClass)}
      >
        {buttonText}
      </Typography>
    </Box>
  );
};
