import { Box } from "@mui/material";
import React from "react";
import { SubmitButton } from "src/components/common/common-button";
import { CommonInputField } from "src/components/common/common-input-field";
import styles from "../style.module.css"

const ContactForm = ({ formData, handleChange, handleClose, contactFormRef }) => {
  return (
    <>
    <Box  className={styles["contact-form"]}>
    <Box className={styles["get_in"]} id="contact">Get In Touch</Box>
        <Box className={styles["get_indesc"]}>
          Can't find the answer you're looking for? Don't worry! Get in touch
          with the Docs Support team, we will be glad to assist you.
        </Box>
   <Box className={styles["contact-us-form"]}>
   <Box className={styles["form_box"]}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log(formData);
              handleClose();
            }}
          >
            <Box className={styles["get_in_text"]}>Message Us</Box>
            <Box className={styles["get_in_tex_desc"]}>
              Our friendly team would love to hear from you..
            </Box>
            <Box>
              <label htmlFor="name">Full Name</label>
              <CommonInputField
                placeholder="Full Name"
                name="name"
                id="name"
                onChange={handleChange}
                value={formData?.name}
                customClass={"full-width"}
              />
            </Box>

            <Box className={styles["analysis_box"]}>
              <label htmlFor="email">Email</label>
              <CommonInputField
                placeholder="Email"
                name="email"
                id="email"
                onChange={handleChange}
                value={formData?.email}
                customClass={"full-width"}
              />
            </Box>
            <Box className={styles["analysis_box"]}>
              <label htmlFor="number">Phone Number</label>
              <CommonInputField
                placeholder="Phone Number"
                name="number"
                id="number"
                onChange={handleChange}
                value={formData?.number}
                customClass={"full-width"}
              />
            </Box>
            <Box>
              <label htmlFor="comment">Comment</label>
              <CommonInputField
                placeholder="Comment"
                name="comment"
                id="comment"
                onChange={handleChange}
                value={formData?.comment}
                customClass={styles["text-area"]}
                minLines={2}
                multiline="true"
              />
            </Box>

            <SubmitButton
              fullWidth={true}
              text="Submit"
              customClass={styles["contact-button"]}
            />
          </form>
        </Box>
        

        <Box className={styles["form_right_img"]}>
          <img
            src="/assets/contactuss.png"
            alt="support"
            className={styles["second-form-img"]}
          />
        </Box>
        
      </Box>
      </Box>
 
    </>
  );
};

export default ContactForm;
