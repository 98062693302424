import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useLeadsData } from "src/hooks/useLeadsData";
import { useNavigate } from "react-router-dom";
import CustomInput from "src/components/input/CustomInput";
import CustomButton from "src/components/input/CustomButton";
import FileUpload from "src/pages/leads/detail-view/components/dropzone";
import { useSendBulkEmailMutation } from "src/hooks/useTemplateData";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showNotification } from "src/components/notification";

const buttonStyle = {
  backgroundColor: "#212b36",
  color: "#ffffff",
  padding: "6px 12px",
  borderRadius: "8px",
  fontWeight: "700",
  fontSize: "0.875rem",
  width: "100%",
  "&:hover": {
    backgroundColor: "#444e5b",
    color: "#ffffff",
  },
};

function SendEmail({ templates, styles }) {
  const navigate = useNavigate();

  // const [emailOptions, setEmailOptions] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailSource, setEmailSource] = useState("");
  const [manualEmail, setManualEmail] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null); // State for selected template
  const { data } = useLeadsData();

  const sendBulkEmailMutation = useSendBulkEmailMutation();

  useEffect(() => {
    setSelectedEmails([]); // Reset selected emails on email source change
  }, [emailSource]);

  const handleTemplateRedirect = () => {
    navigate("/dashboard/create-template");
  };

  const handleAddManualEmail = () => {
    if (manualEmail && !selectedEmails.includes(manualEmail)) {
      setSelectedEmails((prev) => [...prev, manualEmail]);
      setManualEmail("");
    }
  };

  const handleSelectEmail = (event, newValue) => {
    setSelectedEmails(newValue);
  };

  const handleTemplateChange = (event) => {
    const templateName = event.target.value;
    if (templateName === "create") {
      handleTemplateRedirect();
    } else {
      const template = templates?.results?.find((t) => t.name === templateName);
      setSelectedTemplate(template); // Update selected template state
      console.log("Selected Template:", template); // Log the selected template
    }
  };

  const handleCsvUpload = () => {
    console.log("CSV upload option selected - Implement CSV upload logic here");
  };

  const sendEmailToUsers = async () => {
    try {
      await sendBulkEmailMutation.mutateAsync(
        {
          emails: selectedEmails.join(", "),
          template_id: selectedTemplate?.id,
        },
        {
          onSuccess: () => {
            showNotification("success", "All Emails Sent Successfully.!", 2000);
            setSelectedEmails([]);
          },
          onError: (error) => {
            console.log(error);
            showNotification("error", error, 8000);
          },
        }
      );
    } catch (error) {
      console.error("Error exporting template:", error);
      showNotification("error", error, 8000);
    }
  };

  return (
    <Box elevation={3} sx={{ height: "300px" }}>
      <ToastContainer />
      <Typography variant="h5" sx={{ mb: 2 }} gutterBottom>
        Send Emails
      </Typography>

      {/* Template Dropdown */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1">Select a Template</Typography>
        <Select
          displayEmpty
          value={selectedTemplate ? selectedTemplate.name : ""} // Set value based on selected template
          fullWidth
          onChange={handleTemplateChange}
          className={styles["search-input"]}
          sx={{ mt: 1 }}
          style={{ width: "100%" }}
        >
          <MenuItem value="" disabled>
            Select Template
          </MenuItem>
          {templates?.results?.map((template, index) => (
            <MenuItem key={index} value={template.name}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1">Choose Email Source</Typography>
        <Select
          displayEmpty
          value={emailSource}
          onChange={(e) => setEmailSource(e.target.value)}
          fullWidth
          className={styles["search-input"]}
          sx={{ mt: 1 }}
        >
          <MenuItem value="" disabled>
            Select Email Source
          </MenuItem>
          <MenuItem value="manual">Add Email Manually</MenuItem>
          <MenuItem value="existing">Existing Leads' Emails</MenuItem>
          <MenuItem value="csv">Upload CSV File</MenuItem>
        </Select>
        {emailSource === "manual" && (
          <>
            <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
              <CustomInput
                name="email"
                placeholder="Email"
                type="email"
                value={manualEmail}
                onChange={(e) => setManualEmail(e.target.value)}
                fullWidth
              />
              <CustomButton onClick={handleAddManualEmail} style={buttonStyle}>
                Add
              </CustomButton>
            </Box>
            {selectedEmails.map((item) => (
              <Box>{item}</Box>
            ))}
          </>
        )}
        {emailSource === "existing" && (
          <Autocomplete
            multiple
            options={data?.results?.map((lead) => lead.email) || []}
            value={selectedEmails}
            onChange={handleSelectEmail}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Add email"
              />
            )}
            sx={{ mt: 1 }}
          />
        )}
        {emailSource === "csv" && (
          <Box sx={{ mt: 2 }}>
            <FileUpload handleFileUpload={handleCsvUpload} />
          </Box>
        )}
      </Box>

      {/* <CustomButton style={buttonStyle} onClick={() => sendEmailToUsers()}>
        Send Email
      </CustomButton> */}
      <Button
            variant="contained"
            onClick={() => sendEmailToUsers()}
            className={styles["create-btn"]}
              >
                <Box className={styles["export_btn"]}>  Send Email</Box>
              </Button>
    </Box>
  );
}

export default SendEmail;
