import { useMutation, useQuery } from "@tanstack/react-query";
import {
  authorizedDelete,
  authorizedGet,
  authorizedPatch,
  authorizedPost,
} from "src/api/base";

export function useTemplateData() {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["emailTemplate"],
    queryFn: async () =>
      await authorizedGet({
        uri: `/api/v1/emailtemplates/`,
      }),
  });

  return { data, isLoading, isError, error };
}

export function useTemplateMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({ uri: "/api/v1/emailtemplates/", body: data }),
  });

  return mutation;
}

export function useSendBulkEmailMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({
        uri: "/api/v1/emailtemplates/send-bulk-email/",
        body: data,
      }),
  });

  return mutation;
}

export function usePatchTemplateMutation() {
  const mutation = useMutation({
    mutationFn: async ({ data }) =>
      await authorizedPatch({
        uri: `/api/v1/emailtemplates/${data?.id}/`,
        body: data,
      }),
  });

  return mutation;
}

export function useDeleteTemplateMutation() {
  const mutation = useMutation({
    mutationFn: async ({ id }) =>
      await authorizedDelete({ uri: `/api/v1/emailtemplates/${id}/` }),
  });

  return mutation;
}

export function useEmailDashboardData({ start_date, end_date }) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["emailDashboard", start_date, end_date],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (start_date) params.append("start_date", start_date);
      if (end_date) params.append("end_date", end_date);

      // Generate final URI with or without query parameters
      const uri = `/api/v1/emailtemplates/dashboard/${
        params.toString() ? `?${params.toString()}` : ""
      }`;

      return await authorizedGet({
        uri,
      });
    },
  });

  return { data, isLoading, isError, error };
}
