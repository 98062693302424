import { Box, MenuItem, Popover, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import NameProfilePic from "src/components/common/name-profile-pic";
import {
  Edit,
  EmailOutlined,
  LeaderboardOutlined,
  PhoneAndroidOutlined,
  Save,
  Language,
  PublishedWithChanges,
} from "@mui/icons-material";

const Header = ({
  styles,
  activeTab,
  setActiveTab,
  data,
  setIsSummaryEditing,
  isSummaryEditing,
  editedData,
  handleEdit,
  handleSave,
  handleConvertOpen,
  anchorEl,
  handleOpenMenu,
  handleCloseMenu,
  open,
}) => {
  return (
    <Box className={styles["header-main-div"]}>
      {/* neww */}
      <Box className={styles["account-details"]}>
        <Box className={styles["profile-info"]}>
          <Box className={styles["head_stacks"]}>
            <Box
              className={classNames(
                "info-chip",
                styles["custom-chip"],
                data?.is_active ? "" : "danger-chip"
              )}
            >
              {data?.is_active ? "Active" : "InActive"}
            </Box>
          </Box>
          <Box>
            <Typography className={styles["account-name"]}>
              {data?.first_name || "----"} {data?.last_name || "----"}
            </Typography>

            <Box sx={{ width: "50vw", marginLeft: "5px" }}>
              <Box className={styles["short-info"]}>
                <Box className={styles["grid_container"]}>
                  {/* Company */}
                  <Box className={styles["grid_item"]}>
                    <LeaderboardOutlined className={styles["icon"]} />
                    <span className={styles["text"]}>
                      {data?.company || "----"}
                    </span>
                  </Box>

                  {/* Website */}
                  <Box className={styles["grid_item"]}>
                    <Language className={styles["icon"]} />
                    <span className={styles["text"]}>
                      {data?.website || "----"}
                    </span>
                  </Box>

                  {/* Email */}
                  <Box className={styles["grid_item"]}>
                    <EmailOutlined className={styles["icon"]} />
                    <span className={styles["text"]}>
                      {data?.email || "----"}
                    </span>
                  </Box>

                  {/* Phone */}
                  <Box className={styles["grid_item"]}>
                    <PhoneAndroidOutlined className={styles["icon"]} />
                    <span className={styles["text"]}>
                      {data?.phone || "----"}
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles["owner-info"]}>
          <Box
            className={styles["border"]}
            sx={{ height: "42px !important" }}
          />
          <Box className={styles["account-owner-profile"]}>
            <NameProfilePic
                letter={
                  data?.assignee?.user?.fullname
                    ? data?.assignee?.user?.fullname[0].toUpperCase()
                    : ""
                }
           
              customClass={styles["profile-icon"]}
              customStyle={{
                fontSize: "11px !important",
              }}
            />
            <Box className={styles["annual-revenue"]}>
              <Typography className={styles["data"]}>{data?.assignee?.user?.fullname}</Typography>
              <Typography className={styles["header"]}>Owner</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleCloseMenu("attachment")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {isSummaryEditing ? (
          <MenuItem
            onClick={() => {
              handleCloseMenu("attachment");
              handleSave();
              setIsSummaryEditing(false);
            }}
            className={styles["attach_popup"]}
          >
            <Save className={styles["attach_popup_icon"]} />
            Save
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleCloseMenu("attachment");
              setIsSummaryEditing(true);
            }}
            className={styles["attach_popup"]}
          >
            <Edit className={styles["attach_popup_icon"]} />
            Edit
          </MenuItem>
        )}
        <MenuItem
          onClick={handleConvertOpen}
          // onClick={(e) => {
          //   handleCloseMenu("attachment");
          // }}
          className={styles["attach_popup"]}
        >
          <PublishedWithChanges className={styles["attach_popup_icon"]} />
          Convert
        </MenuItem>
      </Popover>
    </Box>
  );
};

export default Header;
