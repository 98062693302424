import React from "react";
import {
  Dialog,
  Typography,
  Box,
  Grid,
  DialogActions,
  DialogContentText,
  DialogContent,
  Button,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomInput from "src/components/input/CustomInput";
import styles from "../style.module.css";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import trash_animation from "src/components/common/trash_animation.json";
import Lottie from "lottie-react";
import { USER_ROLE } from "src/utils/constants";
import { SubmitButton } from "src/components/common/common-button";

const UserModal = ({
  openDialog,
  handleCloseDialog,
  selectedRow,
  handleChange,
  handleAddUser,
  handleEditUser,
  actionType,
  deactivateUser,
}) => {
  const handleSubmit = () => {
    switch (actionType) {
      case "edit":
        handleEditUser();
        break;
      case "add":
        handleAddUser();
        break;
      case "delete":
        deactivateUser();
        break;
      default:
        break;
    }
  };

  return (
    <Box className={styles["main"]}>
      {actionType !== "delete" ? (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="xs"
        >
          <Box className={styles["main_header"]}>
            <Typography fontWeight={300} variant="h6" component="div">
              {actionType === "edit" ? "Edit User" : "Add User"}
            </Typography>
            <Box>
              <Close className={styles["close"]} onClick={handleCloseDialog} />
            </Box>
          </Box>

          <Box style={{ padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  placeholder="Full name"
                  name="fullname"
                  defaultValue={selectedRow?.user?.fullname}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  placeholder="Email"
                  name="email"
                  type="email"
                  defaultValue={selectedRow?.user?.email}
                  onChange={handleChange}
                  fullWidth
                  disabled={actionType === "edit" ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonDropdownField
                  variant="outlined"
                  handleChange={handleChange}
                  name="role"
                  placeholder="Role"
                  style={{
                    height: "1.6rem",
                    padding: "22px 0px",
                    color: "black",
                  }}
                  defaultValue={selectedRow?.role}
                  options={USER_ROLE}
                  customClass={"custom-dropdown"}
                />
              </Grid>
            </Grid>
            
          <SubmitButton
           fullWidth={true}
           customClass={styles.fullWidthButton}
            text={actionType === "edit" ? "Save Changes" : "Add User"}
            onClick={handleSubmit}
          />
          </Box>
        </Dialog>
      ) : (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              paddingTop: "13px",
              paddingRight: "13px",
            }}
          >
            <Close
              sx={{ fontSize: "25px", cursor: "pointer" }}
              onClose={handleCloseDialog}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: " 0 30px 30px 30px",
            }}
          >
            <Box sx={{ padding: " 20px 0px" }}>
              <Box sx={{ height: "150px", width: "150px" }}>
                <Lottie animationData={trash_animation} loop={true} />
              </Box>
            </Box>
            <DialogTitle
              sx={{
                textAlign: "center",
                fontWeight: "semi-bold",
                padding: "10px 0px",
              }}
              id="alert-dialog-title"
            >
              {"You are about to deactivate a user ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ fontSize: "14px", textAlign: "center" }}>
                Deactivating this user will remove all of their information from
                our database. Are you sure you want to deactivate{" "}
                <strong>{selectedRow?.user?.fullname}</strong>?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", width: "100%" }}>
              <Button
                onClick={handleCloseDialog}
                sx={{
                  backgroundColor: "#2065d114",
                  fontWeight: "normal",
                  borderRadius: "5px",
                  padding: "8px 18px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#2065d114",
                  fontWeight: "normal",
                  borderRadius: "5px",
                  padding: "8px 18px",
                }}
              >
                Deactivate
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default UserModal;
