import React from 'react';
import { Modal, Box, Typography, Link } from '@mui/material';

const ForgotPasswordModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="forgot-password-modal-title"
      aria-describedby="forgot-password-modal-description"
    >
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 300, sm: 400 },
          bgcolor: 'background.paper',
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23)',
          p: 4,
          borderRadius: 2,
          textAlign: 'center',
        }}
      >
        <Typography id="forgot-password-modal-title" variant="h6" component="h2" gutterBottom>
          Forgot Password?
        </Typography>
        <Typography id="forgot-password-modal-description" sx={{ mt: 2 }}>
        Your Current password is incorrect.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Link 
            href="/reset-password" 
            color="primary"
            sx={{
              textDecoration: 'none',
              fontWeight: 'bold',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Click here to reset your password.
          </Link>
        </Box>
      </Box>
    </Modal>
  );
};

export default ForgotPasswordModal;
