import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Router from "./routes";
import ThemeProvider from "./theme";
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { AuthProvider } from "./providers/auth";
import { PlanProvider } from "./providers/planContext";
const App = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <PlanProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
        </PlanProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
