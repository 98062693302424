import React from "react";
import { Box } from "@mui/material";
import { SubmitButton } from "src/components/common/common-button";
import NewStakeholderForm from "./new-stakeholder-form";

const IdentifyStakeHolderPhase = ({
  styles,
  stakeholderData,
  setFormData,
  handleEditModalOpen,
  formData,
  handleModalOpen,
  setCurrentAction,
}) => {
  // Define the buttons as a reusable component
  const Buttons = () => (
    <Box className={styles["progress_card_2"]}>
      <Box className={styles["btn_common"]}>
        <SubmitButton
          fullWidth={true}
          text={"Add new Stakeholder"}
          handleClick={handleModalOpen}
          // onClick={handleSubmit}
        />
      </Box>
      <Box>
        <SubmitButton
          fullWidth={true}
          text={"Mark as Complete"}
          handleClick={setCurrentAction}
          // onClick={handleSubmit}
        />
      </Box>
    </Box>
  );

  return (
    <Box className={styles["analysis_main"]}>
      {stakeholderData?.contact?.length === 0 || !stakeholderData ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box className={styles["analysis_box_main"]}>
              Add Stakeholder Or Any Contact
            </Box>
            {/* Reuse the Buttons component here */}
            <Buttons />
          </Box>
        </>
      ) : (
        <Box className={styles["progress_card"]}>
          <Box sx={{ width: "60%" }}>
            <NewStakeholderForm
              styles={styles}
              stakeholderData={stakeholderData}
              setFormData={setFormData}
              handleEditModalOpen={handleEditModalOpen}
              formData={formData}
            />
          </Box>
          {/* Reuse the Buttons component here */}
          <Buttons />
        </Box>
      )}
    </Box>
  );
};

export default IdentifyStakeHolderPhase;
