import React from "react";
import { Box } from "@mui/material";
import classNames from "classnames";

const RightSVGIcon = ({ styles, customClass, svgStyle }) => {
  return (
    <Box className={classNames(styles["icon"], customClass)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        class="component-iconify MuiBox-root css-wxaufm iconify iconify--solar"
        width="1em"
        style={svgStyle}
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M6.25 19a.75.75 0 0 0 1.32.488l6-7a.75.75 0 0 0 0-.976l-6-7A.75.75 0 0 0 6.25 5z"
          opacity=".5"
        ></path>
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M10.512 19.57a.75.75 0 0 1-.081-1.058L16.012 12l-5.581-6.512a.75.75 0 1 1 1.139-.976l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.058.082"
          clip-rule="evenodd"
        ></path>
      </svg>
    </Box>
  );
};

export default RightSVGIcon;
