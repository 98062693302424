import { Box, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { Delete, Edit, Send } from "@mui/icons-material";
import { showNotification } from "src/components/notification";
import { useLeadNotesMutation } from "src/hooks/useLeadsData";
import { useQueryClient } from "@tanstack/react-query";
import { formatDateAndTime } from "src/utils/formatTime";
import EditNotesModal from "./edit-notes";
import classNames from "classnames";
import NameProfilePic from "src/components/common/name-profile-pic";

const Notes = ({
  styles,
  data,
  id,
  handleDeleteNotes,
  handleEditNotes,
  editData,
  setEditData,
}) => {
  const [inputText, setInputText] = useState("");

  const leadsMutation = useLeadNotesMutation();
  const queryClient = useQueryClient();

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendClick = async () => {
    try {
      await leadsMutation.mutateAsync(
        { title: "Title", description: inputText, id: id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification("success", "Notes Added Successfully.!", 2000);
            setInputText("");
          },
        }
      );
    } catch (error) {
      console.error(error);
      const message = `Adding Notes failed: ${JSON.stringify(
        error?.response?.data
      )}`;
      showNotification("error", message, "Adding Notes failed", 5000);
    }
  };

  const renderMessages = () => {
    return data?.lead_detail?.lead_notes.map((message, index) => (
      <Box
        key={index}
        className={`${styles["avtar_box"]} ${styles["note_card"]}`}
      >
        <Box>
          <Box className={styles["uname_date"]}>
            <NameProfilePic
              letter={
                message?.created_by_detail?.fullname
                  ? message?.created_by_detail?.fullname[0].toUpperCase()
                  : message?.created_by_detail?.email
                  ? message?.created_by_detail?.email[0].toUpperCase()
                  : ""
              }
              customClass={styles["profile-icon"]}
              customStyle={{
                fontSize: "13px !important",
              }}
            />
            <Box className={styles["name_text"]}>
              {/* <h2 className={styles["notes_title"]}>{message?.title}</h2> */}
              <p className={styles["notes_title"]}>
                {message?.created_by_detail?.fullname ||
                  message?.created_by_detail?.email}{" "}
                - <span>{formatDateAndTime(message?.created_at)}</span>
              </p>
            </Box>
          </Box>
          <Box className={styles["comment_text"]}>{message?.description}</Box>
          <Box className={styles["notes_chip_main"]}>
            <Box
              className={styles["notes_chip_edit"]}
              onClick={() => {
                setEditData({
                  ...editData,
                  editing: true,
                  message: message,
                  notes_id: message?.id,
                });
              }}
            >
              <Edit className={styles["note_action_icon"]} /> Edit
            </Box>
            <Box
              className={styles["notes_chip_delete"]}
              onClick={(e) => {
                handleDeleteNotes(e, message?.id);
              }}
            >
              <Delete className={styles["note_action_icon"]} /> Delete
            </Box>
          </Box>
        </Box>
      </Box>
    ));
  };

  return (
    <Box className={classNames("common-box-shadow", styles["main_card"])}>
      <Box className={styles["message-div"]}>
        {data?.lead_detail?.lead_notes?.length !== 0 ? (
          renderMessages()
        ) : (
          <Box className={styles["empty-notes"]}>
            Oops..! No any Notes yet..!
          </Box>
        )}
      </Box>
      <Box sx={{ position: "absolute", bottom: 20, width: "100%" }}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            handleSendClick();
          }}
          style={{ width: "80vw", position: "", bottom: 0 }}
        >
          <TextField
            placeholder="Please enter text"
            value={inputText}
            className={styles["notes-input"]}
            onChange={handleInputChange}
            multiline
            InputProps={{
              style: { padding: "5px 14px" },
              endAdornment: (
                <IconButton aria-label="send" type="submit">
                  <Send />
                </IconButton>
              ),
            }}
          />
        </form>
      </Box>
      <EditNotesModal
        open={editData?.editing}
        handleClose={() => setEditData({ ...editData, editing: false })}
        handleSubmit={(e) => handleEditNotes(e, editData?.notes_id)}
        editData={editData}
        setEditData={setEditData}
        styles={styles}
        handleChange={(e) =>
          setEditData({
            ...editData,
            message: { ...editData?.message, description: e.target.value },
          })
        }
      />
    </Box>
  );
};

export default Notes;
