import { Box } from "@mui/material";
import React from "react";
import ClassNames from "classnames";

const NameProfilePic = ({ letter, customClass, customStyle }) => {
  return (
    <Box className={ClassNames("avatar-pic", customClass)} sx={customStyle}>
      {letter}
    </Box>
  );
};

export default NameProfilePic;
