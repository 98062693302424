import React from "react";
import { Box, Tooltip } from "@mui/material";
import UpSVGIcon from "./svg/up-svg";
import RightSVGIcon from "./svg/right-svg";
import { TASK_ACTIVITY_STATUS } from "src/utils/constants";
import { getLabelForValue } from "src/utils/others";
import DownSVGIcon from "./svg/down-svg";
import { CalendarIcon } from "@mui/x-date-pickers";
import { formatDateAndTimeWithoutSec } from "src/utils/formatTime";
import { AlarmOnOutlined, LockClockSharp } from "@mui/icons-material";

const PRIORITY_COLORS = {
  low: "rgb(0, 184, 217)",
  medium: "rgb(255, 171, 0)",
  high: "rgb(255, 86, 48)",
};

const STATUS_COLORS = {
  pending: "rgb(255, 86, 48)",
  inprog: "rgb(255, 171, 0)",
  completed: "rgb(0, 217, 49)",
};

const ActivityCard = ({ styles, data, handleEditClick }) => {
  const renderTask = () => (
    <Box className={styles["activity-card-main"]} onClick={handleEditClick}>
      <Box className={styles["title"]}>{data?.activity}</Box>
      <Box className={styles["description"]}>{data?.description}</Box>
      <Box className={styles["bottom-info"]}>
        <Box className={styles["status"]}>
          {data?.priority?.toLowerCase() === "low" ? (
            <Tooltip title={`Priority: ${data?.priority}`} placement="top">
              <DownSVGIcon
                styles={styles}
                customClass={styles["up-icon"]}
                svgStyle={{
                  height: "1.3em",
                  width: "1.3em",
                  color: PRIORITY_COLORS[data?.priority?.toLowerCase()],
                }}
              />{" "}
            </Tooltip>
          ) : (
            <Tooltip title={`Priority: ${data?.priority}`} placement="top">
              <UpSVGIcon
                styles={styles}
                customClass={styles["up-icon"]}
                svgStyle={{
                  height: "1.3em",
                  width: "1.3em",
                  color: PRIORITY_COLORS[data?.priority?.toLowerCase()],
                }}
              />{" "}
            </Tooltip>
          )}
          <Tooltip
            title={`Status: ${getLabelForValue(
              TASK_ACTIVITY_STATUS,
              data?.status
            )}`}
            placement="top"
          >
            <RightSVGIcon
              styles={styles}
              customClass={styles["right-icon"]}
              svgStyle={{
                height: "1.3em",
                width: "1.3em",
                color: STATUS_COLORS[data?.status?.toLowerCase()],
              }}
            />{" "}
          </Tooltip>
          {data?.send_reminder && (
            <Tooltip title={"Remind me"} placement="top">
              <AlarmOnOutlined fontSize="16px" />{" "}
            </Tooltip>
          )}
        </Box>
        <Tooltip placement="top" title="Task Deadline">
          <Box className={styles["creator"]}>
            <CalendarIcon fontSize="16px" sx={{ marginRight: "5px" }} />{" "}
            {data?.deadline}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );

  const renderMeeting = () => (
    <Box className={styles["activity-card-main"]} onClick={handleEditClick}>
      <Box className={styles["title"]}>{data?.activity}</Box>
      <Box className={styles["description"]}>{data?.description}</Box>
      <Box className={styles["bottom-info"]}>
        <Box className={styles["status"]}>
          {data?.priority?.toLowerCase() === "low" ? (
            <Tooltip title={`Priority: ${data?.priority}`} placement="top">
              <DownSVGIcon
                styles={styles}
                customClass={styles["up-icon"]}
                svgStyle={{
                  height: "1.3em",
                  width: "1.3em",
                  color: PRIORITY_COLORS[data?.priority?.toLowerCase()],
                }}
              />{" "}
            </Tooltip>
          ) : (
            <Tooltip title={`Priority: ${data?.priority}`} placement="top">
              <UpSVGIcon
                styles={styles}
                customClass={styles["up-icon"]}
                svgStyle={{
                  height: "1.3em",
                  width: "1.3em",
                  color: PRIORITY_COLORS[data?.priority?.toLowerCase()],
                }}
              />{" "}
            </Tooltip>
          )}
          <Tooltip
            title={`Status: ${getLabelForValue(
              TASK_ACTIVITY_STATUS,
              data?.status
            )}`}
            placement="top"
          >
            <RightSVGIcon
              styles={styles}
              customClass={styles["right-icon"]}
              svgStyle={{
                height: "1.3em",
                width: "1.3em",
                color: STATUS_COLORS[data?.status?.toLowerCase()],
              }}
            />{" "}
          </Tooltip>
          {data?.send_reminder && (
            <Tooltip title={"Remind me"} placement="top">
              <AlarmOnOutlined fontSize="16px" />
            </Tooltip>
          )}
        </Box>
        <Tooltip
          title={
            data?.type_of_activity?.toLowerCase() === "meeting"
              ? "Meeting Time"
              : "Call Time"
          }
          placement="top"
        >
          <Box className={styles["creator"]}>
            <LockClockSharp fontSize="16px" sx={{ marginRight: "5px" }} />{" "}
            {formatDateAndTimeWithoutSec(data?.meeting_appointment)}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );

  return (
    <Box>
      {data?.type_of_activity?.toLowerCase() === "task"
        ? renderTask()
        : renderMeeting()}
    </Box>
  );
};

export default ActivityCard;
