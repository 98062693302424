import { useQuery, useMutation } from "@tanstack/react-query";
import {
  authorizedDelete,
  authorizedGet,
  authorizedPatch,
  authorizedPost,
  authorizedPostUpload,
  authorizedUploadFile,
} from "src/api/base";

export function useLeadsData(params = {}) {
  const { page, limit, ...otherParams } = params;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["leadsData"],
    queryFn: async () =>
      await authorizedGet({
        uri: `/api/v1/leads/`,
        params: { page, limit, ...otherParams },
      }),
  });

  return { data, isLoading, isError, error };
}

export function useLeadDetails({ id }) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["leadDetail", id],
    queryFn: async () => await authorizedGet({ uri: `/api/v1/leads/${id}` }),
  });

  return { data, isLoading, isError, error };
}

export function useLeadsMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({ uri: "/api/v1/leads/", params: {}, body: data }),
  });

  return mutation;
}

export function useLeadNotesMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({
        uri: `/api/v1/leads/${data?.lead_id}/notes/`,
        params: {},
        body: data,
      }),
  });

  return mutation;
}
export function useLeadNotesEditMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPatch({
        uri: `/api/v1/leads/notes/${data?.notes_id}/`,
        params: {},
        body: data,
      }),
  });
  return mutation;
}
export function useLeadAttachmentMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedUploadFile({
        uri: `/api/v1/leads/${data?.id}/attachments/`,
        params: {},
        body: data?.data,
      });
    },
  });

  return mutation;
}

export function useDeleteLeadAttachmentMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedDelete({
        uri: `/api/v1/leads/attachments/${data?.attachment_id}/`,
        params: {},
      });
    },
  });

  return mutation;
}

export function useDeleteLeadNoteMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedDelete({
        uri: `/api/v1/leads/notes/${data?.note_id}/`,
        params: {},
      });
    },
  });

  return mutation;
}

export function useLeadUpdateMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedPatch({
        uri: `/api/v1/leads/${data?.id}/`,
        params: {},
        body: data,
      });
    },
  });

  return mutation;
}

export function useLeadToOpportunityMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedPost({
        uri: `/api/v1/opportunities/`,
        params: {},
        body: data,
      });
    },
  });

  return mutation;
}

export function useDeleteLeadsMutation() {
  const mutation = useMutation({
    mutationFn: async (id) =>
      await authorizedDelete({ uri: `/api/v1/leads/${id}` }),
  });

  return mutation;
}

export function usePatchLeadsMutation() {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) => {
      return await authorizedPatch({
        uri: `/api/v1/leads/${id}/`,
        params: {},
        body: body,
      });
    },
  });

  return mutation;
}

export function useLeadActivityMutation({ id }) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["activityData", id],
    queryFn: async () =>
      await authorizedGet({ uri: `/api/v1/leads/${id}/activities/` }),
  });

  return { data, isLoading, isError, error };
}

export function usePostLeadActivityMutation() {
  const mutation = useMutation({
    mutationFn: async ({ body }) =>
      await authorizedPost({
        uri: `/api/v1/leads/activities/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
}

export function usePatchLeadActivityMutation() {
  const mutation = useMutation({
    mutationFn: async ({ body }) =>
      await authorizedPatch({
        uri: `/api/v1/leads/activities/${body?.id}/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
}

export const useLeadsStakeholderActivityData = ({ id }) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["stakeholders", id],
    queryFn: async () =>
      await authorizedGet({ uri: `/api/v1/leads/${id}/stakeholders/` }),
  });

  return {
    stakeholderData: data?.results[0],
    stakeholderLoading: isLoading,
    isError,
    error,
  };
};

export const usePostStakeholderMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) =>
      await authorizedPost({
        uri: `/api/v1/leads/${id}/stakeholders/contacts/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
};

export const usePatchStakeholderMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ body }) =>
      await authorizedPatch({
        uri: `/api/v1/leads/contacts/${body?.id}/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
};

export const useLeadsProposalActivityData = ({ id }) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["proposals"],
    queryFn: async () =>
      await authorizedGet({ uri: `/api/v1/leads/${id}/proposals` }),
  });

  return {
    proposalData: data?.results,
    proposalLoading: isLoading,
    isError,
    error,
  };
};

export const usePostProposalMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) =>
      await authorizedPostUpload({
        uri: `/api/v1/leads/${id}/proposals/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
};
export const usePatchProposalMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ body, id, lead_id }) =>
      await authorizedPatch({
        uri: `/api/v1/leads/${lead_id}/proposals/${id}/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
};
export const usePostMarkAsCompletedProposalMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ id }) =>
      await authorizedPostUpload({
        uri: `/api/v1/leads/${id}/proposals/mark-as-completed/`,
        params: {},
      }),
  });

  return mutation;
};

export const useLeadsCloseActivityData = ({ id }) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["close-lead", id],
    queryFn: async () =>
      await authorizedGet({ uri: `/api/v1/leads/close-lead/${id}/` }),
  });

  return { closeData: data, closeLoading: isLoading, isError, error };
};

export const usePostCloseLeadMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) =>
      await authorizedPatch({
        uri: `/api/v1/leads/close-lead/${id}/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
};

export const usePostCloseLostLeadsMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) =>
      await authorizedPost({
        uri: `/api/v1/leads/close-lead/${id}/mark-as-lost/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
};

export const usePatchCloseWinLeadsMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) =>
      await authorizedPatch({
        uri: `/api/v1/leads/close-lead/${id}/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
};
