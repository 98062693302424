import React from "react";
import { Box, Button } from "@mui/material";
import { Check } from "@mui/icons-material";

const CallCenterheader = ({ styles }) => {
  return (
    <>
      <Box className={styles["main-box"]}>
        <Box className={styles["container"]}>
          <Box className={styles["first_container_box"]}>
            <Box>
              <Box className={styles["first-container"]}>
                How can we help you?
              </Box>
              <Box className={styles["first_container_desc"]}>
                Have questions? Search through our Help Center. For Further
                Info, Please Contact Us With This Line{" "}
              </Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Box className={styles["check_icon_box"]}>
                    <Check className={styles["check_icon"]} />
                  </Box>

                  <Box className={styles["chekbox_text"]}>
                    234 Triumph, Los Angeles, California, US
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box className={styles["check_icon_box"]}>
                    <Check className={styles["check_icon"]} />
                  </Box>

                  <Box className={styles["chekbox_text"]}>
                    +1 876 43987 247{" "}
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box className={styles["check_icon_box"]}>
                    <Check className={styles["check_icon"]} />
                  </Box>

                  <Box className={styles["chekbox_text"]}>
                    helo133@gmail.ca{" "}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Button className={styles["contact_btn"]}>Contact Us</Button>
                <Button className={styles["contact_btn"]}>Know More</Button>
              </Box>
            </Box>
            <Box className={styles["second-container"]}>
              <img
                src="/assets/contact.svg"
                alt="contact"
                className={styles["second-container-img"]}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}></Box>
    </>
  );
};

export default CallCenterheader;
