import { Box } from "@mui/material";
import React from "react";
import styles from "../style.module.css";
import { CheckCircle, Circle } from "@mui/icons-material";
const About = () => {
  return (
    <Box className={styles["about-section-container"]} id="about">
      {/* <Box className={styles["about-background-image-container"]}>
        <img src="/assets/feature.png" alt="" style={{ height: "400px" }} />
      </Box> */}
      <Box className={styles["about-section-image-container"]}>
        <img src="/assets/lead3.svg" alt="" />
      </Box>
      <Box className={styles["about-section-text-container"]}>
        <Box className={styles["home-text-chip"]}>
          <Circle sx={{ height: "11px" }} /> About
        </Box>
        <h1 className={styles["primary-heading"]}>What We Do </h1>
        <p className={styles["primary-text"]}>
          We offer state-of-the-art LMS solutions. Our platform is designed to
          optimize lead tracking and conversion for your business.
          <br/>
           
        </p>
        <d className={styles["primary-text2"]}>
           We understand the challenges businesses face in converting
          leads into loyal customers and have designed our platform to
          seamlessly integrate with your existing systems, providing actionable
          insights and empowering strategic decision-making.
        </d>
        <Box>
          <Box sx={{ display: "flex" }}>
           
            <CheckCircle sx={{color:"#174276",marginRight:"10px"}}/>

            <Box className={styles["chekbox_text"]}>
            <b> Why Choose Us: </b> Our LMS stands out for its effectiveness and ease of use. We help businesses streamline their sales processes.
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
           
            <CheckCircle sx={{color:"#174276",marginRight:"10px"}}/>

            <Box className={styles["chekbox_text"]}>
            <b> About Our Platform: </b> Our LMS is built with the latest technology. We aim to simplify your lead management and enhance your sales strategies.
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
           
            <CheckCircle sx={{color:"#174276",marginRight:"10px"}}/>

            <Box className={styles["chekbox_text"]}>
            <b> Who We Serve: </b> We cater to businesses of all sizes. Our LMS is designed to meet diverse needs and help you achieve your sales goals.
            </Box>
          </Box>
         
        </Box>
      </Box>
    </Box>
  );
};

export default About;
