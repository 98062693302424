import React from "react";
import { Close } from "@mui/icons-material";
import { Box, Dialog,Checkbox, FormControlLabel } from "@mui/material";
import { SubmitButton } from "src/components/common/common-button";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import { CommonInputField } from "src/components/common/common-input-field";
import {
  TASK_ACTIVITY_PRIORITY,
  TASK_ACTIVITY_STATUS,
} from "src/utils/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatDateTimeLocal } from "src/utils/formatTime";

const MeetingModal = ({
  styles,
  open,
  handleDialogClose,
  editing,
  meetingFormData,
  handleSubmit,
  handleChange,
  handleUpdate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Box className={styles["main-dialog"]}>
      <Dialog open={open} fullWidth>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>
            {!editing ? "Add Meeting" : "Edit Meeting"}
          </Box>
          <Box>
            <Close className={styles["close"]} onClick={handleDialogClose} />
          </Box>
        </Box>
        <form onSubmit={editing ? handleUpdate : handleSubmit} style={{ padding: "20px" }}>
          <CommonInputField
            placeholder="Title"
            name="activity"
            value={meetingFormData?.activity || ""}
            onChange={handleChange}
          />
          <CommonInputField
            placeholder="Description"
            name="description"
            customClass={styles["text-area"]}
            value={meetingFormData?.description || ""}
            onChange={handleChange}
            minLines={4}
          />

          <Box sx={{ display: "flex" }}>
            <Box className={styles["date"]}>
              <input
                type="datetime-local"
                name="meeting_appointment"
                className={styles["date-input"]}
                value={
                  meetingFormData?.meeting_appointment
                    ? formatDateTimeLocal(meetingFormData?.meeting_appointment)
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
            <Box className={styles["checkbox-container"]}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={meetingFormData?.send_reminder}
                    onChange={(e) => handleChange(e, "checkbox")}
                    color="primary"
                    name="send_reminder"
                  />
                }
                label="Set Reminder"
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CommonDropdownField
              name="priority"
              placeholder="Priority"
              variant="outlined"
              value={meetingFormData?.priority || "default"}
              onChange={handleChange}
              options={TASK_ACTIVITY_PRIORITY}
              customClass={"half-width"}
            />
            <CommonDropdownField
              name="status"
              placeholder="Status"
              variant="outlined"
              value={meetingFormData?.status || "default"}
              onChange={handleChange}
              options={TASK_ACTIVITY_STATUS}
              customClass={"half-width"}
            />
          </Box>

          <SubmitButton
            fullWidth={true}
            text={editing ? "Update Meeting" : "Add Meeting"}
          />
        </form>
      </Dialog>
    </Box>
  </LocalizationProvider>
  );
};

export default MeetingModal;
