import React, { createContext, useContext, useState } from "react";

// Define the available plans
const plans = {
  free: { plan: "Free", price: "0" },
  professional: { plan: "Professional", price: "15" },
  enterprise: { plan: "Enterprise", price: "30" },
};

const PlanContext = createContext();

export const usePlan = () => useContext(PlanContext);

export const PlanProvider = ({ children }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const updateSelectedPlan = (key) => {
    setSelectedPlan(plans[key] || null); // Set the selected plan as an object with both plan and price
  };

  const value = {
    selectedPlan,
    setSelectedPlan: updateSelectedPlan,
  };

  return (
    <PlanContext.Provider value={value}>
      {children}
    </PlanContext.Provider>
  );
};
