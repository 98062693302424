import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const DealsKeyDetails = ({ styles, data }) => {
  const [isCollapsed, setIsCollapsed] = useState(false); // State to handle toggle

  const infoArray = [
    {
      label: "Budget Amount",
      value: `INR ${data?.closing_value}`,
      imageSrc: "/assets/icons/deals/budget.png",
    },
    {
      label: "Opportunity",
      value: `INR ${data?.opportunity_amount || "----"}`,
      imageSrc: "/assets/icons/deals/budget.png",
    },
    {
      label: "probablity",
      value: `${data?.probability +"%" || "----"}`,
      imageSrc: "/assets/icons/lead/probablity.png",
    },
    {
      label: "Status",
      value: `${data?.status || "----"}`,
      imageSrc: "/assets/icons/lead/status.png",
    },
    {
      label: "Source",
      // value: `${formatDate(data?.created_at)}`,
      value: `${data?.source || "----"}`,
      imageSrc: "/assets/icons/deals/website.png",
    },
    {
      label: "Created By",
      value: `${data?.created_by?.fullname}`,
      imageSrc: "/assets/icons/lead/man.png",
    },
  ];

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Toggle collapse state
  };

  return (
    <Box
      className={`${styles["info-main-div"]} ${
        isCollapsed ? styles.collapsed : styles.expanded
      }`} // Apply dynamic classes
    >
      <Box className={styles["info_main_details"]}>
        <Typography className={styles["lead_info"]}>Key Details</Typography>

        {/* Conditional rendering based on isCollapsed */}
        {!isCollapsed && (
          <Grid container spacing={2}>
            {infoArray.map((info, index) => (
              <Grid item xs={12} sm={4} md={2} key={index}>
                <Box className={styles.lead_info_box}>
                  <Box className={styles.lead_info_one}>
                    <Box className={styles.icon_box}>
                      <img
                        src={info.imageSrc}
                        alt={info.label}
                        style={{
                          width: "23px",
                          height: "23px",
                          margin: "5px 8px 0px 0px",
                        }}
                      />
                    </Box>
                    <Box className={styles.lead_short_info}>
                      <Box className={styles.lead_amount_text}>
                        {info.label}
                      </Box>
                      <Box className={styles.lead_amount_value}>
                        {info.value}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Box className={styles["info_sec_details"]}>
        {/* Clickable box for toggling collapse */}
        <Box className={styles["info_sec"]} onClick={toggleCollapse}>
          <ExpandMore
            className={`${styles["info_sec_icon"]} ${
              isCollapsed ? styles.info_sec_icon_rotated : ""
            }`} // Rotate icon if collapsed
          />
          <Box className={styles["info_sec_text"]}>
            {isCollapsed ? "Expand" : "Collapse"}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DealsKeyDetails;
