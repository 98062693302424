import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const faqData = [
  //   {
  //     question: "What is a Lead Management System (LMS)?",
  //     answer:
  //       "A Lead Management System (LMS) is a comprehensive tool designed to streamline the process of capturing, tracking, and managing leads, enabling businesses to efficiently convert prospects into customers and optimize their sales pipeline.",
  //   },
  //   {
  //     question: "Why should my business use an LMS?",
  //     answer:
  //       "Using an LMS can significantly enhance your sales process by organizing leads, automating follow-ups, providing insightful analytics, and ultimately boosting conversion rates and customer relationships.",
  //   },
  //   {
  //     question: "How does lead scoring enhance my sales process?",
  //     answer:
  //       "Lead scoring automatically evaluates and ranks leads based on their engagement and demographic data, allowing your sales team to focus on the most promising prospects and improve conversion rates.",
  //   },
  //   {
  //     question: "What makes your LMS's lead nurturing capabilities unique?",
  //     answer:
  //       "Our LMS offers advanced automated nurturing sequences that deliver personalized content and follow-ups at the right time, ensuring your leads receive relevant information that drives them towards making a purchase.",
  //   },
  //   {
  //     question: "How easy is it to set up the LMS?",
  //     answer:
  //       "Setting up our LMS is quick and straightforward. With user-friendly onboarding and comprehensive support, you can start capturing and managing leads within minutes.",
  //   },

  //   {
  //     question: "How can I maximize the effectiveness of lead tracking?",
  //     answer:
  //       "Regularly monitor lead interactions, update lead statuses, and utilize our comprehensive analytics to refine your strategies and ensure no lead falls through the cracks.",
  //   },
  //   {
  //     question: "What support options are available if I need help?",
  //     answer:
  //       "We offer 24/7 customer support through live chat, email, and phone. Additionally, our extensive knowledge base and community forums are always available to help you resolve any issues.",
  //   },
  //   {
  //     question: "How do I report a problem or request a new feature?",
  //     answer:
  //       "Simply log into our support portal to submit a ticket for any issues or feature requests. Our team prioritizes user feedback and works diligently to address your needs.",
  //   },
  // ];

  const faqData = [
    {
        question: "What is CRM?",
        answer: "Customer Relationship Management (CRM) is a system that helps businesses manage interactions with current and potential customers. It uses data analysis to improve business relationships, focusing on customer retention and driving sales growth."
    },
    {
        question: "What is a Lead?",
        answer: "A lead is a potential customer who has shown interest in your product or service. Our LMS helps you capture, track, and nurture these leads, guiding them through the sales funnel until they become loyal customers."
    },
    {
        question: "How is a Lead Converted to a Deal?",
        answer: "Converting a lead to a deal involves several steps: capturing the lead, nurturing them with personalized communication, and moving them through the sales funnel. Our LMS streamlines this process by providing tools for effective lead management, ensuring a smooth transition from prospect to paying customer."
    },
    {
        question: "What is a Progress Bar?",
        answer: "A progress bar visually represents the stages of a lead or deal within the sales pipeline. It helps you quickly understand where each lead stands, allowing you to focus on actions needed to move them closer to a successful conversion."
    },
    {
        question: "What is Dashboard Analysis?",
        answer: "Dashboard Analysis provides a comprehensive view of your sales performance and lead management activities. It uses visual data representations like charts and graphs to help you monitor key metrics, identify trends, and make data-driven decisions."
    },
    {
        question: "Can We Export Data in LMS?",
        answer: "Yes, our LMS allows you to easily export your data in various formats like CSV and Excel. This feature ensures that you can maintain comprehensive records, perform detailed analysis, and integrate data with other tools or systems."
    },
    {
        question: "Can We Send Emails via CRM?",
        answer: "Absolutely! Our CRM integration enables you to send personalized emails directly from the platform. You can automate email campaigns, track responses, and ensure timely communication with your leads and customers, enhancing engagement and conversion rates."
    }
];

  return (
    <Container
      id="faq"
      sx={{
       
        pb: { xs: 4, sm: 8 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h3"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Frequently Asked <span style={{color:"#174276"}}>Questions</span>
      </Typography>
      <Box sx={{ width: "100%" }}>
        {faqData.map((item, index) => (
          <Accordion
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}d-content`}
              id={`panel${index + 1}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: "100%", md: "80%" } }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
