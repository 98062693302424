import React from "react";
import { Box } from "@mui/material";
import { CommonInputField } from "src/components/common/common-input-field";
import classNames from "classnames";
import { formatDateAndTime } from "src/utils/formatTime";
import { Attachment, Edit } from "@mui/icons-material";

const ProposalNewCard = ({
  styles,
  data,
  index,
  handleEditModalOpen,
  setProposalFormData,
  proposalData,
}) => {
  return (
    <>
      <Box className={styles["analysi_main"]}>
        <Box className={styles["analysis_box"]}>
          <Box className={styles["chip_edit"]}>
            <Box className={classNames("info-chip", styles["custom-chip"])}>
              Proposal {index + 1}
            </Box>
            <Box
              className={styles["edit_phase"]}
              onClick={() => {
                handleEditModalOpen();
                setProposalFormData({ ...proposalData });
              }}
            >
              <Edit className={styles["edit_phase_icon"]} />
            </Box>
          </Box>
          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Name"
              name="name"
              // onChange={handleChange}
              value={data?.to}
              customClass={"full-width-proposal"}
            />
          </Box>
          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Email"
              name="email"
              // onChange={handleChange}
              value={data?.cc}
              customClass={"full-width-proposal"}
            />
          </Box>

          <CommonInputField
            placeholder="Comment"
            name="comment"
            // onChange={handleChange}
            value={
              data?.subject?.length > 53
                ? `${data?.subject?.substring(0, 53)} ...`
                : data?.subject
            }
            customClass={styles["stake-text-area"]}
            minLines={4}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Attachment sx={{ fontSize: "16px" }} />{" "}
          <Box sx={{ fontSize: "12px", color: "grey" }}>Attachment</Box>
        </Box>
        <Box className={styles["progress_card_job"]}>
          {formatDateAndTime(data?.updated_at)}
        </Box>
      </Box>
    </>
  );
};

export default ProposalNewCard;
