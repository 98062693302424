import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";

function getProgressMappedValue(a) {
  switch (a) {
    case 1:
      return 10;
    case 2:
      return 30;
    case 3:
      return 50;
    case 4:
      return 70;
    case 5:
      return 100;
    default:
      return null; // or some default value if `a` is out of expected range
  }
}

function ProgressStepper({
  styles,
  setCurrentAction,
  currentAction,
  data,
  closeLeadData,
  stakeholderData,
  analysis,
}) {
  const [completion, setCompletion] = useState(1);

  useEffect(() => {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element?.isCompleted) {
        setCompletion(index + 1);
      }
    }
  }, [data, closeLeadData, stakeholderData, analysis]);

  return (
    <Box className={styles["progress_main"]}>
      <Box className={styles["progress"]}>
        {data.map((item, index) => (
          <Tooltip title={item.tooltip} key={index}>
            <Box
              className={styles["single_step"]}
              onClick={() => {
                if (item.sequence !== 1) {
                  setCurrentAction(item.sequence);
                }
              }}
            >
              <Box className={styles["single_step_one"]}>
                <Box
                  className={styles["progress_num"]}
                  style={{
                    backgroundColor: item.isCompleted
                      ? "#19941d"
                      : currentAction === item.sequence
                      ? ""
                      : "white",
                    color: item.isCompleted
                      ? "white"
                      : currentAction === item.sequence
                      ? "white"
                      : "#174276",
                    border: item.isCompleted
                      ? "none"
                      : currentAction === item.sequence
                      ? "none"
                      : "1px solid #174276",
                  }}
                >
                  {item.sequence}
                </Box>
              </Box>
              <Box className={styles["progress_text_completed"]}>
                {item.label}
              </Box>
            </Box>
          </Tooltip>
        ))}
      </Box>
      <Box className={styles["bar-box"]}>
        <Box
          className={styles["bar"]}
          style={{
            backgroundColor: "#19941d",
            width: `${getProgressMappedValue(completion)}%`,
          }}
        ></Box>
      </Box>
    </Box>
  );
}

export default ProgressStepper;
