import React from "react";
import dayjs from "dayjs";
import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Box, Typography } from "@mui/material";
import TaskBySite from "./components/tasks-by-site";
import GradientDonut from "./components/gradient-donut";
import StatsCard from "./components/stats-card";
import Filters from "./components/filters";
import { useEffect, useState } from "react";
import ExportButton from "./components/export";
import { useQuery } from "@tanstack/react-query";
import ColumnChart from "./components/column-chart";
import FunnelChart from "./components/funnel-chart";
import ProgressBarComponent from "./components/progress-bar";

const DashboardAppPage = () => {
  const { data } = useQuery({ queryKey: ["sessionData"] });
  const { setOpen } = useOutletContext();
  const theme = useTheme();
  const [selectedMonth, setSelectedMonth] = useState("jan");
  const [value, setValue] = React.useState(dayjs());

  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
    setOpen(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    const monthInThreeLetters = value.format("MMM").toLowerCase();
    setSelectedMonth(monthInThreeLetters);
  }, [value]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Lead Management </title>
      </Helmet>
      <Container maxWidth="xxl">
        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "13px", alignItems: "center", marginBottom: "2%", borderRadius: "12px", backgroundColor: "#FFFEFE" }}>
          <Typography sx={{ color: "#8F8F8E", marginTop: "18px" }} fontWeight={500} fontSize={14}>
            Dashboard - {new Date().toDateString()}
          </Typography>

          <Grid sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} item xs={12} sm={6} md={12 / 6} lg={3}>
            <ProgressBarComponent value="80" data={data} />
            <Filters
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              value={value}
              setValue={setValue}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
            <ExportButton setOpen={setOpen} />
          </Grid>
        </Box>
        <Grid container spacing={2} sx={{ width: "99%" }} >
          <Grid item xs={12} sm={6} md={12 / 4} lg={12 / 4}>
            <StatsCard
              bgColor="#147AB3"
              textColor="white"
              title="Organic Lead"
              total="324"
              color="success"
              icon={
                <img alt="icon" src="/assets/icons/statsCard/network.png" />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12 / 4} lg={12 / 4}>
            <StatsCard
              textColor="black"
              bgColor="#EDFAF4"
              title="Inorganic Lead"
              // total={"11"}
              total="67"
              icon={<img alt="icon" style={{ color: "black" }} src="/assets/icons/statsCard/inorganic.png" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12 / 4} lg={12 / 4}>
            <StatsCard
              textColor="black"
              bgColor="#EDFAF4"
              title="Assigned Lead"
              total="145"
              icon={<img alt="icon" src="/assets/icons/statsCard/assign.png" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12 / 4} lg={12 / 4}>
            <StatsCard
              textColor="black"
              bgColor="#EDFAF4"
              title="Unassigned Lead"
              total={
                "24"
              }
              icon={<img alt="icon" src="/assets/icons/statsCard/unassign.png" />}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={5}>
            <TaskBySite
              title="Overall Lead Status report"
              chartData={[
                { label: "Lost Lead", value: 8 },
                { label: "closed Lead", value: 92 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={7}>
            <ColumnChart
              title={"Weekly Lead Status"}
              chartData={[
                {
                  name: "Closed",
                  data: [10, 9, 10, 11],
                },
                {
                  name: "Lost",
                  data: [4, 3, 2, 1],
                },
              ]}
              category={["W1", "W2", "W3", "W4"]}
            />
          </Grid>

          <Grid item xs={12} md={3} lg={5}>
            <GradientDonut
              title={"Lead Status"}
              chartData={[
                { label: "Organic", value: 100 },
                { label: "In oraganic", value: 40 },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <FunnelChart
              title={"Probability wise Lead Status"}
              chartData={[
                {
                  name: "Funnel Series",
                  data: [0.2, 0.4, 0.5, 0.7, 0.8],
                },
              ]}
              category={[
                "Create Lead",
                "Analyse Requirement",
                "Identify Stakeholders",
                "Proposal & Demo",
                "Close",
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={3} lg={3}>
            <TaskBySite
              title="Top 5 Busy Bots"
              chartData={[
                { label: "Expense Bot", value: 1000 },
                { label: "Login Bot", value: 3000 },
                { label: "Bot Manager", value: 4000 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}
          {/* 
          <Grid item xs={12} md={6} lg={3}>
            <GaugeCharts title={"Bot Utilization %"} />
          </Grid> */}
          {/* 
          <Grid item xs={12} md={3} lg={3}>
            <TaskBySite
              title="Top 5 Consecutive Failed Bots"
              chartData={TOP_5_CONSECUTIVE_FAILED_BOTS[selectedMonth]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TaskBySite
              title="Run ID Success Ratio %"
              chartData={RUN_ID_RATIO[selectedMonth]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default DashboardAppPage;
