import { SaveAlt } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function ExportButton(props) {
  const { setOpen } = props;

  const handleOpen = (event) => {
    setOpen(false);
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <Button
      disableRipple
      color="inherit"
      onClick={handleOpen}
      endIcon={<SaveAlt fontSize="small" />}
      sx={{
        padding: "2px 18px",
        fontWeight: "400",
        height: "33px",
        backgroundColor: "#117FB5",
        borderRadius: "3px",
        color: "#ffff",
        minWidth: "110px !important",
        marginRight: "10px",
        "&:hover": { backgroundColor: "#0B90D2", color: "#fff" },
      }}
    >
      Export
      <Typography
        component="span"
        variant="subtitle2"
        sx={{ color: "text.secondary" }}
      ></Typography>
    </Button>
  );
}
