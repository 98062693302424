export const parseErrorMessages = (error) => {
  console.log(error);
  if (error.response) {
    if (error.response.status === 500) {
      return "Internal Server Error.";
    }
    if (error.response.status === 404) {
      return "Data not Found On Server.";
    }
    if (error.response.data) {
      const errorData = error.response.data;
      if (Array.isArray(errorData)) {
        return errorData.join(",\n");
      }
      const errorMessages = Object.keys(errorData)
        .map((key) => {
          const errorMessage = Array.isArray(errorData[key])
            ? errorData[key].join(",\n")
            : errorData[key];
          return ` ${key.toUpperCase()}: ${errorMessage}`;
        })
        .join("\n");
      return errorMessages;
    }
  }
  return "An unknown error occurred.";
};
