import * as React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import UpdateForm from "./components/UpdateForm";
import UpdateProfile from "./components/UpdateProfile";
import { useQuery } from "@tanstack/react-query";
import { usePatchOrgMutation } from "src/hooks/useMembersData";
import { useNavigate } from "react-router-dom";
import { showNotification } from "src/components/notification";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Organisation = () => {
  const navigation = useNavigate();
  const { data, isLoading } = useQuery({ queryKey: ["sessionData"] });
  const updateOrgPatch = usePatchOrgMutation();
  const deactivateUserMutation = usePatchOrgMutation();
  const [formData, setFormData] = React.useState({});
  const [orgPic, setOrgPic] = React.useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
    
      if (!data.user_org?.id) {
        throw new Error("Organization ID is missing");
      }
      if (orgPic) {
        formData.append("org_pic", orgPic);
      }
      
      await updateOrgPatch.mutateAsync({ body: formData, id: data.user_org.id });
     
      showNotification("success", "Organization updated successfully", "Update Successful", 5000);
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };



  const deactivateUser = async () => {
    try {
      await deactivateUserMutation.mutateAsync({
        body: { is_active: false },
        id: data.user_org?.id,
      },{onSuccess: (data) => {
        setTimeout ( () =>  showNotification("success", "Organization Deactivated Successfully.!", 2000) , 1000);
      }});
      navigation("/login");
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <ToastContainer />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <UpdateProfile
              orgPic={orgPic}
              setOrgPic={setOrgPic}
              data={data}
              isLoading={isLoading}
              deactivateUser={deactivateUser}
            />
          </Grid>
          <Grid item xs={8}>
            <UpdateForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleUpdate={handleUpdate}
              data={data}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Organisation;
