import React, { useRef } from "react";
import RichTextEditor from "../../../components/text-editor";
import parse from "html-react-parser";
import { Box, Typography } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";

const 
LeadAnalysisPhase = ({
  styles,
  value,
  editedData,
  handleChange,
  getValue,
  isSummaryEditing,
}) => {
  const dateInputRef = useRef(null);

  const handleCalendarClick = () => {
    dateInputRef.current.showPicker();
  };

  const renderPurchaseTimeline = (name, placeholder, value) => {
    return (
      <Box className={styles["single-info-analysis"]}>
        <Box className={styles["title"]}>
          <Typography className={styles["title-text"]}>
            {placeholder}
          </Typography>
        </Box>
        <Box className={styles["input-box"]}>
          <input
            type="date"
            name={name}
            value={value}
            ref={dateInputRef}
            onChange={(e) => handleChange(name, e.target.value)}
            className={styles["purchase-timeline-date"]}
          />
          <Box className={styles["end-icon"]}>
            <CalendarMonth
              onClick={() => isSummaryEditing && handleCalendarClick()}
              fontSize="14px"
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={styles["summary_box"]}>
      <Box className="row">
        <Box className={styles["account-info-card"]}>
          {renderPurchaseTimeline(
            "purchase_timeline",
            "Purchase timeline",
            editedData?.purchase_timeline
          )}
        </Box>
      </Box>
      <Typography className={styles["title-text-comment"]}>
        Requirement
      </Typography>
      {isSummaryEditing ? (
        <RichTextEditor
          initialValue={editedData?.description}
          getValue={getValue}
        />
      ) : (
        <Box className={styles["text-editor"]}>{parse(value)}</Box>
      )}
    </Box>
  );
};

export default LeadAnalysisPhase;
