import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import { Grid, Container, Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiLineCharts from "./components/multi-line-charts";
import { useEmailDashboardData } from "src/hooks/useTemplateData";

const EmailsDashboard = () => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  // Format the start and end dates to yyyy-mm-dd if they are not null
  const formattedStartDate = startDate
    ? dayjs(startDate).format("YYYY-MM-DD")
    : null;
  const formattedEndDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : null;

  const { data: chartData, isLoading } = useEmailDashboardData({
    start_date: formattedStartDate,
    end_date: formattedEndDate,
  });

  // Function to get xAxisCategories with formatted dates
  const getXAxisCategories = () => {
    if (chartData)
      return chartData.map((item) => dayjs(item.date).format("DD MMM"));
    else return [];
  };

  // Function to get series data for each metric
  const getSeriesData = () => {
    const metrics = [
      "delivered",
      "opens",
      "unique_opens",
      "clicks",
      "unique_clicks",
      "bounces",
      "spam_reports",
    ];

    if (chartData)
      return metrics.map((metric) => ({
        name: metric,
        data: chartData.map((item) => item.metrics[metric] || 0),
      }));
    else return [];
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Lead Management </title>
      </Helmet>
      <Container maxWidth="xxl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "13px",
            alignItems: "center",
            marginBottom: "2%",
            borderRadius: "12px",
            backgroundColor: "#FFFEFE",
          }}
        >
          <Typography sx={{ color: "#8F8F8E" }} fontWeight={500} fontSize={14}>
            Emails Statistics
          </Typography>

          <Box className={styles["date-range"]}>
            <DatePicker
              showIcon
              selectsRange={true}
              startDate={startDate}
              maxDate={new Date()}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 48 48"
                >
                  <mask id="ipSApplication0">
                    <g
                      fill="none"
                      stroke="#fff"
                      strokeLinejoin="round"
                      strokeWidth="4"
                    >
                      <path
                        strokeLinecap="round"
                        d="M40.04 22v20h-32V22"
                      ></path>
                      <path
                        fill="#fff"
                        d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                      ></path>
                    </g>
                  </mask>
                  <path
                    fill="currentColor"
                    d="M0 0h48v48H0z"
                    mask="url(#ipSApplication0)"
                  ></path>
                </svg>
              }
            />
          </Box>
        </Box>
        {/* Charts */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MultiLineCharts
              xAxisCategories={getXAxisCategories()}
              seriesData={getSeriesData()}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EmailsDashboard;
