import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const leadSubMenu = [
  {
    title: "Summary",
    path: "lead-summary",
    icon: icon("ic_summary"),
  },
  {
    title: "Notes",
    path: "lead-notes",
    icon: icon("ic_notes"),
  },
  {
    title: "Attachment",
    path: "lead-attachment",
    icon: icon("ic_attachment"),
  },
  {
    title: "Activity",
    path: "lead-activity",
    icon: icon("ic_lead_task"),
  },
];
const dealSubMenu = [
  {
    title: "Summary",
    path: "deals-summary",
    icon: icon("ic_summary"),
  },
];
const contactSubMenu = [
  {
    title: "Summary",
    path: "contact-summary",
    icon: icon("ic_summary"),
  },
  {
    title: "Relationship",
    path: "contact-relationship-analytics",
    icon: icon("ic_notes"),
  },
  {
    title: "Details",
    path: "contact-details",
    icon: icon("ic_attachment"),
  },
  {
    title: "Related",
    path: "contact-related",
    icon: icon("ic_lead_task"),
  },
];
const navConfig = [
  {
    title: "dashboard",
    name: "dashboard",
    path: "/dashboard/app",
    icon: icon("ic_app"),
  },
  {
    title: "Leads",
    name: "lead",
    path: "/dashboard/leads",
    icon: icon("ic_leads"),
    children: leadSubMenu,
  },
  {
    title: "Deals",
    name: "deals",
    path: "/dashboard/deals",
    icon: icon("ic_deals"),
    children: dealSubMenu,
  },
  {
    title: "accounts",
    name: "account",
    path: "/dashboard/accounts",
    icon: icon("ic_accounts"),
  },
  {
    title: "contacts",
    name: "contact",
    path: "/dashboard/contacts",
    icon: icon("ic_contacts"),
    children: contactSubMenu,
  },
  {
    title: "Emails Dashboard",
    name: "email-dashboard",
    path: "/dashboard/emails-dashboard",
    icon: icon("ic_email"),
  },
  {
    title: "Email",
    name: "email",
    path: "/dashboard/email",
    icon: icon("ic_email"),
  },
  {
    title: "help-center",
    name: "Help Center",
    path: "/dashboard/help-center",
    icon: icon("ic_help-center"),
  },
 {
    title: "User Management",
    path: "/dashboard/users",
    icon: icon("ic_users"),
  },
];

export default navConfig;
