import * as React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import UpdateForm from "./components/UpdateForm";
import UpdateProfile from "./components/UpdateProfile";
import { useQuery } from "@tanstack/react-query";
import { usePatchUserMutation } from "src/hooks/useMembersData";
import { useNavigate } from "react-router-dom";
import { showNotification } from "src/components/notification";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Account = () => {
  const navigation = useNavigate();

  const { data, isLoading } = useQuery({ queryKey: ["sessionData"] });
  const updateProfilePatch = usePatchUserMutation();
  const deactivateUserMutation = usePatchUserMutation();
  const [formData, setFormData] = React.useState({});
  const [profilePic, setProfilePic] = React.useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("address.")) {
      const addressField = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUpdate = async () => {
    try {
      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "address" && formData[key] != null) {
          form.append("address", JSON.stringify(formData[key]));
        } else if (key !== "email" && key !== "address") {
          form.append(key, formData[key]);
        }
      });

      if (profilePic) {
        form.append("profile_pic", profilePic);
      }
      await updateProfilePatch.mutateAsync({ body: form, id: data.id },{onSuccess: (data) => {
           setTimeout ( () =>  showNotification("success", "Profile Updated Successfully.!", 2000) , 1000);
         }});
    } catch (error) {
      showNotification("error", error, 8000);
      
    }
  };

  const deactivateUser = async () => {
    try {
      await deactivateUserMutation.mutateAsync({
        body: { is_active: false },
        id: data?.id,
      },{onSuccess: (data) => {
        setTimeout ( () =>  showNotification("success", "Profile Deactivated Successfully.!", 2000) , 1000);
      }});
      navigation("/login");
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <ToastContainer />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <UpdateProfile
              profilePic={profilePic}
              setProfilePic={setProfilePic}
              data={data}
              isLoading={isLoading}
              deactivateUser={deactivateUser}
            />
          </Grid>
          <Grid item xs={8}>
            <UpdateForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleUpdate={handleUpdate}
              data={data}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Account;
