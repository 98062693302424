import React from "react";
import { Box } from "@mui/material";

const ActivityCardHeader = ({ styles, title, number }) => {
  return (
    <Box className={styles["activity-header-main"]}>
      <Box className={styles["card-header-title"]}>{title}</Box>
      <Box className={styles["card-header-number"]}>{number}</Box>
    </Box>
  );
};

export default ActivityCardHeader;
