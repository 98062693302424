import React from "react";
import Select from "react-select";

const CustomSelect = ({ options, value, onChange, placeholder,customStyle, ...rest }) => {
  const selectedOption = options.find(option => option.value === value);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#1e90ff' : '#ced4da',
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#1e90ff' : '#adb5bd',
      },
     
      ...customStyle 
    }),

  };
  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={onChange}
      styles={customStyles}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default CustomSelect;
