import React from "react";
import { Box } from "@mui/material";
import classNames from "classnames";
import { Visibility, Edit, CheckCircle, Cancel } from "@mui/icons-material";
import { SubmitButton } from "src/components/common/common-button";

const ClosePhase = ({
  styles,
  data,
  stakeholderData,
  proposalsData,
  closeLeadData,
  handleWonModalOpen,
  handlecloseLostModalOpen,
  setCurrentAction,
  setFormData,
  openEditCloseLeadModal,
}) => {
  const completedProposals =
    proposalsData?.filter((proposal) => proposal.is_completed) || [];
  const isProposalCompleted =
    completedProposals.length > proposalsData?.length / 2;

  const getCompletedOrNotSummary = (completed) => {
    return (
      <Box
        className={
          completed
            ? styles["close_success_icon"]
            : styles["close_failure_icon"]
        }
      >
        {completed ? (
          <CheckCircle className={styles["progress_card_icon_success"]} />
        ) : (
          <Cancel className={styles["progress_card_icon_failure"]} />
        )}
      </Box>
    );
  };

  const handleCloseLeadEdit = () => {
    setFormData({
      amount_closed_at: data?.closing_value,
      comment: data?.closing_comment,
    });
    openEditCloseLeadModal();
  };

  return (
    <Box className={styles["close_lead_box"]}>
    <Box className={styles["close_main_content"]}>
      <Box
        className={classNames("common-box-shadow", styles["close_card_first"])}
      >
        <Box className={styles["proposal_title"]}>
          <Box className={classNames("common-box-shadow", styles["card_img"])}>
            <img
              src="/assets/requirement.png"
              alt=""
              className={styles["user_to_img"]}
            />
          </Box>
          <Box className={styles["requirement_Box"]}>
            <Box>
              <Box className={styles["proposal_to_name"]}>
                Purchase Timeline
              </Box>
              <Box className={styles["proposal_title"]}>
                <Box className={styles["progress_card_icon_text"]}>
                  {data?.purchase_timeline}
                </Box>
              </Box>
            </Box>
            <Box className={styles["close_date_view"]}>
              {getCompletedOrNotSummary(
                data?.description && data?.purchase_timeline
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        className={classNames("common-box-shadow", styles["close_card_first"])}
      >
        <Box className={styles["proposal_title"]}>
          <Box className={classNames("common-box-shadow", styles["card_img"])}>
            <img
              src="/assets/handshake.png"
              alt=""
              className={styles["user_to_img"]}
            />
          </Box>
          <Box className={styles["requirement_Box"]}>
            {(stakeholderData?.contact?.length !== 0 || stakeholderData) && (
              <Box>
                <Box className={styles["proposal_to_name"]}>
                  Interacted With Stakeholders
                </Box>
                <Box className={styles["proposal_title"]}>
                  {stakeholderData?.contact.map((item, index) => (
                    <Box key={index} className={styles["stakeholder_intract"]}>
                      {item?.name}
                    </Box>
                  ))}
                 
                </Box>
              </Box>
            )}
            <Box className={styles["close_date_view"]}>
              {/* <Box className={styles["card_title"]}>
                <Box className={styles["non_decision_maker"]}>22/02/2024</Box>
              </Box> */}
              <Box
                className={styles["visibility"]}
                onClick={() => setCurrentAction(3)}
              >
                <Visibility className={styles["progress_card_icon_sec"]} />
              </Box>
              {getCompletedOrNotSummary(stakeholderData?.is_completed)}
              {/* <Box className={styles["close_success_icon"]}>
                <CheckCircle className={styles["progress_card_icon_success"]} />
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        className={classNames("common-box-shadow", styles["close_card_first"])}
      >
        <Box className={styles["proposal_title"]}>
          <Box className={classNames("common-box-shadow", styles["card_img"])}>
            <img
              src="/assets/business-proposal.png"
              alt=""
              className={styles["user_to_img"]}
            />
          </Box>
          <Box className={styles["requirement_Box"]}>
            <Box>
              <Box className={styles["proposal_to_name"]}>
                Total Sent Proposal
              </Box>
              <Box className={styles["proposal_title"]}>
                <Box className={styles["progress_card_icon_text"]}>
                  You have sent total of {proposalsData?.length} proposal to
                  this lead..
                </Box>
              </Box>
            </Box>
            <Box className={styles["close_date_view"]}>
              {getCompletedOrNotSummary(isProposalCompleted)}
            </Box>
          </Box>
        </Box>
      </Box>

      {(data?.status === "won" || data?.status === "lost") && (
        <Box
          className={classNames(
            "common-box-shadow",
            styles["close_card_first"]
          )}
        >
          <Box className={styles["proposal_title"]}>
            <Box
              className={classNames("common-box-shadow", styles["card_img"])}
            >
              <img
                src="/assets/payment.png"
                alt=""
                className={styles["user_to_img"]}
              />
            </Box>
            <Box className={styles["requirement_Box"]}>
              <Box>
                <Box className={styles["proposal_to_name"]}>Close Amount</Box>
                <Box className={styles["proposal_title"]}>
                  <Box className={styles["progress_card_icon_text"]}>
                    {data?.closing_value}
                  </Box>
                </Box>
              </Box>
              <Box className={styles["close_date_view"]}>
                {/* <Box className={styles["card_title"]}>
                  <Box className={styles["non_decision_maker"]}>22/02/2024</Box>
                </Box>
                <Box className={styles["visibility"]}>
                  <Visibility className={styles["progress_card_icon_sec"]} />
                </Box> */}
                <Box
                  className={styles["close_success_edit_icon"]}
                  onClick={handleCloseLeadEdit}
                >
                  <Edit className={styles["progress_card_icon_sec"]} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
    <Box className={styles["close_btn_main"]}>
            <Box className={styles["btn_common"]}>
              <SubmitButton
               customClass={styles.fullWidthButton}
                fullWidth={true}
                text={"Close Lead As Won"}
                handleClick={handleWonModalOpen}
                // onClick={handleSubmit}
              />
            </Box>
            <Box>
              <SubmitButton
             customClass={styles.fullWidthButton}
                fullWidth={true}
                text={"Close Lead As Lost"}
                handleClick={handlecloseLostModalOpen}
            
                // onClick={handleSubmit}
              />
            </Box>
            </Box>
    </Box>
  );
};

export default ClosePhase;
