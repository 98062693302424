import { useState } from "react";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  IconButton,
  Popover,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom"; // Import Link for routing
import NameProfilePic from "src/components/common/name-profile-pic";
import { ExpandMore } from "@mui/icons-material";

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
    link: "",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
    link: "/dashboard/profile",
  },
];

const AccountPopover = () => {
  const [open, setOpen] = useState(null);
  const { data } = useQuery({ queryKey: ["sessionData"] });

  const plan = data?.user_profile?.plan
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <>
      <Box
        sx={{
          padding: "6px 8px",
          backgroundColor: "white",
          borderRadius: "30px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        
        }}
        onClick={handleOpen}
      >
        <IconButton
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              "&:before": {
                zIndex: 1,
                content: "''",
                width: "100%",
                height: "100%",
                borderRadius: "30%",
                position: "absolute", // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <Box
            sx={{
              borderRadius: "100%",
              height: "32px",
              width: "32px",
              marginRight:"9px",
              border: "1px solid black",
              backgroundColor: "#174276",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",

              ...(open && {
                backgroundColor: "#1C2536 !important",
              }),
            }}
          >
            {data?.profile_pic ? (
              <img
                src={data?.profile_pic}
                alt="user"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <NameProfilePic
                letter={data?.email ? data?.email[0].toUpperCase() : ""}
                customStyle={{
                  height: "35px !important",
                  width: "35px !important",
                  backgroundColor: "transparent !important",
                }}
              />
            )}
          </Box>
        </IconButton>
        <Box >
          <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <Box sx={{ color: "black", fontSize: "11px", fontWeight: "500",marginRight:"5px"}}>
         
            {data?.fullname || "random"}
          </Box>
          <ExpandMore sx={{ color: "black", fontSize: "14px", fontWeight: "500",marginRight:"9px"}}/>
          </Box>
          <Box sx={{ color: "black", fontSize: "9px", fontWeight: "400" }}>
            Admin
          </Box>
        </Box>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box
          sx={{
            my: 1.5,
            px: 2,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              borderRadius: "100%",
              height: "35px",
              width: "35px",
              border: "1px solid black",
              backgroundColor: "#1C2536",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {data?.profile_pic ? (
              <img
                src={data?.profile_pic}
                alt="user"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <NameProfilePic
                letter={data?.email ? data?.email[0].toUpperCase() : ""}
                customStyle={{
                  height: "35px !important",
                  width: "35px !important",
                }}
              />
            )}
          </Box>
          <Box>
            <Typography variant="subtitle2" noWrap>
              {data?.fullname || "random"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", fontSize: "11px" }}
              noWrap
            >
              {data?.email}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <Link
              to={option.link}
              key={option.label}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>{option.label}</MenuItem>
            </Link>
          ))}
           <MenuItem onClick={handleClose}>{plan}</MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
};

export default AccountPopover;
