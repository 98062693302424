import React, { useState } from "react";
import styles from "../style.module.css";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CommonButton } from "src/components/common/common-button";

const FileUpload = ({ handleFileUpload }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    setIsDragOver(false);
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <Box className={styles["main_drag_card"]}>
      <Box className={styles["main_drag"]}>
        <Box
          className={`${styles.dragArea} ${isDragOver ? styles.active : ""}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <button onClick={handleButtonClick}>
            Drag & Drop to Upload File
          </button>
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            hidden
          />
          {file && (
            <Box className={styles["file-preview"]}>
              {file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="file"
                  className={styles["img_preview"]}
                />
              ) : (
                <span>{file.name}</span>
              )}
              <Box>
                <Close onClick={() => setFile(null)} />
              </Box>
            </Box>
          )}
        </Box>
        {file && (
          <Box className={styles["btns"]}>
            <CommonButton
              text={"Cancel"}
              customClass={styles["button"]}
              handleClick={(e) => {
                e.preventDefault();
                setFile(null);
              }}
            />

            <CommonButton
              text={"Upload"}
              customClass={styles["button"]}
              handleClick={(e) => {
                e.preventDefault();
                handleFileUpload(file);
                setFile(null);
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileUpload;
