import React from "react";
import { Box } from "@mui/material";
import classNames from "classnames";

const UpSVGIcon = ({ styles, customClass, svgStyle }) => {
  return (
    <Box className={classNames(styles["icon"], customClass)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        class="component-iconify MuiBox-root css-1lxnv28 iconify iconify--solar"
        style={svgStyle}
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M5 17.75a.75.75 0 0 1-.488-1.32l7-6a.75.75 0 0 1 .976 0l7 6A.75.75 0 0 1 19 17.75z"
          opacity=".5"
        ></path>
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M4.43 13.488a.75.75 0 0 0 1.058.081L12 7.988l6.512 5.581a.75.75 0 1 0 .976-1.138l-7-6a.75.75 0 0 0-.976 0l-7 6a.75.75 0 0 0-.081 1.057"
          clip-rule="evenodd"
        ></path>
      </svg>
    </Box>
  );
};

export default UpSVGIcon;
