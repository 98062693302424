import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  MenuItem,
  Popover,
} from "@mui/material";
import {
  BorderAll,
  Delete,
  FolderZip,
  GetApp,
  Image,
  MoreHoriz,
  PictureAsPdf,
  Slideshow,
  TextSnippet,
} from "@mui/icons-material";
import classNames from "classnames";
import FileUpload from "./dropzone";
import { getFileType } from "src/utils/others";
import { formatDateAndTime } from "src/utils/formatTime";

const renderIcon = (styles, type) => {
  if (type === "zip") {
    return <FolderZip className={styles["file_attach"]} />;
  } else if (type === "pdf") {
    return <PictureAsPdf className={styles["file_attach"]} />;
  } else if (type === "mp4") {
    return <Slideshow className={styles["file_attach"]} />;
  } else if (type === "txt") {
    return <TextSnippet className={styles["file_attach"]} />;
  } else if (type === "jpg" || type === "png" || type === "jpeg") {
    return <Image className={styles["file_attach"]} />;
  } else if (type === "xlsx" || type === "csv" || type === "jpeg") {
    return <BorderAll className={styles["file_attach"]} />;
  }
};

const Attachment = ({
  styles,
  data,
  handleFileUpload,
  handleOpenMenu,
  anchorEl,
  handleCloseMenu,
  open,
  handleDeleteAttachment,
  setDeleteId,
}) => {
  const [page, setPage] = useState(0);

  return (
    <>
      <Box className={styles["main_attachment"]}>
        <Box
          className={classNames(
            "common-box-shadow",
            styles["attachment_first_card"]
          )}
        >
          <Box className={styles["table_box"]}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={styles["tHead"]}>
                  <TableCell>File Name</TableCell>
                  <TableCell width={200} align="center">
                    Type
                  </TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableHead>
                <TableBody className={styles["tbody"]}>
                  {data?.lead_detail?.attachments?.length === 0 ? (
                    <TableRow className={styles["no-data"]}>
                      <TableCell colSpan={4} align="center">
                        Oops..! No any Attachments yet..!
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.lead_detail?.attachments
                      ?.slice(page * 5, page * 5 + 5)
                      .map((row) => (
                        <TableRow
                          className={styles["table-body-row"]}
                          key={row.id}
                        >
                          <TableCell className={styles["table_cell_sx"]}>
                            <Box className={styles["attachment_box"]}>
                              <Box className={styles["attch_image"]}>
                                {renderIcon(
                                  styles,
                                  getFileType(row?.document_file).toLowerCase()
                                )}
                              </Box>
                              <Box className="flex-grow-1 ms-2 name">
                                {row?.title}
                              </Box>
                            </Box>
                          </TableCell>

                          <TableCell
                            className={styles["table_cell_sx"]}
                            align="center"
                          >
                            {getFileType(row?.document_file).toLowerCase()}
                          </TableCell>

                          <TableCell
                            className={styles["table_cell_sx"]}
                            align="left"
                          >
                            <Box>{formatDateAndTime(row?.created_at)}</Box>
                          </TableCell>

                          <TableCell
                            className={classNames(
                              styles["table_cell_sx"],
                              styles["cell_center"]
                            )}
                          >
                            <Box className={styles["view_more"]}>
                              <MoreHoriz
                                className={styles["view_more_icon"]}
                                onClick={(e) => {
                                  handleOpenMenu(e, "attachment");
                                  setDeleteId(row?.id);
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={data?.attachments?.length || 0}
              rowsPerPage={5}
              page={page}
              onPageChange={(e, pageNo) => {
                setPage(pageNo);
              }}
            />
          </Box>
        </Box>

        <Box
          className={classNames(
            "common-box-shadow",
            styles["attachment_sec_card"]
          )}
        >
          <Box className={styles["table_box"]}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={styles["tHead"]}>
                  <TableRow>
                    <TableCell>File Upload</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Box>
          <Box className={styles["dropzone_area"]}>
            <FileUpload handleFileUpload={handleFileUpload} />
          </Box>
        </Box>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleCloseMenu("attachment")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => handleCloseMenu("attachment")}
          className={styles["attach_popup"]}
        >
          <GetApp className={styles["attach_popup_icon"]} />
          Download
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleCloseMenu("attachment");
            handleDeleteAttachment(e);
          }}
          className={styles["attach_popup"]}
        >
          <Delete className={styles["attach_popup_icon"]} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
};

export default Attachment;
