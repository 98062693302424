import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import EmailEditor from "react-email-editor";

import {
  usePatchTemplateMutation,
  useTemplateData,
  useDeleteTemplateMutation,
} from "src/hooks/useTemplateData";
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from "src/components/notification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "src/components/input/CustomInput";
import { Button, Box } from "@mui/material";
import styles from "../style.module.css";
function PreviewTemplate() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { id } = useParams();
  const emailEditorRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateName, setTemplateName] = useState("");

  const { data, isLoading, isError, error } = useTemplateData();
  const updateMutation = usePatchTemplateMutation();
  const deleteMutation = useDeleteTemplateMutation();

  useEffect(() => {
    if (data) {
      setTemplates(data.results || []);
      const template = data.results.find((template) => template.id === id);
      if (template) {
        setSelectedTemplate(template);
        setTemplateName(template?.name);
      }
    }
  }, [data, id]);

  const handleEditorLoad = () => {
    if (selectedTemplate && emailEditorRef.current) {
      emailEditorRef.current.editor.loadDesign(selectedTemplate.design);
    }
  };

  const saveEditedTemplate = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { html, design } = data;
      const updatedTemplate = { ...selectedTemplate, html, design, name: templateName };
      updateMutation.mutate(
        { data: updatedTemplate },
        {
          onSuccess: () => {
            const updatedTemplates = templates.map((template) =>
              template.id === selectedTemplate.id ? updatedTemplate : template
            );
            setTemplates(updatedTemplates);
            queryClient.invalidateQueries({ queryKey: ["emailTemplate"] });
            showNotification("success", "Template updated successfully!", 2000);
          },
          onError: (err) => {
            console.error("Error updating template:", err);
            showNotification("error", err, 8000);
          },
        }
      );
    });
  };

  const deleteSelectedTemplate = () => {
    if (!selectedTemplate) {
      showNotification("error", "No template selected for deletion.", 8000);
      return;
    }

    deleteMutation.mutate(
      { id: selectedTemplate.id },
      {
        onSuccess: () => {
          const updatedTemplates = templates.filter(
            (template) => template.id !== selectedTemplate.id
          );
          setTemplates(updatedTemplates);
          setSelectedTemplate(null);
          showNotification("success", "Template deleted successfully!", 2000);
          setTimeout(() => {
            navigate("/dashboard/email");
          }, 1000);
        },
        onError: (err) => {
          console.error("Error deleting template:", err);
          showNotification("error", err, 8000);
        },
      }
    );
  };

  if (isLoading) return <div>Loading templates...</div>;
  if (isError) return <div>Error loading templates: {error.message}</div>;

  return (
    <div style={{ padding: "20px" }}>
      <ToastContainer />
      {/* <h2>Edit Email Template</h2> */}
      <CustomInput
        name="template_name"
        placeholder="Template Name"
        value={templateName}
        required
        onChange={(e) => setTemplateName(e.target.value)}
        fullWidth
        style={{ marginBottom: "20px" }}
      />
      <EmailEditor ref={emailEditorRef} onLoad={handleEditorLoad} />
      {selectedTemplate && (
        <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            onClick={saveEditedTemplate}
            className={styles["export_btn"]}
              >
                <Box className={styles["export_btn"]}>  Save Edited Template</Box>
              </Button>
              <Button
            variant="contained"
            onClick={deleteSelectedTemplate}
            className={styles["export_btn"]}
              >
                <Box className={styles["export_btn"]}>  Delete Selected</Box>
              </Button>
        </div>
      )}
    </div>
  );
}

export default PreviewTemplate;
