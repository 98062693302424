import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import styles from "../style.module.css";

const Features = () => {
  return (
    <Box className={styles["main_box_feature"]} id="features">
      <Box className={styles["feature-background-image-container"]}>
        <img src="/assets/feature.png" alt="" style={{ height: "400px" }} />
      </Box>
      <Grid container spacing={5}>
        {/* Top row */}
        <Grid item xs={4}>
          <Paper className={styles["customPaperNoShadow"]}>
            <Typography
              variant="h3"
              gutterBottom
              className={styles["feature_title"]}
            >
              Efficiently route sales for streamlined pipeline management.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={styles["customPaper"]}>
            <Box className={styles["feature_one_box"]}>
              <Box className={styles["feature_one"]}>
                <Box className={styles["customPaper_box"]}>
                  <img
                    src="/assets/images/feature/lead_score.png"
                    alt="Image1"
                    className={styles["circleImage"]}
                  />
                </Box>

                <Typography variant="h6" gutterBottom>
                  Lead Scoring
                </Typography>
                <Typography className={styles["desc_text"]}>
                  Evaluate and prioritize leads based on behavior, demographics,
                  and engagement levels, enabling focused efforts on prospects
                  most likely to convert.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={styles["customPaper"]}>
            <Box className={styles["feature_one_box"]}>
              <Box className={styles["feature_one"]}>
                <Box className={styles["customPaper_box"]}>
                  <img
                    src="/assets/images/feature/contact-mail.png"
                    alt="contact-img"
                    className={styles["circleImage"]}
                  />
                </Box>

                <Typography variant="h6" gutterBottom>
                  Conatct Management
                </Typography>
                <Typography className={styles["desc_text"]}>
                  Centralize and manage contact information and interactions
                  with leads and customers, ensuring streamlined communication
                  and personalized engagement.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        {/* Bottom row */}
        <Grid item xs={4}>
          <Paper className={styles["customPaper"]}>
            <Box className={styles["feature_one_box"]}>
              <Box className={styles["feature_one"]}>
                <Box className={styles["customPaper_box"]}>
                  <img
                    src="/assets/images/feature/lead_track.png"
                    alt="track"
                    className={styles["circleImage"]}
                    style={{ height: "60px", width: "60px" }}
                  />
                </Box>

                <Typography variant="h6" gutterBottom>
                  Lead Tracking
                </Typography>
                <Typography className={styles["desc_text"]}>
                  Monitor lead interactions and movements through the sales
                  pipeline, providing visibility into each prospect's journey
                  and identifying optimal conversion opportunities.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={styles["customPaper"]}>
            <Box className={styles["feature_one_box"]}>
              <Box className={styles["feature_one"]}>
                <Box className={styles["customPaper_box"]}>
                  <img
                    src="/assets/images/feature/tracking.png"
                    alt="tracking"
                    className={styles["circleImage"]}
                  />
                </Box>

                <Typography variant="h6" gutterBottom>
                  Analytics & Reporting
                </Typography>
                <Typography className={styles["desc_text"]}>
                  Access comprehensive analytics and detailed reports on lead
                  performance, conversion rates, and campaign effectiveness,
                  facilitating data-driven decision-making and continuous
                  improvement.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={styles["customPaper"]}>
            <Box className={styles["feature_one_box"]}>
              <Box className={styles["feature_one"]}>
                <Box className={styles["customPaper_box"]}>
                  <img
                    src="/assets/images/feature/travel-agent.png"
                    alt="travel-agent"
                    className={styles["circleImage"]}
                  />
                </Box>

                <Typography variant="h6" gutterBottom>
                  Cusstomer Support Ticketing
                </Typography>
                <Typography className={styles["desc_text"]}>
                  Streamline the management and resolution of customer inquiries
                  and issues through a centralized ticketing system, ensuring
                  efficient and responsive customer support.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
