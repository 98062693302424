import { useQuery, useMutation } from "@tanstack/react-query";
import {
  authorizedDelete,
  authorizedGet,
  authorizedPatch,
  authorizedPost,
  authorizedUploadFile,
} from "src/api/base";

export function useAccountsData(params = {}) {
  const { page, limit, ...otherParams } = params;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["accountsData"],
    queryFn: async () =>
      await authorizedGet({
        uri: `/api/v1/accounts/`,
        params: { page, limit, ...otherParams },
      }),
  });

  return { data, isLoading, isError, error };
}

export function useAccountDetails({ id }) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["opportunityDetail", id],
    queryFn: async () =>
      await authorizedGet({ uri: `/api/v1/accounts/${id}` }),
  });

  return { data, isLoading, isError, error };
}

export function useAccountsMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({ uri: "/api/v1/leads/", params: {}, body: data }),
  });

  return mutation;
}

export function useAccountNotesMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({
        uri: `/api/v1/leads/${data?.id}/notes/`,
        params: {},
        body: data,
      }),
  });

  return mutation;
}
export function useAccountNotesEditMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPatch({
        uri: `/api/v1/leads/notes/${data?.notes_id}/`,
        params: {},
        body: data,
      }),
  });
  return mutation;
}
export function useAccountAttachmentMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedUploadFile({
        uri: `/api/v1/leads/${data?.id}/attachments/`,
        params: {},
        body: data?.data,
      });
    },
  });

  return mutation;
}

export function useDeleteAccountAttachmentMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedDelete({
        uri: `/api/v1/leads/attachments/${data?.attachment_id}/`,
        params: {},
      });
    },
  });

  return mutation;
}

export function useDeleteAccountNoteMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedDelete({
        uri: `/api/v1/leads/notes/${data?.note_id}/`,
        params: {},
      });
    },
  });

  return mutation;
}

export function useAccountUpdateMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedPatch({
        uri: `/api/v1/leads/${data?.id}/`,
        params: {},
        body: data,
      });
    },
  });

  return mutation;
}

export function useAccountToAccountMutation() {
  const mutation = useMutation({
    mutationFn: async (data) => {
      return await authorizedPost({
        uri: `/api/v1/accounts/`,
        params: {},
        body: data,
      });
    },
  });

  return mutation;
}

export function useDeleteAccountsMutation() {
  const mutation = useMutation({
    mutationFn: async (id) =>
      await authorizedDelete({ uri: `/api/v1/leads/${id}` }),
  });

  return mutation;
}

export function usePatchAccountsMutation() {
  const mutation = useMutation({
    mutationFn: async (body) => {
      return await authorizedPatch({
        uri: `/api/v1/accounts/${body?.id}/`,
        params: {},
        body: body,
      });
    },
  });

  return mutation;
}
