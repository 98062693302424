import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import NameProfilePic from "src/components/common/name-profile-pic";
import {
  LeaderboardOutlined,
  Language,
  EmailOutlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";

const Header = ({ styles, activeTab, setActiveTab, data }) => {
  return (
    <Box className={styles["header-main-div"]}>
      <Box className={styles["account-details"]}>
        <Box className={styles["profile-info"]}>
          <Box className={styles["head_stacks"]}>
            <Box
              className={classNames(
                "info-chip",
                styles["custom-chip"],
                data?.is_decision_maker ? "" : "danger-chip"
              )}
            >
              {data?.is_decision_maker
                ? "Decision Maker"
                : "Non-Decision Maker"}
            </Box>
          </Box>
          <Box>
            <Typography className={styles["account-name"]}>
              {data?.name}
            </Typography>
            <Box sx={{ width: "50vw"}}>
              <Box className={styles["short-info"]}>
                <Box className={styles["grid_container"]}>
                  {/* Company */}
                  <Box className={styles["grid_item"]}>
                    <LeaderboardOutlined className={styles["icon"]} />
                    <span className={styles["text"]}>
                      {data?.company || "----"}
                    </span>
                  </Box>

                  {/* Website */}
                  <Box className={styles["grid_item"]}>
                    <Language className={styles["icon"]} />
                    <span className={styles["text"]}>
                      {data?.website || "----"}
                    </span>
                  </Box>

                  {/* Email */}
                  <Box className={styles["grid_item"]}>
                    <EmailOutlined className={styles["icon"]} />
                    <span className={styles["text"]}>
                      {data?.email || "----"}
                    </span>
                  </Box>

                  {/* Phone */}
                  <Box className={styles["grid_item"]}>
                    <PhoneAndroidOutlined className={styles["icon"]} />
                    <span className={styles["text"]}>
                      {data?.number || "----"}
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles["owner-info"]}>
          <Box className={styles["annual-revenue"]}>
            <Typography className={styles["data"]}>$ 350000</Typography>
            <Typography className={styles["header"]}>Annual Revenue</Typography>
          </Box>
          <Box
            className={styles["border"]}
            sx={{ height: "42px !important" }}
          />
          <Box className={styles["account-owner-profile"]}>
            <NameProfilePic
                letter={
                  data?.assignee?.user?.fullname
                    ? data?.assignee?.user?.fullname[0].toUpperCase()
                    : ""
                }
              customClass={styles["profile-icon"]}
              customStyle={{
                fontSize: "11px !important",
              }}
            />
            <Box className={styles["annual-revenue"]}>
              <Typography className={styles["data"]}>{data?.assignee?.user?.fullname}</Typography>
              <Typography className={styles["header"]}>Owner</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box className={styles["tab-switcher"]}>
        {["Summary", "Relationship Analytics", "Details", "Related"].map(
          (item, index) => (
            <Box
              key={index}
              onClick={() => setActiveTab(index)}
              className={classNames(
                styles["single-tab"],
                activeTab === index && styles["active-tab-content"]
              )}
            >
              <Box key={index}>
                <Box>{item}</Box>
                {activeTab === index && (
                  <Box
                    className={
                      activeTab === index
                        ? styles["active-tab"]
                        : styles["unactive-tab"]
                    }
                  ></Box>
                )}
              </Box>
            </Box>
          )
        )}
      </Box> */}
    </Box>
  );
};

export default Header;
