import { Select, MenuItem } from "@mui/material";
import classNames from "classnames";
import React from "react";

export const CommonDropdownField = ({
  name,
  placeholder,
  variant,
  value,
  handleChange,
  customClass,
  options,
  style,
  defaultValue,
  ...rest
}) => {
  return (
    <Select
      value={value}
      defaultValue={defaultValue}
      variant={variant}
      name={name}
      placeholder={placeholder}
      className={classNames("common-dropdown", customClass)}
      onChange={handleChange}
      style={style}
      {...rest}
    >
      {placeholder && (
        <MenuItem value="default" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options?.map((option, index) => (
        <MenuItem key={index} value={option?.value}>
          {option?.label}
        </MenuItem>
      ))}
    </Select>
  );
};
