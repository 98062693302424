import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Card, CardHeader, Typography, Box } from "@mui/material";
import { useChart } from "../../../components/chart";

const styles = {
  card: { 
    height: "100%", 
    display: 'flex', 
    padding: '8px 12px',
    justifyContent: 'center',

  },
  chartContainer: { 
    
    display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
     padding: '24px',
      width: '100%',
  },
  legendContainer: {
    
    marginBottom: '6px',
      display: 'flex',
      alignItems: 'center',
  },
  legendItem: {
    
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  cardHeader: {

    padding: '0',
    marginBottom: '16px',
  },
  legendMarker: {
    width: '12px',
      height: '12px',
      borderRadius: '6px',
      marginRight: '8px',
   
  },
  title: {
    fontSize: "13px",
    border:" 1px solid #959495 ",borderLeft: "none",borderRight: "none",borderTop: "none" ,
    fontWeight: "bold",
    color:"#959495"
  },
};

const GradientDonut = ({ title, subheader, chartData }) => {
  const gradientChartColors = [ "#379ACE","#EF3817"];
  const theme = useTheme();
  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);

 

  const chartOptions = useChart({
    colors: chartData.map(
      (item, index) => gradientChartColors[index % gradientChartColors.length]
    ),
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper], width: 0.8 },
    legend: {
      show: false, 
    },
    plotOptions: {
      pie: { 
        donut: { 
          labels: { 
            show: false 
          } 
        } 
      },
    },
  });

  return (
    <Card sx={styles.card}>
      <Box sx={styles.chartContainer}>
      <CardHeader
        title={
          <Typography sx={styles.title}>
            {title}
          </Typography>
        }
        sx={styles.cardHeader}
        subheader={subheader}
      />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {chartData.map((data, index) => (
            <Box key={data.label} sx={styles.legendContainer}>
              <Box sx={styles.legendItem}>
                <Box
                  sx={{
                    ...styles.legendMarker,
                    backgroundColor: gradientChartColors[index % gradientChartColors.length],
                  }}
                />
                
                <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ width: "50%" ,fontSize:"12px" , fontWeight:500 }}>
              {(chartSeries[index] / chartSeries.reduce((acc, val) => acc + val, 0) * 100).toFixed(1)}%
              </Typography>
              <Typography  sx={{ fontSize:"12px" , fontWeight:500 }}>
              {data.label}
              </Typography>
            </Box>

              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center",alignItems:"center" }}>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          height={170}
          width={170}
        />
      </Box>
    </Card>
  );
};

export default GradientDonut;
