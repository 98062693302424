import { Grid, Typography, Paper } from "@mui/material";
import React from "react";
import TemplateList from "./component/template-list";
import { useNavigate } from "react-router-dom";
import { useTemplateData } from "src/hooks/useTemplateData";
import SendEmail from "./component/SendEmail";
import styles from "./style.module.css";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
function Email() {
  const { data: templates } = useTemplateData();
  const navigate = useNavigate();

  const handleRedirectToCreateTemplate = () => {
    navigate("/dashboard/create-template");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            Available Templates
          </Typography>
          <Button
            variant="contained"
            onClick={handleRedirectToCreateTemplate}
            className={styles["create-btn"]}
              >
                <Box className={styles["export_btn"]}>Create Template</Box>
              </Button>
           <TemplateList templates={templates} styles={styles} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: "20px", height: "100%" }}>
          <SendEmail templates={templates} styles={styles} />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Email;
