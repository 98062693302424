import { Box } from "@mui/material";
import React from "react";
import { DeleteOutline, DriveFileRenameOutline } from "@mui/icons-material";
import { formatDateAndTime } from "src/utils/formatTime";
import classNames from "classnames";
import NotesModal from "./notes-modal";
import { CommonButton } from "src/components/common/common-button";

const Notes = ({
  styles,
  data,
  handleDeleteNotes,
  notesData,
  setNotesData,
  handleSubmit,
  notesFormOpen,
  setNotesFormOpen,
}) => {
  const renderMessageNew = () => {
    return data?.lead_notes.map((message, index) => (
      <>
        <Box key={index} className={styles["notes-card"]}>
          <Box>
            <Box
              className={styles[""]}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex" }}>
                <Box className={styles["name_text"]}>Title</Box>
                <Box className={styles["comment_text"]}>{message?.title}</Box>
              </Box>
              <Box>
                <Box className={styles["edit_delete"]}>
                  <Box className={styles["createdate"]}>
                    {formatDateAndTime(message?.created_at)}
                  </Box>
                  <Box
                    className={styles["edit_ion"]}
                    onClick={() => {
                      setNotesFormOpen(true);
                      setNotesData({
                        ...notesData,
                        editing: true,
                        message: message,
                        notes_id: message?.id,
                      });
                    }}
                  >
                    <DriveFileRenameOutline className={styles["edit_icon"]} />
                  </Box>
                  <Box
                    className={styles["n"]}
                    onClick={(e) => {
                      handleDeleteNotes(e, message?.id);
                    }}
                  >
                    <DeleteOutline className={styles["edit_icon"]} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={styles["name_desc"]}>Description</Box>
            <Box className={styles["comment_text"]}>{message?.description}</Box>
            <Box className={styles["name_desc"]}>Remarks</Box>
            <Box className={styles["comment_text"]}>{message?.remarks}</Box>
          </Box>
        </Box>
      </>
    ));
  };

  return (
    <Box
      className={classNames(
        "common-box-shadow",
        styles["main_card"],
        styles["notes_main"]
      )}
    >
      <Box sx={{ fontSize: "18px", fontWeight: "600", marginLeft: "15px" }}>
        Notes
      </Box>
      <Box className={styles["message-div"]}>
        <Box
          sx={{
            height: "1px",
            width: "100%",
            backgroundColor: "#d7dadd",
            margin: "30px 20px 8px 20px",
          }}
        ></Box>
        {/* {data?.lead_notes.length !== 0 ? (
          renderMessages()
        ) : (
          <Box className={styles["empty-notes"]}>
            Oops..! No any Notes yet..!
          </Box>
        )} */}

        {data?.lead_notes.length !== 0 ? (
          renderMessageNew()
        ) : (
          <Box className={styles["empty-notes"]}>
            Oops..! No any Notes yet..!
          </Box>
        )}
      </Box>
      <Box>
        <Box className={styles["create-btn"]}>
          <CommonButton
            text={"Create Note"}
            handleClick={() => setNotesFormOpen(true)}
          />
        </Box>
      </Box>

      <NotesModal
        styles={styles}
        open={notesFormOpen}
        handleChange={(e) => {
          setNotesData({
            ...notesData,
            message: { ...notesData?.message, [e.target.name]: e.target.value },
          });
        }}
        handleSubmit={handleSubmit}
        handleClose={() => setNotesFormOpen(false)}
        notesData={notesData}
      />
    </Box>
  );
};

export default Notes;
