import { useState, useRef } from "react";
import { Avatar, Box, Typography, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import CustomButton from "src/components/input/CustomButton";

const buttonStyle = {
    backgroundColor: '#FFE5DF',
    color: '#B61C18',
    padding: "6px 12px",
    borderRadius: "8px",
    fontWeight: "700",
    fontSize: "0.875rem",
    "&:hover": {
        backgroundColor: '#fec8bc',
        color: '#be302bF',
    },
};

const UpdateProfile = ({ data, deactivateUser, setProfilePic }) => {
    const firstLetter = data?.fullname ? data?.fullname.charAt(0).toUpperCase() : '';
    const [isHovered, setIsHovered] = useState(false);
    const [preview, setPreview] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePic(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const fileInputRef = useRef(null);

    const handleAvatarClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            p={2}
            bgcolor="#fff"
            borderRadius="1rem"
            padding={"2rem"}
            gap={"5rem"}
            height={"100%"}
            boxShadow={"rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"}
        >
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".jpeg,.jpg,.png,.gif"
                onChange={handleFileChange}
            />
            <Box display="flex" flexDirection="column" alignItems="center" gap="2rem">
                <Box
                    sx={{
                        position: 'relative',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        transition: 'box-shadow 0.3s',
                        boxShadow: isHovered ? "0 0 10px rgba(0, 0, 0, 0.2)" : "none",
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={handleAvatarClick}
                >
                    <Avatar
                        alt="Profile Picture"
                        src={preview ||data?.profile_pic}
                        sx={{
                            width: 130,
                            height: 130,
                            cursor: 'pointer',
                            transition: 'transform 0.3s',
                            transform: isHovered ? 'scale(1.05)' : 'scale(1)',
                        }}
                    >
                        {data?.profile_pic ? null : <p style={{fontSize: 46}}>{firstLetter}</p>}
                    </Avatar>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            transition: 'opacity 0.3s',
                            opacity: isHovered ? 1 : 0,
                            width: 130,
                            height: 130,
                        }}
                    >
                        <PhotoCamera sx={{ width: 130, height: 130 ,transition: ' transform 0.3s', transform: isHovered ? 'scale(1.05)' : 'scale(1)' ,backgroundColor: 'rgba(22, 28, 36, 0.64)', borderRadius: '50%' ,padding: '40%', color: 'white'}} />
                    </IconButton>
                </Box>
                <Typography sx={{fontSize: "1rem", fontWeight: "500", padding: " 0rem 3rem", color: "#919eab", textAlign: "center"}}>
                    Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3 Mb
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Typography sx={{
                    fontSize: "1rem",
                    fontWeight: "700",
                    color: "#000000",
                    textAlign: "center",
                }}>{data?.membership?.role || "User_Role"}</Typography>
                <CustomButton onClick={deactivateUser} style={buttonStyle}>Deactivate User</CustomButton>
            </Box>
        </Box>
    );
};

export default UpdateProfile;
