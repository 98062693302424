import axios from "axios";
export const host = process.env.REACT_APP_API_URL;
// export const host = "http://localhost:8000";

export function buildHeaders() {
  const token = JSON.parse(localStorage.getItem("token"));
  return { Authorization: `Bearer ${token?.access}` };
}

export function get(uri, params = {}, options) {
  const opt = { method: "GET", params };
  return axios
    .get(`${host}${uri}`, { ...opt, ...options })
    .then((response) => response.data);
}
export function post(uri, body = {}) {
  return axios.post(`${host}${uri}`, body).then((response) => response.data);
}

export function authorizedGet({ uri, params = {}, options }) {
  const opt = {
    params,
    headers: buildHeaders(),
  };
  if (options && options.responseType === "blob") {
    opt.headers = {
      ...opt.headers,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
  }
  if (options && opt) {
    return axios
      .get(`${host}${uri}`, { ...options, ...opt })
      .then((response) => response.data);
  } else if (options) {
    return axios
      .get(`${host}${uri}`, options)
      .then((response) => response.data);
  } else if (opt) {
    return axios.get(`${host}${uri}`, opt).then((response) => response.data);
  } else {
    return axios.get(`${host}${uri}`).then((response) => response.data);
  }
}
export function authorizedPost({ uri, params, body = {} }) {
  const options = {
    params,
    headers: buildHeaders(),
  };
  return axios
    .post(`${host}${uri}`, body, options)
    .then((response) => response.data);
}

export function authorizedUploadFile({ uri, params, body = {} }) {
  const options = {
    params,
    headers: { ...buildHeaders(), "Content-Type": "multipart/form-data" },
  };
  return axios
    .post(`${host}${uri}`, body, options)
    .then((response) => response.data);
}

export function authorizedDelete({ uri, params }) {
  const options = {
    params,
    headers: buildHeaders(),
  };
  return axios
    .delete(`${host}${uri}`, options)
    .then((response) => response.data);
}
export function authorizedPatch({ uri, params, body = {} }) {
  const options = {
    params,
    headers: buildHeaders(),
  };
  return axios
    .patch(`${host}${uri}`, body, options)
    .then((response) => response.data);
}
export function authorizedPostUpload({ uri, body = {}, params }) {
  const options = {
    params,
    headers: { ...buildHeaders(), "Content-Type": "multipart/form-data" },
  };
  return axios
    .post(`${host}${uri}`, body, options)
    .then((response) => response.data);
}

export function authorizedPatchUpload({ uri, body = {}, params }) {
  const options = {
    params,
    headers: buildHeaders(),
  };
  return axios
    .patch(`${host}${uri}`, body, options)
    .then((response) => response.data);
}
