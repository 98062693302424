import { useQuery, useMutation } from "@tanstack/react-query";
import { authorizedGet, authorizedPatch, authorizedPost } from "src/api/base";

export function useUserList(params={}) {
  const { page, limit, ...otherParams } = params;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["userList"], 
    queryFn: async () =>
      await authorizedGet({
        uri: `/api/v1/org/members/`,
        params: { page, limit, ...otherParams }
      }),
  });

  return { data, isLoading, isError, error };
}



export function usePatchUserMutation() {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) => {
      return await authorizedPatch({
        uri: `/auth/custom-users/${id}/`,
        params: {},
        body: body,
      });
    },
  });
  return mutation;
}
export function usePostUserMutation() {
  const mutation = useMutation({
    mutationFn: async ({ body }) => {
      return await authorizedPost({
        uri: `/api/v1/org/members/`,
        params: {},
        body: body,
      });
    },
  });
  return mutation;
}

export function usePatchOrgMutation() {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) => {
      return await authorizedPatch({
        uri: `/api/v1/common/organization/${id}/`,
        params: {},
        body: body,
      });
    },
  });
  return mutation;
}