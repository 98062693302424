export function getFileType(filename) {
  // Get the last index of the dot character to find the file extension
  const lastIndex = filename.lastIndexOf(".");

  // If the dot character is found and it's not the first or last character in the filename
  if (
    lastIndex !== -1 &&
    lastIndex !== 0 &&
    lastIndex !== filename.length - 1
  ) {
    // Extract the file extension using substring
    const extension = filename.substring(lastIndex + 1).toLowerCase();
    return extension;
  } else {
    // If the dot character is not found or it's the first or last character, return undefined
    return undefined;
  }
}

export const getLabelForValue = (optionsList, value) => {
  const optionsItem = optionsList.find((item) => item.value === value);
  return optionsItem ? optionsItem.label : null;
};

export const formatFileName = (fileName, maxLength = 20) => {
  if (fileName.length <= maxLength) return fileName;

  const extension = fileName.split('.').pop();
  const baseName = fileName.substring(0, fileName.length - extension.length - 1);
  const allowedBaseNameLength = maxLength - extension.length - 3; // 3 for the dots and the dot before extension

  return `${baseName.substring(0, allowedBaseNameLength)}..${extension}`;
};

export function capitalizeFirstChar(string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}