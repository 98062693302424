import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@mui/material";

const Logo = forwardRef(
  ({ disabledLink = false, sx, openNav, onOpenNav, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: "inline-flex",
          ...sx,
        }}
        {...other}
      ></Box>
    );


    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Box sx={{ display: "flex" }}>
        <Link
          to="/dashboard"
          component={RouterLink}
          sx={{ display: "contents" }}
        >
          <img
            src={
              openNav
                ? "https://d306m7ao21jgui.cloudfront.net/public/assets/images/icons/logo-blue.png?w=256&q=75"
                : "https://d306m7ao21jgui.cloudfront.net/public/assets/images/icons/logo-blue.png?w=256&q=75"
            }
            alt="cognifydigital"
            height={55}
          />
        </Link>
        {/* <Box
          sx={{
            marginLeft: "10px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: "text.primary",
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        </Box> */}
      </Box>
    );
  }
);

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
  openNav: PropTypes.bool,
};

export default Logo;
