import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import NameProfilePic from "src/components/common/name-profile-pic";


const ProgressBarComponent = ({value,data}) => {

  return (
    <Box
      display="flex"
      alignItems="center"
      marginRight="10px"
      sx={{
        backgroundColor: "#FFFEFE",
        padding: "8px 17px",
        borderRadius: "25px",
        border: "1px solid #DBDBDA",
        width: "100%",
        height: "43px"
      }}
    >
      <Box
        sx={{
          borderRadius: "100%",
          height: "30px",
          width: "50px",
          marginRight: "9px",
          border: "1px solid black",
          backgroundColor: "#174276",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >

        {data?.profile_pic ? (
          <img
            src={data?.profile_pic}
            alt="user"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <NameProfilePic
            letter={data?.email ? data?.email[0].toUpperCase() : ""}
            customStyle={{
              height: "30px",
              width: "50px",
              backgroundColor: "transparent !important",
            }}
          />
        )}

      </Box>


      <p style={{ fontSize: "11px", fontWeight: "400" }}>Total Process Success Ration</p>
      <Box
        display="flex"
        alignItems="center"
        sx={{ flexGrow: 1, width: "100%" }} 
      >
        <LinearProgress
          variant="determinate"
          value={value}

          sx={{
            flexGrow: 1,
            height: "12px",
            borderRadius: "6px",
            backgroundColor: "#d9d9d9",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#35CD8A",
            },
            marginRight: "10px",
            width: "30px",
          }}
        />
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Typography fontSize={14} fontWeight={600}>
            {value}%
          </Typography>
          <Typography fontSize={10} sx={{ color: "#8e8e8e" }}>
            Last 7 Days
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBarComponent;
