import React from "react";
import CallCenterheader from "./components/header";
import styles from "./style.module.css";
import { Box } from "@mui/material";
import MostAsked from "./components/most-asked";
import ContactCard from "./components/card";
import ContactForm from "./components/contact-form";
import { useRef } from "react";

const HelpCenterPage = () => {
  const faqRef = useRef(null)
  const contactFormRef = useRef(null)

  return (
    <>
      <Box className={styles["main-box"]}>
        <Box className={styles["container"]}>
          <CallCenterheader styles={styles} />
          <ContactCard styles={styles} faqRef={faqRef} contactFormRef={contactFormRef} />
          <MostAsked styles={styles} faqRef={faqRef} />
          <ContactForm styles={styles} contactFormRef={contactFormRef} />
          <Box sx={{marginBottom: "50px"}} />
        </Box>
      </Box>
    </>
  );
};

export default HelpCenterPage;
