import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Lottie from "lottie-react";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import trash_animation from "src/components/common/trash_animation.json";
import { CommonButton } from "src/components/common/common-button";

export default function DeleteContactModal({
  open,
  handleClose,
  deleteUserData,
  setDeleteUserData,
  handleDelete,
  styles,
}) {
  const handleCloseModal = () => {
    handleClose();
    setDeleteUserData({});
  };

  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          paddingTop: "13px",
          paddingRight: "13px",
        }}
      >
        <Close sx={{ fontSize: "25px" }} onClick={handleClose} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: " 0 30px 30px 30px",
        }}
      >
        <Box sx={{ padding: " 20px 0px" }}>
          <Box sx={{ height: "150px", width: "150px" }}>
            <Lottie animationData={trash_animation} loop={true} />
          </Box>
        </Box>
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "semi-bold",
            padding: "10px 0px",
          }}
          id="alert-dialog-title"
        >
          {"You are about to delete a contact ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "14px", textAlign: "center" }}>
            Deleting your Contact will remove all of your information from our
            database
            <strong>{deleteUserData?.name}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", width: "100%" }}>
          <Button
            onClick={handleCloseModal}
            sx={{
              backgroundColor: "#2065d114",
              fontWeight: "normal",
              borderRadius: "5px",
              padding: "8px 18px",
            }}
          >
            Cancel
          </Button>
          <CommonButton
            text={"Yes, Delete It!!"}
            customClass={styles["button"]}
            handleClick={(e) => {
              e.preventDefault();
              handleCloseModal();
              handleDelete(e);
            }}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
}
