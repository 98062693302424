import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextareaAutosize,
} from "@mui/material";
import styles from "../style.module.css";
import { CommonButton } from "src/components/common/common-button";
import { CommonInputField } from "src/components/common/common-input-field";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import { STAGE_OPTIONS } from "src/utils/constants";

const ConvertDialog = ({
  isOpen,
  closeDialog,
  data,
  // handleChange,
  handleOpportunityConversion,
}) => {
  return (
    <>
    <Box>
      <Dialog open={isOpen}>
        <Box className={styles["main_dialog_box"]}></Box>
        <Box className={styles["dialog"]}>
          <Box className={styles["dialog_summary"]}>
            <Box className={styles["dialog_summary_name"]}>
              Name: {data?.first_name} {data?.last_name}jhghjgh
            </Box>
            <Box>
              <Box className={styles["text_field"]}>
                <CommonInputField
                  placeholder="Probability"
                  name="probability"
                  type="number"
                  // value={formData?.email}
                  // handleChange={handleChange}
                  customClass={"half-width"}
                />
                <CommonInputField
                  placeholder="Opportunity Amount"
                  name="opportunity_amount"
                  type="number"
                  // value={formData?.phone}
                  // handleChange={handleChange}
                  customClass={styles["common-search-input-right"]}
                />
              </Box>
              <Box className={styles["text_field"]}>
                <CommonInputField
                  placeholder="Close Date"
                  name="close_date"
                  type="close_date"
                  customClass={"half-width"}
                  // value={formData?.email}
                  // handleChange={handleChange}
                />
                <CommonInputField
                  placeholder="Stage"
                  name="stage"
                  // value={formData?.phone}
                  // handleChange={handleChange}
                  customClass={styles["common-search-input-right"]}
                />
              </Box>

              {/* NEW DESIGN  */}
              <CommonDropdownField
                // handleChange={handleChange}
                name="source"
                placeholder="Source"
                variant="outlined"
                value={"default"}
                options={STAGE_OPTIONS}
              />

              <TextareaAutosize
                id="textarea"
                aria-label="empty textarea"
                placeholder="Textarea"
                style={{
                  width: "100%",
                  minHeight: "100px",
                  padding: "8px",
                  resize: "vertical",
                }}
              />
            </Box>
          </Box>
          <Box className={styles["first_dialog_content"]}>
            <Box className={styles["sec_dialog_content"]}>
              <img
                src="/assets/convert.png"
                alt="Convert"
                className={styles["dialog_img"]}
              />
            </Box>
            <Box>
              <DialogTitle>{"Confirmation to convert the Lead!!"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to confirm converting this lead into an
                  opportunity?
                </DialogContentText>
              </DialogContent>
            </Box>
          </Box>
          <DialogActions>
            <CommonButton
              handleClick={closeDialog}
              customClass={styles["cancel-button"]}
              text={"Cancel"}
            />

            <CommonButton
              handleClick={handleOpportunityConversion}
              color="primary"
              text={"Convert"}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>

    
    </>
  );
};

export default ConvertDialog;
