import React, { useState } from "react";
import { Box, LinearProgress, Typography, IconButton, InputAdornment } from "@mui/material";
import CustomButton from "src/components/input/CustomButton";
import { showNotification } from "src/components/notification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSetNewPassMutation } from "src/hooks/useSessiondata";
import ForgotPasswordModal from "./components/ForgotPasswordModal";
import CustomInput from "src/components/input/CustomInput";
import zxcvbn from "zxcvbn";
import closeEye from "./ic_close_eyes.svg";
import openEye from "./ic_open_eyes.svg";
const Setting = () => {
  const [data, setData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [passwordStrength, setPasswordStrength] = useState(0); // eslint-disable-line
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const setPasswordMutation = useSetNewPassMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });

    if (name === "new_password") {
      if (value === "") {
        setPasswordStrength(0);
      } else {
        const strength = zxcvbn(value).score;
        setPasswordStrength(strength);
      }
    }
  };

  const handleSubmit = async () => {
    if (data.new_password !== data.confirm_password) {
      showNotification("error", "Password Mismatch", "Password Mismatch", 5000);
      return;
    }
    try {
      await setPasswordMutation.mutateAsync({
        body: data,
      });
      showNotification("success", "Updated Password", 2000);
    } catch (error) {
      if (
        error?.response?.data?.current_password?.[0] === "Invalid password."
      ) {
        showNotification("error", "Incorrect password", 2000);
        setTimeout(() => {
          setIsModalOpen(true);
        }, 2000);
      } else {
        showNotification("error", error, 8000);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const buttonStyle = {
    backgroundColor: "#212b36",
    color: "#ffffff",
    padding: "12px 12px",
    borderRadius: "8px",
    fontWeight: "700",
    fontSize: "0.875rem",
    "&:hover": {
      backgroundColor: "#444e5b",
      color: "#ffffff",
    },
  };

  const getStrengthColor = (strength) => {
    switch (strength) {
      case 0:
        return "#ff1744";
      case 1:
        return "#ff9100";
      case 2:
        return "#ffc400";
      case 3:
        return "#00e676";
      case 4:
        return "#00c853";
      default:
        return "#d3d3d3";
    }
  };

  const toggleShowPassword = (field) => {
    switch (field) {
      case "current_password":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new_password":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm_password":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      p={2}
      bgcolor="#fff"
      borderRadius="1rem"
      padding={"1.5rem"}
      height={"100%"}
      boxShadow={
        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
      }
    >
      <ToastContainer />
      <CustomInput
        fullWidth
        placeholder="Current Password"
        type={showCurrentPassword ? "text" : "password"}
        variant="outlined"
        margin="normal"
        name="current_password"
        value={data.current_password}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => toggleShowPassword("current_password")}
                edge="end"
              >
                {showCurrentPassword ? <img src={openEye} alt="open-eyes" />  : <img src={closeEye}  alt="close-eyes"/>}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CustomInput
        fullWidth
        placeholder="New Password"
        type={showNewPassword ? "text" : "password"}
        variant="outlined"
        margin="normal"
        name="new_password"
        value={data.new_password}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => toggleShowPassword("new_password")}
                edge="end"
              >
                {showNewPassword ? <img src={openEye} alt="open-eyes" />  : <img src={closeEye}  alt="close-eyes"/>}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {data.new_password && (
        <Box mt={1} mb={1}>
          <Box display="flex" sx={{ gap: "10px", alignItems: "center" }}>
            <Typography variant="body2">Password Strength: </Typography>
            <LinearProgress 
              variant="determinate" 
              value={passwordStrength * 25} 
              style={{ height: '10px', borderRadius: '5px', backgroundColor: '#d3d3d3', width: "20%" }} 
            />
            <Typography variant="body2" color={getStrengthColor(passwordStrength)}>
              {['Very Weak', 'Weak', 'Fair', 'Good', 'Strong'][passwordStrength]}
            </Typography>
          </Box>
        </Box>
      )}
      <CustomInput
        fullWidth
        placeholder="Confirm Password"
        type={showConfirmPassword ? "text" : "password"}
        variant="outlined"
        margin="normal"
        name="confirm_password"
        value={data.confirm_password}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment  position="end">
              <IconButton
                onClick={() => toggleShowPassword("confirm_password")}
                edge="end"
                
              >
                {showConfirmPassword ?<img src={openEye} alt="open-eyes" />  : <img src={closeEye}  alt="close-eyes"/>}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box mt={2} textAlign="right">
        <CustomButton onClick={handleSubmit} style={buttonStyle}>
          Save Changes
        </CustomButton>
      </Box>
      <ForgotPasswordModal open={isModalOpen} handleClose={handleCloseModal} />
    </Box>
  );
};

export default Setting;
