import React from "react";
import { Box, Grid } from "@mui/material";
import styles from "./style.module.css";
import classNames from "classnames";
import ActivityCard from "./activity-card";
import ActivityCardHeader from "./activity-card-header";
import AddButton from "./add-button";
import TaskModal from "./modals/task-modal";
import { useState } from "react";
import {
  useLeadActivityMutation,
  usePatchLeadActivityMutation,
  usePostLeadActivityMutation,
} from "src/hooks/useLeadsData";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import EmailModal from "./modals/email-modal";
import CallModal from "./modals/call-modal";
import MeetingModal from "./modals/meeting-modal";
import { showNotification } from "src/components/notification";
import { capitalizeFirstChar } from "src/utils/others";
import { useQueryClient } from "@tanstack/react-query";

export const ActivityPage = () => {
  const { id } = useParams();

  // States
  const [open, setOpen] = useState();
  const [editing, setEditing] = useState(false);
  const [activityData, setActivityData] = useState({});
  const [formData, setFormData] = useState();

  // React Query Mutations
  const queryClient = useQueryClient();
  const { data, isLoading } = useLeadActivityMutation({ id }); // eslint-disable-line
  const addActivityMutation = usePostLeadActivityMutation();
  const updateActivityMutation = usePatchLeadActivityMutation();

  // State Change Effect
  useEffect(() => {
    const taskData = data?.results?.filter(
      (activity) => activity.type_of_activity.toLowerCase() === "task"
    );
    const emailData = data?.results?.filter(
      (activity) => activity.type_of_activity.toLowerCase() === "email"
    );
    const callData = data?.results?.filter(
      (activity) => activity.type_of_activity.toLowerCase() === "call"
    );
    const meetingData = data?.results?.filter(
      (activity) => activity.type_of_activity.toLowerCase() === "meeting"
    );

    setActivityData({
      taskData,
      emailData,
      callData,
      meetingData,
    });
  }, [data]);

  // Essential Functions
  const handleAddButtonClick = (type) => {
    if (type?.toLowerCase() === "task") {
      setOpen({ task: true });
    }
    if (type?.toLowerCase() === "email") {
      setOpen({ email: true });
    }
    if (type?.toLowerCase() === "call") {
      setOpen({ call: true });
    }
    if (type?.toLowerCase() === "meeting") {
      setOpen({ meeting: true });
    }
  };

  const handleModalClose = () => {
    setOpen(null);
    setTimeout(() => {
      setEditing(false);
      setFormData();
    }, 500);
  };

  const handleEditClick = (type, formData) => {
    if (type?.toLowerCase() === "task") {
      handleAddButtonClick(type?.toLowerCase());
      setFormData({ ...formData });
      setEditing(true);
    }
    if (type?.toLowerCase() === "email") {
      handleAddButtonClick(type?.toLowerCase());
      setFormData({ ...formData });
      setEditing(true);
    }
    if (type?.toLowerCase() === "call") {
      handleAddButtonClick(type?.toLowerCase());
      setFormData({ ...formData });
      setEditing(true);
    }
    if (type?.toLowerCase() === "meeting") {
      handleAddButtonClick(type?.toLowerCase());
      setFormData({ ...formData });
      setEditing(true);
    }
  };

  const handleChange = (e, type) => {
    if (type === "checkbox") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleAddActivity = async (e, type) => {
    e.preventDefault();
    const body = {
      ...formData,
      lead: id,
      type_of_activity: type,
    };
    try {
      await addActivityMutation.mutateAsync(
        { body },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["activityData", id] });
            showNotification(
              "success",
              `${capitalizeFirstChar(type)} added successfully`,
              2000
            );
            setOpen(null);
            setTimeout(() => {
              setEditing(false);
              setFormData();
            }, 500);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleUpdateActivity = async (e) => {
    e.preventDefault();
    try {
      await updateActivityMutation.mutateAsync(
        { body: formData },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["activityData", id] });
            showNotification("success", "Activity updated successfully", 2000);
            setOpen(null);
            setTimeout(() => {
              setEditing(false);
              setFormData();
            }, 500);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  return (
    <>
      <Box className={styles["activity-main"]}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <Box
              className={classNames(styles["task-col"], styles["common-col"])}
            >
              <ActivityCardHeader
                styles={styles}
                title={"Tasks"}
                number={activityData?.taskData?.length}
              />
              <AddButton
                styles={styles}
                title={"Add Task"}
                handleClick={() => {
                  console.log(editing);
                  handleAddButtonClick("task");
                }}
              />
              {activityData?.taskData?.map((task) => (
                <ActivityCard
                  styles={styles}
                  data={task}
                  setFormData={setFormData}
                  handleEditClick={() => handleEditClick("task", task)}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Box
              className={classNames(styles["email-col"], styles["common-col"])}
            >
              <ActivityCardHeader
                styles={styles}
                title={"Emails"}
                number={activityData?.emailData?.length}
              />
              <AddButton
                styles={styles}
                title={"Add Email"}
                handleClick={() => handleAddButtonClick("email")}
              />
              {activityData?.emailData?.map((task) => (
                <ActivityCard
                  styles={styles}
                  data={task}
                  setFormData={setFormData}
                  handleEditClick={() => handleEditClick("email", task)}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Box
              className={classNames(styles["call-col"], styles["common-col"])}
            >
              <ActivityCardHeader
                styles={styles}
                title={"Calls"}
                number={activityData?.callData?.length}
              />
              <AddButton
                styles={styles}
                title={"Add Call"}
                handleClick={() => handleAddButtonClick("call")}
              />
              {activityData?.callData?.map((task) => (
                <ActivityCard
                  styles={styles}
                  data={task}
                  setFormData={setFormData}
                  handleEditClick={() => handleEditClick("call", task)}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Box
              className={classNames(
                styles["meeting-col"],
                styles["common-col"]
              )}
            >
              <ActivityCardHeader
                styles={styles}
                title={"Meetings"}
                number={activityData?.meetingData?.length}
              />
              <AddButton
                styles={styles}
                title={"Add Meeting"}
                handleClick={() => handleAddButtonClick("meeting")}
              />
              {activityData?.meetingData?.map((task) => (
                <ActivityCard
                  styles={styles}
                  data={task}
                  setFormData={setFormData}
                  handleEditClick={() => handleEditClick("meeting", task)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <TaskModal
        styles={styles}
        open={open?.task || false}
        handleDialogClose={handleModalClose}
        editing={editing}
        taskFormData={formData}
        handleChange={handleChange}
        handleSubmit={(e) => handleAddActivity(e, "Task")}
        handleUpdate={handleUpdateActivity}
      />
      <EmailModal
        styles={styles}
        open={open?.email || false}
        handleDialogClose={handleModalClose}
        editing={editing}
        emailFormData={formData}
        handleSubmit={(e) => handleAddActivity(e, "Email")}
        handleChange={handleChange}
        handleUpdate={handleUpdateActivity}
      />
      <CallModal
        styles={styles}
        open={open?.call || false}
        handleDialogClose={handleModalClose}
        editing={editing}
        callFormData={formData}
        handleSubmit={(e) => handleAddActivity(e, "Call")}
        handleChange={handleChange}
        handleUpdate={handleUpdateActivity}
      />
      <MeetingModal
        styles={styles}
        open={open?.meeting || false}
        handleDialogClose={handleModalClose}
        editing={editing}
        meetingFormData={formData}
        handleSubmit={(e) => handleAddActivity(e, "Meeting")}
        handleChange={handleChange}
        handleUpdate={handleUpdateActivity}
      />
    </>
  );
};
