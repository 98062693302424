import React from "react";
import styles from "../style.module.css";
import { Circle } from "@mui/icons-material";
import { Box } from "@mui/material";
import AppAppBar from "./appbar";

const Home = () => {
  return (
    <Box className={styles["home-container"]}>
      <AppAppBar />
      <Box className={styles["home-banner-container"]}>
        <Box className={styles["home-bannerImage-container"]}>
          <img
            src="/assets/bg_banner.png"
            alt="Banner Background"
            style={{ maxWidth: "100%" }}
          />
        </Box>
        <Box className={styles["home-text-section"]}>
          <Box className={styles["home-text-chip"]}>
            <Circle sx={{ height: "11px" }} /> CRM for Lead Flow
          </Box>
          <h1 className={styles["primary-heading"]}>
            Transform Prospects into Promoters with Our Leading-Edge LMS
          </h1>
          <p className={styles["primary-text2"]}>
            Seamlessly turn potential customers into brand promoters nurturing
            lasting relationships and engagement with our powerful LMS solution.
          </p>
          <Box sx={{ display: "flex" }}>
            <button className={styles["secondary-button"]}>Get Started</button>
            <button className={styles["secondary-button-two"]}>Try Demo</button>
          </Box>
        </Box>
        <Box className={styles["home-image-section"]}>
          <img src="/assets/home_hero.png" alt="Banner Image1" />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
