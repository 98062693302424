import React from 'react';
import { Grid, Paper, Typography, Box, Button, Divider } from '@mui/material';
import { useQuery } from "@tanstack/react-query";
import { formatDate } from 'src/utils/formatTime';
function Plan() {
  const { data } = useQuery({ queryKey: ["sessionData"] });
  const calculateRemainingDays = (endDate) => {
    if (!endDate) return "N/A";
    const today = new Date();
    const end = new Date(endDate);
    const timeDifference = end - today;
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return remainingDays >= 0 ? `${remainingDays} days` : "Expired";
  };

  const remainingDays = calculateRemainingDays(data?.user_profile?.end_date);
  return (
    <Box
      sx={{
        margin: 'auto',


      }}
    >
      <Paper
        sx={{
          padding: 3,
          borderRadius: 3,
          backgroundColor: '#117FB5', 
          color: '#fff',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
          marginBottom: 4,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: '700', marginBottom: 1 }}>
          {data?.user_profile?.plan}
        </Typography>
        <Typography variant="body1" sx={{ opacity: 0.8 }}>
          Active Status: <strong>{data?.user_profile?.plan === "Smart Plan" ? "Paid" : "Unpaid"}</strong>
        </Typography>
      </Paper>

      {/* Plan Details and Features Section */}
      <Grid container spacing={4}>
        {/* Plan Details Section */}
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              padding: 4,
              borderRadius: 3,
              backgroundColor: '#f9f9f9',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: '600', color: '#333' }}>
                Plan Details
              </Typography>

              <Typography variant="body1" sx={{ color: '#777', marginBottom: 2 }}>
                <strong>Plan Type:</strong> {data?.user_profile?.plan === "Smart Plan" ? "Smart" : "Free"}
              </Typography>
              <Typography variant="body1" sx={{ color: '#777', marginBottom: 2 }}>
                <strong>Plan Status:</strong> {data?.user_profile?.plan === "Smart Plan" ? "Paid" : "Unpaid"}
              </Typography>
              <Typography variant="body1" sx={{ color: '#777', marginBottom: 2 }}>
                <strong>End Date:</strong> {formatDate(data?.user_profile?.end_date)}
              </Typography>
              <Typography variant="body1" sx={{ color: '#777', marginBottom: 2 }}>
                <strong>Remaining Days:</strong> {remainingDays}
              </Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Plan Features Section */}
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              padding: 4,
              borderRadius: 3,
              backgroundColor: '#f9f9f9',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: '600', color: '#333' }}>
                Plan Features
              </Typography>

              <Typography variant="body1" sx={{ color: '#777', marginBottom: 1 }}>
                • 15 Days Free for 2 Logins
              </Typography>
              <Typography variant="body1" sx={{ color: '#777', marginBottom: 1 }}>
                • Extra Login Available
              </Typography>
              <Typography variant="body1" sx={{ color: '#777', marginBottom: 1 }}>
                • Live Chat Support
              </Typography>
              <Typography variant="body1" sx={{ color: '#777', marginBottom: 1 }}>
                • Monthly Pay Available
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Plan Options Section */}
      <Paper
        sx={{
          padding: 4,
          borderRadius: 3,
          backgroundColor: '#ffffff',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          marginTop: 4,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: 2, color: '#333' }}>
          Plan Options
        </Typography>

        {/* Divider to separate options */}
        <Divider sx={{ marginBottom: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#117FB5',
                color: '#fff',
                textTransform: 'none',
                height: '48px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#0A6A99',
                },
                borderRadius: 2,
              }}
            >
              Upgrade Plan
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#117FB5',
                color: '#117FB5',
                textTransform: 'none',
                height: '48px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'rgba(17, 127, 181, 0.1)',
                  borderColor: '#117FB5',
                },
                borderRadius: 2,
              }}
            >
              Renew Plan
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: '#d32f2f',
                color: '#d32f2f',
                textTransform: 'none',
                height: '48px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'rgba(211, 47, 47, 0.1)',
                  borderColor: '#d32f2f',
                },
                borderRadius: 2,
              }}
            >
              Cancel Plan
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Plan;
