import React from "react";
import { Box } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";

const ContactCard = ({ styles, faqRef, contactFormRef }) => {
  const executeFaqScroll = () => faqRef.current.scrollIntoView();
  const executeContactFormScroll = () =>
    contactFormRef.current.scrollIntoView();

  return (
    <>
      <Box className={styles["row"]}>
        <Box className={styles["first_box"]}>
          <img
            src="/assets/faq.png"
            alt="FAQs"
            className={styles["first_box_img"]}
          />
          <Box className={styles["contact_card_title"]}>FAQs</Box>
          <Box className={styles["contact_card_desc"]}>
            FAQ, short for frequently asked questions, is a list of commonly
            asked questions and answers about a specific topic.
          </Box>
          <Box className={styles["arrow-btn"]} onClick={executeFaqScroll}>
            <Box className={styles["card_view_more"]}>View FAQ</Box>
            <Box>
              <ArrowRightAlt />
            </Box>
          </Box>
        </Box>
        <Box className={styles["sec_box"]}>
          <img
            src="/assets/book.png"
            alt="Guides"
            className={styles["first_box_img"]}
          />
          <Box className={styles["contact_card_title"]}>Guides & Resources</Box>
          <Box className={styles["contact_card_desc"]}>
            UI Style Guides are a design & development tool that brings cohesion
            to a digital product user interface & experience
          </Box>
          <Box className={styles["arrow-btn"]}>
            <Box className={styles["card_view_more"]}>Browse Guides</Box>
            <Box>
              <ArrowRightAlt />
            </Box>
          </Box>
        </Box>
        <Box className={styles["third_box"]}>
          <img
            src="/assets/support.png"
            alt="support"
            className={styles["first_box_img"]}
          />
          <Box className={styles["contact_card_title"]}>Support</Box>
          <Box className={styles["contact_card_desc"]}>
            The good news is that youre not alone, and youre in the right place.
            Contact us for more detailed support.
          </Box>
          <Box
            className={styles["arrow-btn"]}
            onClick={executeContactFormScroll}
          >
            <Box className={styles["card_view_more"]}>Submit a Request</Box>
            <Box>
              <ArrowRightAlt />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactCard;
