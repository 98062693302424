import React from "react";
import { Box, TextField, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { SubmitButton } from "src/components/common/common-button";

const EditNotesModal = ({
  styles,
  open,
  handleClose,
  handleSubmit,
  handleChange,
  editData,
}) => {
  return (
    <Box className={styles["main-dialog"]}>
      <Dialog open={open} fullWidth>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>Edit Note</Box>
          <Box>
            <Close className={styles["close"]} onClick={() => handleClose()} />
          </Box>
        </Box>
        <form onSubmit={handleSubmit} style={{ padding: "20px" }}>
          <TextField
            label="description"
            name="description"
            type="text"
            className={styles["text-area"]}
            value={editData?.message?.description}
            onChange={handleChange}
            margin="normal"
            fullWidth
            multiline
            minRows={3}
          />
          <SubmitButton
            fullWidth={true}
            text={"Update Note"}
            onClick={handleSubmit}
          />
        </form>
      </Dialog>
    </Box>
  );
};

export default EditNotesModal;
