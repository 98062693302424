import { InputBase, Paper } from "@mui/material";
import classNames from "classnames";
import React from "react";

export const CommonInputField = ({
  placeholder,
  name,
  value,
  handleChange,
  type = "text",
  customClass,
  customInputClass,
  readOnly = false,
  minLines = 1,
  ...rest
}) => {
  return (
    <Paper
      component="form"
      className={classNames("common-search-input", customClass)}
    >
      <InputBase
        type={type}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        name={name}
        inputProps={{ "aria-label": "" }}
        readOnly={readOnly}
        multiline={true}
        minRows={minLines}
        className={customInputClass}
        {...rest}
      />
    </Paper>
  );
};
