import React from "react";
import { Close } from "@mui/icons-material";
import { Box, Checkbox, Dialog, FormControlLabel } from "@mui/material";
import { SubmitButton } from "src/components/common/common-button";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import { CommonInputField } from "src/components/common/common-input-field";
import {
  TASK_ACTIVITY_PRIORITY,
  TASK_ACTIVITY_STATUS,
} from "src/utils/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const TaskModal = ({
  styles,
  open,
  handleDialogClose,
  editing,
  taskFormData,
  handleChange,
  handleSubmit,
  handleUpdate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className={styles["main-dialog"]}>
        <Dialog open={open} fullWidth>
          <Box className={styles["main_header"]}>
            <Box className={styles["add_lead"]}>
              {editing ? "Edit Task" : "Add Task"}
            </Box>
            <Box>
              <Close className={styles["close"]} onClick={handleDialogClose} />
            </Box>
          </Box>
          <form
            onSubmit={editing ? handleUpdate : handleSubmit}
            style={{ padding: "20px" }}
          >
            <CommonInputField
              placeholder="Title"
              name="activity"
              value={taskFormData?.activity || ""}
              onChange={handleChange}
            />
            <CommonInputField
              placeholder="Description"
              name="description"
              customClass={styles["text-area"]}
              value={taskFormData?.description || ""}
              onChange={handleChange}
              minLines={4}
            />
            <Box sx={{ display: "flex" }}>
              <Box className={styles["date"]}>
                <input
                  type="date"
                  name="deadline"
                  className={styles["date-input"]}
                  value={taskFormData?.deadline || ""}
                  onChange={handleChange}
                />
              </Box>
              <Box className={styles["checkbox-container"]}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={taskFormData?.send_reminder}
                      onChange={(e) => handleChange(e, "checkbox")}
                      color="primary"
                      name="send_reminder"
                    />
                  }
                  label="Set Reminder"
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <CommonDropdownField
                name="priority"
                placeholder="Priority"
                variant="outlined"
                value={taskFormData?.priority || "default"}
                onChange={handleChange}
                options={TASK_ACTIVITY_PRIORITY}
                customClass={"half-width"}
              />
              <CommonDropdownField
                name="status"
                placeholder="Status"
                variant="outlined"
                value={taskFormData?.status || "default"}
                onChange={handleChange}
                options={TASK_ACTIVITY_STATUS}
                customClass={"half-width"}
              />
            </Box>

            <SubmitButton
              fullWidth={true}
              text={editing ? "Update Task" : "Add Task"}
            />
          </form>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default TaskModal;
