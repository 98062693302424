import React from "react";
// import { Box } from "@mui/material";
// import { CommonInputField } from "src/components/common/common-input-field";

const Address = ({ styles }) => {
  // const singleInfo = (name, placeholder, value) => {
  //   return (
  //     <Box className={styles["address-single-info"]}>
  //       <Box className={styles["input-box"]}>
  //         <CommonInputField
  //           name={name}
  //           value={value}
  //           handleChange={() => {}}
  //           placeholder={placeholder}
  //           customClass={styles["common-input-div"]}
  //           readOnly={true}
  //         />
  //       </Box>
  //     </Box>
  //   );
  // };

  return (
    <>
      {/* <Box
      className={classNames("common-box-shadow", styles["account-info-card"])}
    >
      <Typography className={styles["heading"]}>Contact Information</Typography>
      {singleInfo("account_name", "Account Name", "A. Datom Corporation")}
      {singleInfo("phone", "Phone", "+91 8978562312")}
      {singleInfo("website", "Website", "www.abc.com")}
    </Box> */}
    </>
  );
};

export default Address;
