import { Box, Grid } from "@mui/material";
import React from "react";
import CustomButton from "src/components/input/CustomButton";
import CustomInput from "src/components/input/CustomInput";
import { INDUSTRY_TYPE, ORGANIZATION_TYPE } from "src/utils/constants";
import CustomSelect from "src/components/common/CustomSelect";
const buttonStyle = {
  backgroundColor: "#212b36",
  color: "#ffffff",
  padding: "6px 12px",
  borderRadius: "8px",
  fontWeight: "700",
  fontSize: "0.875rem",
  "&:hover": {
    backgroundColor: "#444e5b",
    color: "#ffffff",
  },
};

const UpdateForm = ({ data, handleInputChange, handleUpdate }) => {
  return (
    <Box
      p={2}
      bgcolor="#fff"
      borderRadius="1rem"
      padding={" 1.5rem "}
      height={"100%"}
      boxShadow={
        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <CustomInput
            placeholder="Organisation Name"
            name="name"
            defaultValue={data?.user_org?.name}
            onChange={handleInputChange}
            type={"text"}
            fullwidth
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            placeholder="Organisation Email"
            name="email"
            defaultValue={data?.user_org?.email}
            onChange={handleInputChange}
            type={"text"}
            fullwidth
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            placeholder="Organisation Website"
            name="website"
            defaultValue={data.user_org?.website}
            type={"url"}
            onChange={handleInputChange}
            fullwidth
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            placeholder="Employee Count"
            name="employee_count"
            defaultValue={data?.user_org?.employee_count}
            onChange={handleInputChange}
            fullwidth
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInput
            placeholder="Phone Number"
            name="phone_number"
            defaultValue={data.user_org?.phone_number}
            onChange={handleInputChange}
            type={"number"}
            fullwidth
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            options={INDUSTRY_TYPE}
            defaultValue={
              data?.user_org?.industry_type
                ? {
                    value: data?.user_org?.industry_type,
                    label: data?.user_org?.industry_type,
                  }
                : null
            }
            onChange={(selectedOption) =>
              handleInputChange({
                target: { name: "industry_type", value: selectedOption.value },
              })
            }
            placeholder="Select Industry Type"
            customStyle={{
              padding: "3.5px 0px",
              color: "black",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            options={ORGANIZATION_TYPE}
            defaultValue={
              data.user_org?.org_type
                ? {
                    value: data?.user_org?.org_type,
                    label: data?.user_org?.org_type,
                  }
                : null
            }
            onChange={(selectedOption) =>
              handleInputChange({
                target: { name: "org_type", value: selectedOption.value },
              })
            }
            placeholder="Select Organization Type"
            customStyle={{
              padding: "3.5px 0px",
              color: "black",
            }}
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="right">
        <CustomButton onClick={handleUpdate} style={buttonStyle}>
          Save Changes
        </CustomButton>
      </Box>
    </Box>
  );
};

export default UpdateForm;
