import { useQuery, useMutation } from "@tanstack/react-query";
import {
  authorizedDelete,
  authorizedGet,
  authorizedPatch,
  authorizedPost,
} from "src/api/base";

export function useContactsData(params = {}) {
  const { page, limit, ...otherParams } = params;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["contactsData"],
    queryFn: async () =>
      await authorizedGet({
        uri: `/api/v1/leads/contacts/`,
        params: { page, limit, ...otherParams },
      }),
  });

  return { data, isLoading, isError, error };
}

export function useContactDetails({ id }) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["contactDetail", id],
    queryFn: async () => await authorizedGet({ uri: `/api/v1/leads/contacts/${id}` }),
  });

  return { data, isLoading, isError, error };
}

export function useContactsMutation() {
  const mutation = useMutation({
    mutationFn: async (data) =>
      await authorizedPost({
        uri: "/api/v1/leads/contacts/",
        params: {},
        body: data,
      }),
  });

  return mutation;
}

export const usePatchContactsMutation = () => {
  const mutation = useMutation({
    mutationFn: async (body) =>
      await authorizedPatch({
        uri: `/api/v1/leads/contacts/${body?.id}/`,
        params: {},
        body: body,
      }),
  });

  return mutation;
};

export function useDeleteContactsMutation() {
  const mutation = useMutation({
    mutationFn: async (id) =>
      await authorizedDelete({ uri: `/api/v1/leads/${id}` }),
  });

  return mutation;
}
