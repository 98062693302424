import { Edit } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { CommonInputField } from "src/components/common/common-input-field";
import { formatDateAndTime } from "src/utils/formatTime";

const ContactInfo = ({ styles, data }) => {
  return (
    <>
      <Box className={styles["analysi_main"]}>
        <Box className={styles["analysis_box"]}>
          <Box className={styles["chip_edit"]}>
            <Box className={styles["custom-chip2"]}>Contact 1</Box>
            <Box
              className={styles["edit_phase"]}
              // onClick={() => {
              //   handleEditModalOpen();
              //   setFormData({ ...item });
              // }}
            >
              <Edit className={styles["edit_phase_icon"]} />
            </Box>
          </Box>

          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Name"
              name="name"
              // onChange={handleChange}
              value={data?.name}
              customClass={"half-width-field"}
            />
            <CommonInputField
              placeholder="Designation"
              name="designation"
              // onChange={handleChange}
              value={data?.designation}
              // customClass={styles["common-search-input-right"]}
              customClass={"half-width-field"}
            />
          </Box>
          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Email"
              name="email"
              // onChange={handleChange}
              value={data?.email}
              customClass={"half-width-field"}
            />
            <CommonInputField
              placeholder="Contact Number"
              name="number"
              // onChange={handleChange}
              value={data?.number}
              customClass={"half-width-field"}
            />
          </Box>

          <CommonInputField
            placeholder="Comment"
            name="comment"
            // onChange={handleChange}
            value={data?.comment}
            customClass={styles["stake-text-area"]}
            minLines={4}
          />
        </Box>
        <Box className={styles["progress_card_job"]}>
          {formatDateAndTime(data?.created_at)}
        </Box>
      </Box>
    </>
  );
};

export default ContactInfo;
