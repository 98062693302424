import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailEditor from "react-email-editor";
import CustomInput from "../../../components/input/CustomInput";
import {
  useTemplateMutation,
} from "src/hooks/useTemplateData";
import { Button } from "@mui/material";
import styles from "../style.module.css";
import { useSessionData } from "src/hooks/useSessiondata";
import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from "src/components/notification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateTemplate() {
  const navigate = useNavigate();

  const emailEditorRef = useRef(null);
  const [templateName, setTemplateName] = useState("");

  const [editorKey, setEditorKey] = useState(Date.now());
  const { data: user } = useSessionData();
  
  const queryClient = useQueryClient();
  const templateMutation = useTemplateMutation();

  const saveTemplate = async () => {
    try {
      const data = await new Promise((resolve) => {
        emailEditorRef.current.editor.exportHtml((data) => {
          resolve(data);
        });
      });

      const { html, design } = data;
      await templateMutation.mutateAsync(
        { html, design, name: templateName, user: user?.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["emailTemplate"] });
            showNotification("success", "Template Saved Successfully.!", 2000);
            setTemplateName("");
            setEditorKey(Date.now());
            navigate("/dashboard/email");
          },
          onError: (error) => {
            console.log(error);
            showNotification("error", error, 8000);
          },
        }
      );
    } catch (error) {
      console.error("Error exporting template:", error);
      showNotification("error", error, 8000);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <ToastContainer />
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: 700, color: "#212b36", mb: 2 }}
      >
        Create Email Template
      </Typography>
      <CustomInput
        name="template_name"
        placeholder="Template Name"
        value={templateName}
        required
        onChange={(e) => setTemplateName(e.target.value)}
        fullWidth
        style={{ marginBottom: "20px" }}
      />
      <EmailEditor key={editorKey} ref={emailEditorRef} />
      <Button
        style={{ marginTop: "20px" }}
        variant="contained"
        onClick={saveTemplate}
        className={styles["button"]}
      >
        Save Template
      </Button>
    </div>
  );
}

export default CreateTemplate;
