import React from "react";
import { Box, IconButton, Grid, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { SaveAlt } from "@mui/icons-material";

const TableHeader = ({
  styles,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  value,
  setValue,
  filterData,
  handleFilterDataChange,
  handleFilterReset,
  handleFilterSubmit,
  handleSearch,
  searchField,
  setSearchField,
}) => {
  return (
    <Box sx={{ p: 2, backgroundColor: "#E6EAEE", marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="center">
        {/* First Column */}
        <Grid item xs={12} sm={6.8}>
          <Box className={styles["total-lead"]}>
            <Box>Total Deals : </Box>
            <Box> 77</Box>
          </Box>
        </Grid>

        {/* Search Bar */}
        <Grid item xs={12} sm={4}>
          <Paper component="form" className={styles["search-input"]}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              name="search"
              // defaultValue={query.search}
              // onChange={handleChange}
              placeholder="Search Deals"
              inputProps={{ "aria-label": "search Deals" }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>

        {/* export */}
        <Grid item xs={12} sm={1} sx={{}}>
          <Button
            variant="contained"
            className={styles["button"]}
            onClick={openFilter}
            open={openFilter}
            endIcon={<SaveAlt className={styles["export_btn_icon"]} />}
          >
            <Box className={styles["export_btn"]}>Export</Box>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableHeader;
