import React from "react";
import { Box } from "@mui/material";
import { Add } from "@mui/icons-material";

const AddButton = ({ styles, title, handleClick }) => {
  return (
    <Box className={styles["add-btn"]} onClick={handleClick}>
      <Add color="black" fontSize="16px" sx={{ marginRight: "5px" }} /> {title}
    </Box>
  );
};

export default AddButton;
