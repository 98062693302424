import React from "react";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import { useParams } from "react-router-dom";
import { useAccountDetails } from "src/hooks/useAccountsData";
import Loader from "src/components/common/loader";
import AccountDetail from "./components/account-details";

const UsersDetailView = () => {
  const { id } = useParams();
  const { data, isLoading } = useAccountDetails({ id });

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      {/* <TopHeader styles={styles} /> */}
      <Box className={styles["vertical-spacer"]} />
      {/* <Header
        styles={styles}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data}
      /> */}
      <Box className={styles["vertical-spacer"]} />
      {/* {activeTab === 0 && <Summary styles={styles}  />} */}
      <AccountDetail styles={styles} data={data}/>
    </Box>
  );
};

export default UsersDetailView;
