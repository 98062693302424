import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CommonInputField } from 'src/components/common/common-input-field';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { usePatchAccountsMutation } from 'src/hooks/useAccountsData';
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from 'src/components/notification';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
const NotesSection = ({ notes }) => (
    <Grid item xs={4}>
        {notes.map((note, index) => (
            <Typography variant="body2" key={index} mt={index > 0 ? 1 : 0}>
                <strong>Note {index + 1}:</strong> {note}
            </Typography>
        ))}
    </Grid>
);

const RevenueSection = ({ revenueData }) => (
    <Grid item xs={4}>

        {revenueData.map((data, index) => (
            <Typography variant="body2" key={index} mt={index > 0 ? 1 : 0}>
                {data.date} <br />
                Allocated Amount: <strong>{data.amount}</strong>
            </Typography>
        ))}
    </Grid>
);

const SummarySection = ({ summary }) => (
    <Grid item xs={4}>
        {Object.entries(summary).map(([key, value], index) => (
            <Typography variant="body2" key={index} mt={index > 0 ? 1 : 0}>
                <strong>{key}:</strong> {value}
            </Typography>
        ))}
    </Grid>
);
const AccountDetail = ({ styles, data }) => {

    const Headings = [
        "PREVIOUS CONVERSATION",
        "REVENUE",
        "SUMMARY"
    ];
    const notes = [
        "Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.",
        "Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator."
    ];

    const revenueData = [
        { date: "12/Aug/2024", amount: "2,00,000" },
        { date: "16/Aug/2024", amount: "2,00,000" }
    ];

    const summary = {
        "Contact Date": "01/Aug/2024",
        "No. of follow ups": "4",
        "Other Details": "Reference site about Lorem Ipsum, giving information."
    };
    const [formData, setFormData] = useState(data);
    const [isEditable, setIsEditable] = useState(false);
    const patchAccountMutation = usePatchAccountsMutation();
    const queryClient = useQueryClient();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }
    const handleEditClick = () => {
        setIsEditable(!isEditable);
    };
    console.log(data);

    const handleSaveClick = async () => {
        try {
            await patchAccountMutation.mutateAsync(formData, {
                onSuccess: (data) => {
                    queryClient.invalidateQueries({ queryKey: ["accountsData"] });
                    showNotification("success", "Account Updated Successfully.", 2000);
                    setIsEditable(false);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box>
            <ToastContainer />
            <Box sx={{ padding: 2, backgroundColor: "#ffffff" }} >
                <Box sx={{ display: "flex", gap: "1%", }}>
                    {/* Left Side */}
                    <Box sx={{ width: "50%", }}>
                        <Box sx={{ display: "flex", gap: "8%", width: "100%", alignItems: "center", backgroundColor: "#E8E9E9", padding: 2, height: "12%", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                            <Box sx={{ width: "100%" }}>
                                <Typography color={"#5C5D5C"} fontWeight={600} >
                                    Account Details
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ padding: 2, backgroundColor: "#F7F6F7", height: "88%", display: "flex", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                            <Box sx={{ width: "95%", mt: 1 }}>


                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",
                                }}>
                                    <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600 ,mt:1}}>
                                            Name
                                        </Typography>
                                    </Box>
                                    <CommonInputField
                                        customClass={styles["fixed-width"]}
                                        placeholder="Name"
                                        name="name"
                                        type="text"
                                        value={formData?.name}
                                        handleChange={handleChange}
                                        disabled={!isEditable}
                                    />
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",
                                }}>
                                     <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600,mt:1}}>
                                            Company
                                        </Typography>
                                    </Box>
                                    <CommonInputField
                                        customClass={styles["fixed-width"]}
                                        placeholder="Company"
                                        name="company"
                                        handleChange={handleChange}
                                        value={formData?.company}
                                        type="text"
                                        disabled={!isEditable}
                                    />
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",

                                }}>
                                     <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600,mt:1}}>
                                            Team Size
                                        </Typography>
                                    </Box>
                                    <CommonInputField
                                        customClass={styles["fixed-width"]}
                                        placeholder="Team Size"
                                        name="team_size"
                                        handleChange={handleChange}
                                        value={formData?.team_size}
                                        type="number"
                                        disabled={!isEditable}
                                    />
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",
                                }}>
                                     <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                                            Allocated Amount
                                        </Typography>
                                    </Box>

                                    <CommonInputField
                                        customClass={styles["fixed-width"]}
                                        placeholder="Allocated Amount"
                                        name="opportunity_amount"
                                        handleChange={handleChange}
                                        value={formData?.opportunity_amount}
                                        type="number"
                                        disabled={!isEditable}
                                    />
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",
                                }}>
                                     <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600,mt:1}}>
                                            C/S Date
                                        </Typography>
                                    </Box>
                                    <CommonInputField
                                        customClass={styles["fixed-width"]}
                                        placeholder="C/S Date"
                                        name="csDate"
                                        handleChange={handleChange}
                                        defaultValue="In-process"
                                        type="date"
                                        disabled={!isEditable}
                                    />
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",
                                }}>
                                     <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600,mt:1}}>
                                            Deal Owner
                                        </Typography>
                                    </Box>
                                    <CommonInputField
                                        customClass={styles["fixed-width"]}
                                        placeholder="Deal Owner"
                                        name="dealOwner"
                                        handleChange={handleChange}
                                        defaultValue="Ravi Sharma"
                                        type="text"
                                        disabled={!isEditable}
                                    />
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",
                                }}>
                                     <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600,mt:1 }}>
                                            Last Update
                                        </Typography>
                                    </Box>
                                    <CommonInputField
                                        customClass={styles["fixed-width"]}
                                        placeholder="Last Update"
                                        name="lastUpdate"
                                        handleChange={handleChange}
                                        defaultValue="In-process"
                                        type="text"
                                        disabled={!isEditable}
                                    />
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",
                                }}>
                                     <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600,mt:1}}>
                                            LinkedIn
                                        </Typography>
                                    </Box>
                                    <CommonInputField
                                        maxrows={1}
                                        customClass={styles["fixed-width"]}
                                        placeholder="LinkedIn"
                                        name="linkedIn"
                                        handleChange={handleChange}
                                        value={formData?.linkedin}
                                        type="text"
                                        disabled={!isEditable}
                                    />
                                </Box>

                                <Box sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5%",
                                }}>
                                    <Box sx={{ width: "30%"}}>
                                        <Typography sx={{ fontSize: "12px", fontWeight: 600,mt:1}}>
                                            Website
                                        </Typography>
                                    </Box>
                                    <CommonInputField
                                        maxrows={1}
                                        customClass={styles["fixed-width"]}
                                        placeholder="Website"
                                        name="website"
                                        handleChange={handleChange}
                                        value={formData?.website}
                                        type="text"
                                        disabled={!isEditable}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ width: "5%", display: "flex", justifyContent: "flex-end", height: "auto" }}>

                                {isEditable ? (
                                    <CheckIcon onClick={handleSaveClick} sx={{ cursor: "pointer", color: "#A6A7A7", mt: -1 }} fontSize='small' />
                                ) : (
                                    <EditIcon onClick={handleEditClick} sx={{ cursor: "pointer", color: "#A6A7A7", mt: -1 }} fontSize='small' />
                                )}
                            </Box>



                        </Box>
                    </Box>
                    {/* Right Side */}
                    <Box sx={{ width: "75%", }}>
                        <Box sx={{ display: "flex", gap: "8%", width: "100%", alignItems: "center", backgroundColor: "#E8E9E9", padding: 2, height: "12%", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                            {Headings.map((heading, index) => (
                                <Box key={index} sx={{ width: "100%" }}>
                                    <Typography color={"#5C5D5C"} fontWeight={600} >
                                        {heading}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box sx={{ padding: 2, height: "88%", borderRadius: "0%", backgroundColor: "#F7F6F7", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                            <Grid container spacing={7}>
                                <NotesSection notes={notes} />
                                <RevenueSection revenueData={revenueData} />
                                <SummarySection summary={summary} />
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AccountDetail;
