import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({
  onClick,
  children,
  variant = "contained",
  color = "primary",
  customClass,
  style,
  ...rest
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={color}
      className={customClass}
      sx={{
        boxShadow: "none",
        ...style,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
