import React from "react";
import Box from "@mui/material/Box";
import styles from "../style.module.css";
import { StarRate } from "@mui/icons-material";

const Testimonial = () => {
  return (
    <Box className={styles["work-section-wrapper"]} id="testimonials">
      <Box className={styles["work-section-top"]}>
        <p className={styles["primary-subheading"]}>Testimonial</p>
        <h1 sx={{fontSize:"45px",margin:0}}>Hear from <span style={{color:"#174276",fontWeight:"bold",fontSize:"46px"}}>Our Satisfied </span>Customers</h1>
        <p className={styles["primary-text"]}>
        Discover how our LMS has transformed businesses and boosted their sales efficiency. Hear directly from our clients.
        </p>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box className={styles["testimonial-section-bottom"]}>
          <img
            src="/assets/img2.jpg"
            alt=""
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "50%",
              marginBottom: "10px",
              objectFit: "cover",
            }}
          />
          <p className={styles["testimonial-section-text"]}>
            The LMS provides us with comprehensive insights into our lead
            generation efforts. The detailed analytics and reporting features
            help us make data-driven decisions.
          </p>
          <Box className={styles["testimonials-stars-container"]}>
            <StarRate />
            <StarRate />
            <StarRate />
            <StarRate />
            <StarRate />
          </Box>
          <h3>Kelshey jhonas</h3>
        </Box>
        <Box className={styles["testimonial-section-bottom"]}>
          <img
            src="/assets/tstimony.jpeg"
            alt=""
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "50%",
              marginBottom: "10px",
              objectFit: "cover",
            }}
          />
          <p className={styles["testimonial-section-text"]}>
            The LMS has been an exceptional tool for our business. The setup was
            straightforward, and the integration with our existing CRM was
            seamless. 
          </p>
          <Box className={styles["testimonials-stars-container"]}>
            <StarRate />
            <StarRate />
            <StarRate />
            <StarRate />
            <StarRate />
          </Box>
          <h3>Brain clark</h3>
        </Box>
        <Box className={styles["testimonial-section-bottom"]}>
          <img
            src="/assets/img3.jpg"
            alt=""
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "50%",
              marginBottom: "10px",
              objectFit: "cover",
            }}
          />
          <p className={styles["testimonial-section-text"]}>
            Since implementing the LMS, our lead management process has become
            incredibly efficient. The system's ability to automatically
            categorize and prioritize leads has saved us so much time.
          </p>
          <Box className={styles["testimonials-stars-container"]}>
            <StarRate />
            <StarRate />
            <StarRate />
            <StarRate />
            <StarRate />
          </Box>
          <h3>Jelly Robinson</h3>
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonial;
