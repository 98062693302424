import { Box, Grid, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { CommonInputField } from "src/components/common/common-input-field";
import { formatDate } from "src/utils/formatTime";

const MoreInfo = ({ styles, data }) => {
  const singleInfo = (name, placeholder, value, minLines = 3) => {
    return (
      <>
        <Box className={styles[""]}>
          <Typography className={styles["more-info-placeholder"]}>
            {placeholder}
          </Typography>
          <Box className={styles["address-single-info"]}>
            <Box className={styles["more-info-input-box"]}>
              <CommonInputField
                name={name}
                value={value}
                handleChange={() => {}}
                placeholder={placeholder}
                customClass={styles["common-input-div"]}
                customInputClass={styles["common-input"]}
                readOnly={true}
                minLines={minLines}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      className={classNames("common-box-shadow", styles["account-info-card"])}
    >
      <Typography className={styles["heading"]}>More Info</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {singleInfo("description", "Description", data?.description)}
          </Grid>
          <Grid item xs={6}>
            {singleInfo(
              "purchase_timeline",
              "Purchase Timeline",
              data?.purchase_timeline
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {singleInfo("closing_date", "Closing Date", formatDate(data?.close_date) )}
          </Grid>
          <Grid item xs={6}>
            {singleInfo(
              "closing_comment",
              "Closing Date & Comment",
              data?.closing_comment
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MoreInfo;
