import { useState } from "react";
import { Link } from "react-router-dom";
import { Stack, Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import CustomInput from "src/components/input/CustomInput";

const AuthForm = (props) => {
  const { handleUser, type } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [organization, setOrganization] = useState("");

  const theme = createTheme({
    palette: {
      blue: {
        main: "#174276",
        hover: "#2874D0",
        contrastText: "#fff",
      },
    },
  });

  const handleSubmit = () => {
    if (type === "reset_password") {
      handleUser(email);
    } else {
      handleUser(email, password, fullname, organization);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        {type === "signup" && (
          <>
            <CustomInput
              name="fullname"
              placeholder="Full Name"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
            <CustomInput
              name="organization"
              placeholder="Your Organization"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
            />
          </>
        )}
        <CustomInput
          name="email"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {(type === "login" || type === "signup") && (
          <CustomInput
            name="password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}
        {type === "login" && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <Box />
            <Link
              to="/reset-password"
              variant="subtitle2"
              underline="hover"
              style={{ cursor: "pointer", color: "#174276", textDecoration: "none" }}
            >
              Forgot password?
            </Link>
          </Stack>
        )}

        <ThemeProvider theme={theme}>
          <LoadingButton
            fullWidth
            color="blue"
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            sx={{
              "&:hover": {
                backgroundColor: theme.palette.blue.hover,
              },
            }}
          >
            {type === "signup" ? "Sign Up" : type === "Reset_Password" ? "Send Email" : "Login"}
          </LoadingButton>
        </ThemeProvider>
      </Stack>
      <Box mt={2} textAlign="right">
        <Typography variant="subtitle2" color="textSecondary">
          {type === "signup"
            ? "Already have an account? "
            : type === "Reset_Password" ? "Return to " : "Don't have an account? "}
          <Link
            to={type === "signup" ? "/login" : type === "Reset_Password" ? "/login" : "/signup"}
            variant="subtitle2"
            underline="hover"
            style={{ cursor: "pointer", color: "#174276", textDecoration: "none" }}
          >
            {type === "signup" ? "sign in" : type === "Reset_Password" ? "sign in" : "sign up"}
          </Link>
        </Typography>
      </Box>

    </>
  );
};

export default AuthForm;
