import React from "react";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/logo";
import { useLoginMutation } from "../../../hooks/useSessiondata";
import { showNotification } from "../../../components/notification";
import "react-toastify/dist/ReactToastify.css";
import { AuthForm } from "src/sections/auth/login";
import { Box } from "@mui/material";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const   LoginPage = () => {
  const loginMutation = useLoginMutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    try {
      await loginMutation.mutateAsync(
        { email, password },
        {
          onSuccess: (data) => {
            queryClient.setQueryData("token", data);
            localStorage.setItem("token", JSON.stringify(data));
            showNotification("success", "Welcome", 2000);
            navigate("/dashboard");
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  return (
    <>
      <ToastContainer />
      {loginMutation.isLoading}
      <Helmet>
        <title> Sign in | Lead Management System </title>
      </Helmet>

      <StyledRoot>
        <Box sx={{width:"100%",height:"100%",display:"flex",justifyContent:"space-between"}}>
          
          <Box sx={{width:"40%",padding:"0px 80px 0px 80px"}}>
        <Container maxWidth="sm">
          <StyledContent>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "start",
                paddingBottom: "20px",
              }}
            >
              <Logo />
            </Grid>
            <Grid sx={{ textAlign: "center", marginBottom: "50px" }}>
              <Box sx={{fontSize:"28px",color:"#314683",fontWeight:"400",textAlign:"start"}}>
              Welcome to Cognify<br/> Lead Management System
              </Box>
            </Grid>

            <AuthForm type="login" handleUser={handleLogin} />
          </StyledContent>
        </Container>
        </Box>
        <Box sx={{width:"59%"}}><img src="/assets/images/login/login_img.jpg" alt="" style={{objectFit:"cover"}}/></Box>
        </Box>
      </StyledRoot>
    </>
  );
};

export default LoginPage;
