import React from "react";
import { Box } from "@mui/material";
import classNames from "classnames";

const DownSVGIcon = ({ styles, customClass, svgStyle }) => {
  return (
    <Box className={classNames(styles["icon"], customClass)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        class="component-iconify MuiBox-root css-1ydd91u iconify iconify--solar"
        width="1em"
        height="1em"
        style={svgStyle}
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M5 6.25a.75.75 0 0 0-.488 1.32l7 6c.28.24.695.24.976 0l7-6A.75.75 0 0 0 19 6.25z"
          opacity=".5"
        ></path>
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M4.43 10.512a.75.75 0 0 1 1.058-.081L12 16.012l6.512-5.581a.75.75 0 1 1 .976 1.139l-7 6a.75.75 0 0 1-.976 0l-7-6a.75.75 0 0 1-.081-1.058"
          clip-rule="evenodd"
        ></path>
      </svg>
    </Box>
  );
};

export default DownSVGIcon;
