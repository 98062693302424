import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Card, CardHeader, Typography } from "@mui/material";

const styles = {
  card: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 16px",
  },
  chartContainer: { display: "flex", flexDirection: "column" },
  title: {
    fontSize: "13px",
    border: " 1px solid #959495 ",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    fontWeight: "bold",
    color: "#959495",
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3% 0",
  },
};

const FunnelChart = ({ title, chartData, category, name }) => {
  const options = {
    chart: {
      type: "bar",
      height: 240,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: "80%",
        isFunnel: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      dropShadow: {
        enabled: false,
      },
    },
    xaxis: {
      categories: category,
    },
    legend: {
      show: false,
    },
  };

  return (
    <Card sx={styles?.card}>
      <Box sx={styles?.chartContainer}>
        <CardHeader
          title={<Typography sx={styles?.title}>{title}</Typography>}
        />
      </Box>
      <div style={styles?.chart}>
        <ReactApexChart
          options={options}
          series={chartData}
          type="bar"
          height={190}
          width={320}
        />
      </div>
    </Card>
  );
};

export default FunnelChart;
