import { toast } from "react-toastify";
import { parseErrorMessages } from "src/utils/error-handling";

export const showOldNotification = (type, message, duration) => {
  toast[type](message, {
    position: "top-right",
    autoClose: duration,
    closeOnClick: true,
  });
};

export const showNotification = (type, message, duration) => {
  if (type === "error" && message.response) {
    const errorMessage = parseErrorMessages(message);
    toast[type](errorMessage, {
      position: "top-right",
      autoClose: duration,
      closeOnClick: true,
    });
  } else {
    toast[type](message, {
      position: "top-right",
      autoClose: duration,
      closeOnClick: true,
    });
  }
};
