import PropTypes from "prop-types";
import React from "react";
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import {
  SOURCE_OPTIONS,
  STATUS_OPTIONS,
  LEAD_OWNER_OPTIONS,
  IS_ACTIVE_OPTIONS,
} from "src/utils/constants";

export default function LeadFilters({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  filterData,
  handleFilterReset,
  handleFilterDataChange,
  styles,
}) {
  const renderStatusList = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Status</Typography>
      <Select
        defaultValue={filterData?.status}
        variant="outlined"
        name="status"
        style={{ width: "100%" }}
        className={styles["search-input"]}
        onChange={handleFilterDataChange}
      >
        {STATUS_OPTIONS.map((item, index) => (
          <MenuItem key={index} value={item?.value?.toLowerCase()}>
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );

  const renderSourceList = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Source</Typography>
      <Select
        defaultValue={filterData?.source}
        variant="outlined"
        name="source"
        style={{ width: "100%" }}
        className={styles["search-input"]}
        onChange={handleFilterDataChange}
      >
        {SOURCE_OPTIONS.map((item, index) => (
          <MenuItem key={index} value={item?.value?.toLowerCase()}>
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );

  const renderOwnerList = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Lead Owner</Typography>
      <Select
        defaultValue={filterData?.lead_owner}
        name="lead_owner"
        variant="outlined"
        style={{ width: "100%" }}
        className={styles["search-input"]}
        onChange={handleFilterDataChange}
      >
        {LEAD_OWNER_OPTIONS.map((item, index) => (
          <MenuItem key={index} value={item?.value?.toLowerCase()}>
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );

  const renderIsActiveList = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">IsActive</Typography>
      <Select
        defaultValue={filterData?.is_active}
        name="is_active"
        variant="outlined"
        style={{ width: "100%" }}
        className={styles["search-input"]}
        onChange={handleFilterDataChange}
      >
        {IS_ACTIVE_OPTIONS.map((item, index) => (
          <MenuItem key={index} value={item?.value?.toLowerCase()}>
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list"  className={styles["export_btn"]}/>}
        onClick={onOpenFilter}
        className={styles["button"]}

      >
       <Box  className={styles["export_btn"]}>Filters&nbsp;</Box> 
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: {
            width: 280,
            border: "none",
            overflow: "hidden",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Box className="" sx={{ display: "flex" }}>
            <img
              alt="icon"
              src="/assets/icons/dashboard/filter.svg"
              style={{ height: "30px", width: "30px" }}
            />

            <Typography variant="h6" sx={{ ml: 1 }}>
              Filters
            </Typography>
          </Box>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            {renderStatusList}
            {renderSourceList}
            {renderOwnerList}
            {renderIsActiveList}
          </Stack>
        </Scrollbar>

        {/* <Box sx={{ px: 3 }} onClick={handleFilterSubmit}>
          <SubmitButton text={"Filter"} fullWidth={true} />
        </Box> */}
        <Box sx={{ p: 3 }} onClick={handleFilterReset}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

LeadFilters.propTypes = {
  state: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};
