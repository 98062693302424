import { Navigate, Routes, Route } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import UserPage from "./pages/users";
import LoginPage from "./pages/auth/login/login";
import Page404 from "./pages/404";
import DashboardAppPage from "./pages/dashboard";
import LeadsPage from "./pages/leads";
import PrivateRoutes from "./providers/privateRoutes";
import DetailView from "./pages/leads/detail-view";
import SignupPage from "./pages/auth/signup/Signup";
import Profile from "./pages/profile";
import AccountsDetailView from "./pages/accounts/detail-view";
import AccountsPage from "./pages/accounts";
import ContactsPage from "./pages/contacts";
import ContactDetailView from "./pages/contacts/detail-view";
import ReserPassword from "./pages/auth/reset-password/ResetPassword";
import ConfirmPassword from "./pages/auth/confirm-password/ConfirmPassword";
import DealsPage from "./pages/deals";
import DealsDetailView from "./pages/deals/detail-view";
import HelpCenterPage from "./pages/help-center";
import LandingPage from "./pages/landing";
import SendEmail from "./pages/email/component/SendEmail";
import PreviewTemplate from "./pages/email/component/PreviewTemplate";
import Email from "./pages/email";
import CreateTemplate from "./pages/email/component/CreateTemplate";
import EmailsDashboard from "./pages/emails-dashboard";
const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} index={true} />
      <Route path="/login" element={<LoginPage />} index={true} />
      <Route path="/signup" element={<SignupPage />} index={true} />
      <Route path="/login" element={<LoginPage />} index={true} />
      <Route path="/reset-password" element={<ReserPassword />} index={true} />

      <Route
        path="/password-reset/:uuid/:token"
        element={<ConfirmPassword />}
        index={true}
      />
      <Route element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="profile" element={<Profile />} />
          <Route path="landing" element={<LandingPage />} />
          <Route element={<Navigate to="/dashboard/app" />} index={true} />
          <Route path="app" element={<DashboardAppPage />} />

          <Route path="email" element={<Email />} />
          <Route path="emails-dashboard" element={<EmailsDashboard />} />
          <Route path="create-template" element={<CreateTemplate />} />
          <Route path="preview-template/:id" element={<PreviewTemplate />} />
          <Route path="send-email" element={<SendEmail />} />
          <Route path="leads" element={<LeadsPage />} />
          <Route path="leads/:id" element={<DetailView />} />
          <Route path="deals" element={<DealsPage />} />
          <Route path="deals/:id" element={<DealsDetailView />} />
          <Route path="accounts" element={<AccountsPage />} />
          <Route path="accounts/:id" element={<AccountsDetailView />} />
          <Route path="contacts" element={<ContactsPage />} />
          <Route path="contacts/:id" element={<ContactDetailView />} />
          <Route path="users" element={<UserPage />} />
          <Route path="help-center" element={<HelpCenterPage />} />
        </Route>
      </Route>

      <Route element={<SimpleLayout />}>
        <Route element={<Navigate to="/dashboard/app" />} />
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
export default Router;
