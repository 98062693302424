import React from 'react';
import TextField from '@mui/material/TextField';

const CustomTextArea = ({row, label, value, onChange, placeholder ,name,...rest}) => {
  return (
    <TextField
      name={name}s
      multiline
      rows={row}
      variant="outlined"
      fullWidth
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      InputProps={{
        sx: {
          '& textarea': {
            fontSize: '16px',
          },
        },
      }}
      InputLabelProps={{
        sx: {
          color: '#666',
          fontSize: '16px',
          fontWeight: 'normal',
          marginBottom: '4px',
        },
      }}
      {...rest}
    />
  );
};

export default CustomTextArea;
