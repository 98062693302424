import { Box } from "@mui/material";
import React from "react";
import MoreInfo from "./more-info";


const Summary = ({
  styles,
  data,
  isSummaryEditing,
  editedData,
  handleChange,
}) => {
  return (
    <Box className={styles["summary-main"]}>
      {/* <Box className={styles["first-col"]}>
        <KeyDetails styles={styles} data={data} />
        <Box className={styles["vertical-spacer"]} />
      </Box> */}

      <Box className={styles["third-col"]}>
        <MoreInfo styles={styles} data={data} />
        
      </Box>
    </Box>
  );
};

export default Summary;
