import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Typography,
  Box,
  Paper,
  InputBase,
  Grid,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import styles from "./style.module.css";

export default function UserListToolbar({
  numSelected,
  query,
  handleChange,
  handleOpenDialog,
  total_users,
}) {
  return (
    <Box sx={{ mb: 2, p: 2, backgroundColor: "#E6EAEE" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Box className={styles["total_users"]}>
            <Typography  fontWeight={600} id="tableTitle" component="div">Total users : {total_users}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}></Grid>
        <Grid
          item
          xs={12} sm={12} md={4}
          sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
        > 
            <Paper
            component="form"
            className={styles["search-input"]}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "300px",
              mr: 2,
            }}
          >
            <InputBase
              sx={{ flex: 1, pl: 1 }}
              defaultValue={query.search}
              onChange={handleChange}
           placeholder="Search For..."
              inputProps={{ "aria-label": "Search For..." }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            onClick={handleOpenDialog}
            className={styles["button"]}
            endIcon={<AddIcon className={styles["export_btn"]}/>}
          >
             <Box  className={styles["export_btn"]}>Add User</Box>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  handleOpenDialog: PropTypes.func,
};
