import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

const EmptyTableMessage = ({ colSpan, message }) => {
  return (
    <TableRow sx={{ height: 150 }} >
      <TableCell colSpan={colSpan} style={{ textAlign: "center", padding: "20px 0" , margin: "20px 0" }}>
        <Typography variant="subtitle1">{message}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default EmptyTableMessage;
