import React from "react";
import { Close } from "@mui/icons-material";
import { SubmitButton } from "src/components/common/common-button";
import { Box, Checkbox, Dialog } from "@mui/material";
import { CommonInputField } from "src/components/common/common-input-field";
import {
  usePatchContactsMutation,
  useContactsMutation,
} from "src/hooks/useContactsData";
import { showNotification } from "src/components/notification";
import { useQueryClient } from "@tanstack/react-query";

const NewContactModal = ({
  styles,
  open,
  handleClose,
  edit,
  handleChange,
  formData,
}) => {
  // React Query Mutations
  const queryClient = useQueryClient();
  const postContactsMutation = useContactsMutation();
  const patchContactMutation = usePatchContactsMutation();

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await patchContactMutation.mutateAsync(
        formData,
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["contactsData"] });
            showNotification(
              "success",
              "Contact Updated Successfully.!",
              5000
            );
            handleClose();
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await postContactsMutation.mutateAsync(formData, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["contactsData"] });
          showNotification(
            "success",
            "Contact Created Successfully.!",
            5000
          );
          handleClose();
        },
      });
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  return (
    <Box className={styles["main-dialog"]}>
      <Dialog fullWidth open={open === undefined ? false : open}>
        <Box className={styles["main_header"]}>
          <Box className={styles["add_lead"]}>
            {edit ? "Update Contact" : "New Contact"}
          </Box>
          
          <Box>
            <Close className={styles["close"]} onClick={handleClose} />
          </Box>
        </Box>

        <form
          onSubmit={edit ? handleUpdate : handleAdd}
          style={{ padding: "20px" }}
        >
          <Box className={styles["analysis_main"]}>
            <Box className={styles["analysis_box"]}>
              <Box className={styles["text_field"]}>
                <CommonInputField
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={formData?.name}
                  customClass={"half-width"}
                />
                <CommonInputField
                  placeholder="Designation"
                  name="designation"
                  onChange={handleChange}
                  value={formData?.designation}
                  customClass={styles["common-search-input-right"]}
                />
              </Box>
              <Box className={styles["text_field"]}>
                <CommonInputField
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={formData?.email}
                  customClass={"half-width"}
                />
                <CommonInputField
                  placeholder="Contact Number"
                  name="number"
                  onChange={handleChange}
                  value={formData?.number}
                  customClass={styles["common-search-input-right"]}
                />
              </Box>
              <Box className={styles["checkbox-container"]}>
                <Checkbox
                  name="is_decision_maker"
                  checked={formData?.is_decision_maker}
                  onChange={(e) => handleChange(e, "checkbox")}
                />
                Decision Maker
              </Box>
              <CommonInputField
                placeholder="Comment"
                name="comment"
                onChange={handleChange}
                value={formData?.comment}
                customClass={styles["text-area"]}
                minLines={4}
              />
            </Box>
          </Box>

          <SubmitButton
            fullWidth={true}
            text={edit ? "Update Contact" : "New Contact"}
          />
        </form>
      </Dialog>
    </Box>
  );
};

export default NewContactModal;
